import folderGraphic from "@/assets/folder.svg";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import FolderModal from "@/components/FolderModal";
import FolderPath from "@/components/FolderPath";
import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDebounce, useIntersectionObserver } from "@/hooks";
import {
  useFolders,
  usePromptRegistryObjects,
  usePromptRegistryTags,
} from "@/queries";
import { Folder } from "@/types/folders";
import { getTruncatedFolderPath } from "@/utils/utils";
import { SearchIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FolderGrid from "./FolderGrid";
import RegistryTabs from "./RegistryTabs";
import TagsDropdown from "./TagsDropdown";
import TemplateGrid from "./TemplateGrid";

const Registry = () => {
  const [isFolderModalOpen, setFolderModalOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("registrySearch") || "";
  const q = useDebounce(searchQuery, 300);
  const selectedTags = searchParams.getAll("registryTags");

  const { folderId } = useParams();
  const navigate = useNavigate();
  const userContext = useUser();
  const auth = useAuth();
  const workspaceId = userContext.activeWorkspaceId!;
  const userToken = auth?.userToken!;
  const searchFolders = !!folderId || !q;
  const foldersQuery = useFolders(userToken!, workspaceId!, folderId!);
  const folders: Folder[] = foldersQuery.data?.folders ?? [];
  const folderPath: string[] =
    foldersQuery.data?.path?.map((folder: Folder) => folder.name) ?? [];
  const folderPathWithIds: Folder["path"] = foldersQuery.data?.path ?? [];
  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    folderId,
    q,
    tags: selectedTags,
    searchFolders,
  });
  if (entry?.isIntersecting) {
    promptRegistryObjects.fetchNextPage();
  }
  const templates =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  const promptRegistriesTags = usePromptRegistryTags(auth?.userToken!, {
    workspaceId,
  });
  const allTemplateTags = promptRegistriesTags.data || [];

  const handleTagClick = (tag: string) => {
    setSearchParams((prev) => {
      if (selectedTags.includes(tag)) {
        prev.delete("registryTags");
      } else {
        prev.append("registryTags", tag);
      }
      return prev;
    });
  };

  const renderCreateFolderButton = () => {
    return (
      <Button
        className="mr-2"
        onClick={() => setFolderModalOpen(true)}
        variant="secondary"
      >
        Create Folder
      </Button>
    );
  };

  const foldersWithMatches = folders.filter((folder) =>
    templates.some((template) => template.folder_id === folder.id),
  );

  const renderFolders = () => {
    if (!folders.length) return null;

    let filteredFolders = folders;
    if (folderPath.length <= 0) {
      filteredFolders = folders.filter((folder: Folder) => !folder.parent_id);
    }

    return (
      <>
        {q === "" ? (
          <>
            <div className="mt-10">Folders</div>
            <div className="mt-4 flex-1">
              <FolderGrid folders={filteredFolders} />
            </div>
          </>
        ) : (
          foldersWithMatches.map((folder) => (
            <div key={folder.id}>
              <div className="mt-10">
                <img
                  src={folderGraphic}
                  alt="folder"
                  className="mr-2 inline h-5 w-auto"
                />
                <strong>Folder:</strong> <FolderPath folder={folder} />
              </div>
              <div className="mt-4 flex-1">
                <TemplateGrid
                  templates={templates.filter(
                    (template) => template.folder_id === folder.id,
                  )}
                />
              </div>
            </div>
          ))
        )}
      </>
    );
  };

  const renderTemplates = () => {
    const displayHeading =
      !!templates.length || (!folderId && !!folders.length);

    return (
      <>
        {displayHeading && <div className="mt-10">Templates</div>}
        <div className="mt-4 flex-1 pb-8">
          <TemplateGrid
            templates={
              q === ""
                ? templates
                : templates?.filter((template) =>
                    foldersWithMatches.every(
                      (folder) => template.folder_id !== folder.id,
                    ),
                  )
            }
          />
          {promptRegistryObjects.hasNextPage && (
            <div ref={ref} className="mt-4 flex justify-center py-2">
              <LoadingSpinner size={5} />
            </div>
          )}
        </div>
      </>
    );
  };

  const getNavigationUrl = () => {
    const currentfolderIndex = folderPathWithIds.findIndex(
      (folder) => folder.id === parseInt(folderId ?? ""),
    );
    if (currentfolderIndex > 0) {
      return `/workspace/${userContext?.activeWorkspaceId}/prompt-folder/${
        folderPathWithIds[currentfolderIndex - 1].id
      }`;
    }
    return `/workspace/${userContext?.activeWorkspaceId}/prompt`;
  };

  return (
    <>
      <div className="bg-white p-1">
        <Breadcrumbs
          items={
            [
              "Registry",
              "Prompts",
              ...(getTruncatedFolderPath(folderPath) ?? null),
            ].filter(Boolean) as string[]
          }
          navigateUrl={
            folderPath?.length > 0
              ? getNavigationUrl()
              : `/workspace/${userContext?.activeWorkspaceId}/home`
          }
          tabsAboveTitle={<RegistryTabs />}
          pageTitle="Prompt Registry"
          pageSubtitle={
            <>
              Manage your prompt templates programmatically or through the web
              editor. See{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="https://docs.promptlayer.com/features/prompt-registry"
              >
                docs
              </a>{" "}
              for more info.
            </>
          }
          rightTitleContent={
            <>
              {renderCreateFolderButton()}
              <Button
                onClick={() =>
                  navigate(
                    `/workspace/${userContext?.activeWorkspaceId}/create-prompt${
                      folderId ? `?folder=${folderId}` : ""
                    }`,
                  )
                }
              >
                Create Template
              </Button>
            </>
          }
        />
        <div className="border-b py-2">
          {/* Search bar */}
          <div className="flex items-center">
            <div className="mt-3 flex flex-1 items-center rounded-sm px-1">
              <SearchIcon
                className="mr-2 h-5 w-auto text-gray-400"
                aria-hidden="true"
              />
              <input
                value={searchQuery}
                onChange={(e) => {
                  setSearchParams((prev) => {
                    if (e.target.value === "") {
                      prev.delete("registrySearch");
                      return prev;
                    }
                    prev.set("registrySearch", e.target.value);
                    return prev;
                  });
                }}
                type="text"
                name="search"
                id="search"
                className="block w-full rounded-sm border-0 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
                placeholder="Search templates..."
              />
            </div>
            <div className="px-1">
              <TagsDropdown
                allTags={allTemplateTags}
                selectedTags={selectedTags}
                toggleTag={handleTagClick}
                clearFilters={() => {
                  setSearchParams();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {promptRegistryObjects.isLoading ? (
        <div className="pt-8 text-center">
          <LoadingSpinner size={5} />
        </div>
      ) : (
        <>
          {renderFolders()}
          {renderTemplates()}
        </>
      )}
      {isFolderModalOpen && <FolderModal setOpen={setFolderModalOpen} />}
    </>
  );
};

export default Registry;
