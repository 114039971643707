import { Button } from "@/components/ui/button";
import { PencilIcon } from "lucide-react";
import { useContext } from "react";
import { DatasetEditorContext } from "..";

const EditButton = () => {
  const {
    isDraft,
    rows,
    isSavingDataset,
    handleEditClick,
    handleSaveAsNewVersionClick,
    isSettingDraftDatasetStateFromDataset,
    isSavingCell,
  } = useContext(DatasetEditorContext);

  return isDraft ? (
    <Button
      disabled={rows.length === 0 || isSavingCell}
      isLoading={isSavingDataset}
      onClick={handleSaveAsNewVersionClick}
      size="sm"
    >
      Save as New Version
    </Button>
  ) : (
    <Button
      className="inline-flex items-center"
      isLoading={isSettingDraftDatasetStateFromDataset}
      disabled={isSavingCell}
      onClick={handleEditClick}
      size="sm"
      variant="outline"
    >
      <PencilIcon aria-hidden="true" className="h-4 w-auto pr-1" />
      New Version
    </Button>
  );
};

export default EditButton;
