import { FunctionFormValues } from "@/components/FunctionsModal/Types";
import { Function_, JSONSchema, SchemaDefinition } from "@/types";
import JSON5 from "json5";

export const getAdditionalPropertiesValue = (
  key: JSONSchema | undefined,
  strict?: boolean,
): boolean | JSONSchema | undefined => {
  const additionalPropertiesVal =
    typeof key?.additionalProperties === "object"
      ? key?.additionalProperties
      : !!key?.additionalProperties;

  /**
   * If strict flag is true, then we should always return the additionalProperties either the value assigned to it or false.
   *
   * and if strict is false, then we need to return it as undefined so that it shouldn't bother the original JSON Schema
   * until user explicitly enabled the additionalProperties through the checkbox/JSON editor
   */
  if (!!strict) {
    return additionalPropertiesVal || false;
  } else {
    return additionalPropertiesVal || undefined;
  }
};

// Utility function to convert interactive form data to JSON
export const convertInteractiveToJson = (
  formValues: FunctionFormValues | SchemaDefinition,
): Partial<Function_> | Partial<SchemaDefinition> => {
  const result: Function_ | SchemaDefinition = {
    name: formValues.name,
    description: formValues.description,
    strict: !!formValues.strict || undefined,
  };

  if ("parameters" in formValues) {
    (result as Function_)["parameters"] = {
      ...formValues.parameters,
      additionalProperties: getAdditionalPropertiesValue(
        formValues.parameters,
        formValues.strict,
      ),
    };
  } else if ("schema" in formValues) {
    (result as SchemaDefinition)["schema"] = {
      ...formValues.schema,
      additionalProperties: getAdditionalPropertiesValue(
        formValues.schema,
        formValues.strict,
      ),
    };
  }

  return result;
};

// Utility function to parse JSON and update interactive form fields
export const parseJsonToInteractive = (
  jsonString: Function_ | SchemaDefinition | string,
): Partial<FunctionFormValues> | Partial<SchemaDefinition> => {
  let parsedJson: Partial<Function_ | SchemaDefinition> = {};
  try {
    if (typeof jsonString === "string") {
      parsedJson = JSON5.parse(jsonString);
    } else {
      parsedJson = jsonString;
    }

    // Check if parsedJson has 'parameters' or 'schema' and return the appropriate structure
    if ("parameters" in parsedJson) {
      parsedJson.strict = !!parsedJson.strict || false;
      parsedJson.parameters = {
        ...parsedJson.parameters,
        additionalProperties:
          getAdditionalPropertiesValue(
            parsedJson.parameters,
            parsedJson.strict,
          ) || false,
      };
      return JSON5.parse(JSON5.stringify(parsedJson));
    } else {
      parsedJson.strict = !!parsedJson.strict || false;
      (parsedJson as SchemaDefinition).schema = {
        ...(parsedJson as SchemaDefinition).schema,
        additionalProperties:
          getAdditionalPropertiesValue(
            (parsedJson as SchemaDefinition).schema,
            parsedJson.strict,
          ) || false,
      };
      return JSON5.parse(JSON5.stringify(parsedJson));
    }
  } catch (error) {
    console.error("Failed to parse JSON", error, jsonString);
    throw new Error("Invalid JSON");
  }
};

export const updateAdditionalProperties = (
  schema: JSONSchema,
  newValue: boolean | JSONSchema,
): JSONSchema => {
  // Update additionalProperties at the current level
  if (typeof schema.additionalProperties !== "undefined") {
    schema.additionalProperties = newValue;
  }

  // Traverse and update additionalProperties in nested schemas
  if (schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      schema.properties![key] = updateAdditionalProperties(
        schema.properties![key],
        newValue,
      );
    });
  }

  if (schema.items) {
    if (Array.isArray(schema.items)) {
      schema.items = schema.items.map((item) =>
        updateAdditionalProperties(item, newValue),
      );
    } else {
      schema.items = updateAdditionalProperties(schema.items, newValue);
    }
  }

  if (schema.allOf) {
    schema.allOf = schema.allOf.map((item) =>
      updateAdditionalProperties(item, newValue),
    );
  }

  if (schema.anyOf) {
    schema.anyOf = schema.anyOf.map((item) =>
      updateAdditionalProperties(item, newValue),
    );
  }

  if (schema.oneOf) {
    schema.oneOf = schema.oneOf.map((item) =>
      updateAdditionalProperties(item, newValue),
    );
  }

  if (schema.not) {
    schema.not = updateAdditionalProperties(schema.not, newValue);
  }

  if (schema.definitions) {
    Object.keys(schema.definitions).forEach((key) => {
      schema.definitions![key] = updateAdditionalProperties(
        schema.definitions![key],
        newValue,
      );
    });
  }

  if (schema.patternProperties) {
    Object.keys(schema.patternProperties).forEach((key) => {
      schema.patternProperties![key] = updateAdditionalProperties(
        schema.patternProperties![key],
        newValue,
      );
    });
  }

  if (schema.dependencies) {
    Object.keys(schema.dependencies).forEach((key) => {
      const dependency = schema.dependencies![key];
      if (typeof dependency === "object" && !Array.isArray(dependency)) {
        schema.dependencies![key] = updateAdditionalProperties(
          dependency as JSONSchema,
          newValue,
        );
      }
    });
  }

  return schema;
};
