import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useDuplicatePromptTemplateToWorkspace } from "@/queries";
import { displayToast } from "@/utils/toast";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

type DuplicateToWorkspaceModalProps = {
  open: boolean;
  prompt: any;
  setOpen: (open: boolean) => void;
};

export const DuplicateToWorkspaceModal: FC<DuplicateToWorkspaceModalProps> = ({
  open,
  prompt,
  setOpen,
}) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useAuth();
  const userContext = useUser();

  const userToken = authContext!.userToken;
  const duplicatePromptTemplateToWorkspace =
    useDuplicatePromptTemplateToWorkspace(userToken!);

  const workspacesList = useMemo((): any[] => {
    return [...userContext.workspaces].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [userContext.workspaces]);

  const handleSelectWorkspace = (workspace: any) => {
    setSelectedWorkspace({
      id: workspace.id,
      name: workspace.name,
    });
  };

  const navigate = useNavigate();
  const handleDuplicate = async () => {
    let response;
    try {
      if (!selectedWorkspace?.id) return;
      setIsLoading(true);
      response = await duplicatePromptTemplateToWorkspace.mutateAsync({
        workspace_id: selectedWorkspace?.id,
        prompt_template_id: prompt?.id,
      });
      displayToast("Template duplicated", ToastType.success);
      navigate(
        `/workspace/${selectedWorkspace?.id}/prompt/${response?.prompt_template?.id}`,
      );
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderOptions = () => {
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full">
            {selectedWorkspace?.name || (
              <span className="text-gray-500">Select a workspace...</span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {workspacesList
              .sort((workspace: any) =>
                workspace.id === userContext.activeWorkspace?.id ? -1 : 1,
              )
              .map((workspace: any) => (
                <DropdownMenuItem
                  key={workspace.id}
                  onSelect={() => handleSelectWorkspace(workspace)}
                >
                  {workspace.name}
                  {workspace.id === userContext.activeWorkspace?.id
                    ? " (current)"
                    : ""}
                </DropdownMenuItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="overflow-x-hidden px-10">
        <DialogHeader>
          <DialogTitle className="break-all">
            Duplicate Prompt Template "{prompt?.prompt_name}"
          </DialogTitle>
        </DialogHeader>
        <div className="my-2 grid h-10 grid-cols-3 items-center gap-2 overflow-y-auto transition-all duration-500 ease-in-out">
          <label className="col-span-1 mr-2 text-sm">
            Destination Workspace
          </label>
          <div className="col-span-2">{renderOptions()}</div>
        </div>
        <DialogFooter>
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDuplicate}
            disabled={isLoading}
            className="w-24 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          >
            {isLoading ? <LoadingSpinner size={5} /> : "Duplicate"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
