import codeCircleIcon from "@/assets/code-circle.svg";
import { RenamePrompt } from "@/components";
import DropdownMenu from "@/components/DropdownMenu";
import { DuplicateToWorkspaceModal } from "@/components/DuplicateToWorkspaceModal";
import { MoveToFolderModal } from "@/components/MoveToFolderModal";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useDeletePromptTemplate, useEditPromptTemplate } from "@/queries";
import { PromptRegistry } from "@/types/prompt-registry";
import { displayToast } from "@/utils/toast";
import { EmojiSadIcon } from "@heroicons/react/outline";
import { History } from "lucide-react";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BranchToWorkspaceModal } from "../BranchToWorkspaceModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal";

interface TemplateGridProps {
  templates: Array<PromptRegistry>;
}

export default function TemplateGrid({ templates }: TemplateGridProps) {
  const [isDeleteTemplateModalOpen, setDeleteTemplateModalOpen] =
    useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<null | PromptRegistry>(null);
  const auth = useAuth();
  const userContext = useUser();
  const [isMoveToFolderModalOpen, setMoveToFolderModalOpen] = useState(false);
  const [isDuplicateToWorkspaceModalOpen, setDuplicateToWorkspaceModalOpen] =
    useState(false);
  const [isBranchModalOpen, setBranchModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [selectedPromptTemplate, setSelectedPromptTemplate] =
    useState<null | PromptRegistry>(null);
  const editPromptTemplate = useEditPromptTemplate();
  const deletePrompt = useDeletePromptTemplate(auth?.userToken!);

  if (templates.length === 0) {
    return (
      <div className="mt-12 flex h-full flex-col items-center justify-center">
        <div className="text-2xl text-gray-500">
          No prompts found{" "}
          <EmojiSadIcon className="-mt-1 inline h-6 w-auto text-gray-500" />
        </div>
        <div className="text-md pt-3 text-gray-500">
          Learn more about the Prompt Registry on our{" "}
          <a
            className="text-blue-500 hover:text-blue-400"
            href="https://docs.promptlayer.com/features/prompt-registry"
          >
            docs
          </a>
          .
        </div>
      </div>
    );
  }

  const handleFolderMoveClick = (prompt: any) => {
    setSelectedPromptTemplate(prompt);
    setMoveToFolderModalOpen(true);
  };

  const handleDuplicateToWorkspaceClick = (prompt: any) => {
    setSelectedPromptTemplate(prompt);
    setDuplicateToWorkspaceModalOpen(true);
  };

  const handleBranchToWorkspaceClick = (prompt: any) => {
    setSelectedPromptTemplate(prompt);
    setBranchModalOpen(true);
  };

  const handleRenameClick = (prompt: any) => {
    setSelectedPromptTemplate(prompt);
    setRenameModalOpen(true);
  };

  const handleRenamePrompt = (name: string) => {
    if (selectedPromptTemplate) {
      editPromptTemplate.mutate({
        prompt_id: selectedPromptTemplate.id,
        activeWorkspaceId: userContext?.activeWorkspaceId!,
        userToken: auth?.userToken!,
        basePromptTemplate: {
          prompt_name: name,
          tags: selectedPromptTemplate.tags,
        },
      });
    }
    setRenameModalOpen(false);
  };

  const handleDeleteClick = (template: PromptRegistry, e: MouseEvent) => {
    e.stopPropagation();
    setDeleteTemplateModalOpen(true);
    setSelectedTemplate(template);
  };

  const handleDelete = async () => {
    try {
      await deletePrompt.mutateAsync(selectedTemplate!);
      displayToast("Template deleted", ToastType.success);
      setDeleteTemplateModalOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const promptCreatedDate = (prompt: PromptRegistry) =>
    moment(prompt.last_version_created_at).fromNow();

  const renderCardTop = (prompt: PromptRegistry, link: string) => {
    return (
      <div className="w-full">
        <div className="flex items-center justify-between">
          <div className="flex-1 overflow-hidden">
            <Link to={link}>
              <h3 className="text-md title cursor-pointer break-all font-medium group-hover:text-blue-500">
                <img
                  alt="Code Circle Icon"
                  className="-mt-1 mr-2 inline h-5 w-auto"
                  src={codeCircleIcon}
                />
                {prompt.prompt_name}
              </h3>
            </Link>
          </div>
          <DropdownMenu
            iconSize="5"
            menuItems={[
              {
                text: "Move to folder",
                onClick: () => handleFolderMoveClick(prompt),
              },
              {
                text: "Duplicate",
                onClick: () => handleDuplicateToWorkspaceClick(prompt),
              },
              {
                text: "Copy a version",
                onClick: () => handleBranchToWorkspaceClick(prompt),
              },
              {
                text: "Rename",
                onClick: () => handleRenameClick(prompt),
              },
              {
                text: "Delete",
                onClick: (e) => handleDeleteClick(prompt, e),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const renderCards = () => {
    return templates.map((prompt) => (
      <li
        className="group col-span-1 flex flex-col rounded-md border-2 border-gray-100 bg-white px-4 py-3 shadow-sm hover:border-blue-100"
        key={`prompt-template-${prompt.id}`}
      >
        {renderCardTop(
          prompt,
          `/workspace/${userContext?.activeWorkspaceId}/prompt/${prompt.id}`,
        )}
        <div className="h-20 w-full cursor-pointer pt-2">
          <Link
            to={`/workspace/${userContext?.activeWorkspaceId}/prompt/${prompt.id}`}
          >
            <h4 className="word-break-all line-clamp-3 pr-12 text-sm font-normal text-gray-500">
              {prompt.description}
            </h4>
          </Link>
        </div>
        <Link
          to={`/workspace/${userContext?.activeWorkspaceId}/prompt/${prompt.id}`}
          className="flex h-full flex-col justify-end"
        >
          <div className="mt-auto flex justify-end pt-1">
            <div className="flex flex-1 gap-x-1 overflow-x-auto whitespace-nowrap">
              {prompt.tags.map((tag: string) => (
                <div
                  className="inline rounded-sm border-none bg-gray-100 px-1.5 py-1 text-xs font-medium text-gray-900"
                  key={tag}
                >
                  {tag}
                </div>
              ))}
            </div>
            <div className="flex flex-shrink-0 cursor-pointer items-center gap-x-1 text-xs font-normal text-gray-400">
              <History className="ml-3 inline-block h-4 w-4" />
              {promptCreatedDate(prompt)}
            </div>
          </div>
        </Link>
      </li>
    ));
  };

  return (
    <>
      <ul className="grid grid-cols-1 gap-6 px-1 sm:grid-cols-2 lg:grid-cols-3">
        {renderCards()}
      </ul>
      {isDeleteTemplateModalOpen && selectedTemplate && (
        <DeleteConfirmationModal
          title="Are you sure you want to delete this template?"
          description="Deleting this template will delete all versions and cannot be undone."
          onDelete={handleDelete}
          open={isDeleteTemplateModalOpen}
          setOpen={setDeleteTemplateModalOpen}
        />
      )}
      {isMoveToFolderModalOpen && (
        <MoveToFolderModal
          open={isMoveToFolderModalOpen}
          prompt={selectedPromptTemplate}
          setOpen={setMoveToFolderModalOpen}
        />
      )}
      {isDuplicateToWorkspaceModalOpen && (
        <DuplicateToWorkspaceModal
          open={isDuplicateToWorkspaceModalOpen}
          prompt={selectedPromptTemplate}
          setOpen={setDuplicateToWorkspaceModalOpen}
        />
      )}
      {isBranchModalOpen && !!selectedPromptTemplate ? (
        <BranchToWorkspaceModal
          open={isBranchModalOpen}
          prompt={selectedPromptTemplate}
          setOpen={setBranchModalOpen}
        />
      ) : null}
      {selectedPromptTemplate ? (
        <RenamePrompt
          name={selectedPromptTemplate?.prompt_name || ""}
          open={isRenameModalOpen}
          setOpen={setRenameModalOpen}
          onSubmit={handleRenamePrompt}
        />
      ) : null}
    </>
  );
}
