import { ModelConfigMap } from "@/modelConfig";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

export function SelectProviderFromConfigs({
  selectedProvider,
  handleSelectProvider,
  modelConfigs,
}: {
  selectedProvider: string;
  handleSelectProvider: (provider: string) => void;
  modelConfigs: ModelConfigMap;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-full">
        {selectedProvider || (
          <span className="text-gray-400">Select provider...</span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {[...Object.keys(modelConfigs)].map((provider) => (
          <DropdownMenuItem
            key={provider}
            onSelect={() => handleSelectProvider(provider)}
          >
            {provider}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
