interface SidebarCardProps {
  id: string;
  active?: boolean;
  type?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SidebarCard = ({
  id,
  children,
  active,
  type,
  className,
  onClick,
}: SidebarCardProps) => {
  const activeStyle =
    (active &&
      (type && type === "group"
        ? " border-blue-400 bg-blue-100"
        : "border-blue-400 bg-blue-50")) ||
    "bg-white  hover:bg-gray-50";

  const combinedClassName = `${activeStyle} border cursor-pointer space-y-2 rounded-sm py-[10px] pl-3 pr-4 text-xs font-medium text-gray-500 hover:text-gray-600 ${
    className || ""
  }`;

  return (
    <div data-request-id={id} className={combinedClassName} onClick={onClick}>
      {children}
    </div>
  );
};

export default SidebarCard;
