import { RegularParamForm } from "@/components/FunctionsModal/InteractiveFunctionEditor/RegularParamForm";
import {
  ItemProperties,
  Properties,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";
import { handleRemoveRow } from "@/components/FunctionsModal/InteractiveFunctionEditor/utils/InteractiveParameterUtils";

export const StringParameterEditor = ({
  parameter,
  index,
  parameters,
  setParameters,
}: {
  parameter: any;
  index: number;
  parameters: Properties[];
  setParameters: React.Dispatch<React.SetStateAction<Properties[]>>;
}) => {
  const handleChange = <K extends keyof Properties>(
    index: number,
    key: K,
    val: Properties[K] | ItemProperties[K],
    parameters: any[],
    setParameters: (params: any[]) => void,
  ) => {
    const newParams = [...parameters];
    newParams[index][key] = val;
    setParameters(newParams);
  };

  return (
    <RegularParamForm
      parameter={parameter}
      index={index}
      parameters={parameters}
      setParameters={setParameters}
      handleParameterChange={handleChange}
      handleRemoveRow={handleRemoveRow}
      depth={0}
    />
  );
};
