import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useCreateReportColumn, usePatchReportColumn } from "@/queries";
import { ValidationError } from "@/types/errors";
import { ReportColumn } from "@/types/evaluate";
import { ExclamationIcon, LockClosedIcon } from "@heroicons/react/outline";
import { ReactNode, useEffect, useRef, useState } from "react";
import AssertValidBuilder from "./AssertionType/AssertValidBuilder";
import LLMAssertionBuilder from "./AssertionType/LLMAssertionBuilder";
import { ChooseColumnType } from "./ChooseColumnType";
import CoalesceColumnBuilder from "./CoalesceType/CoalesceColumnBuilder";
import CompareColumnBuilder from "./CompareType/CompareColumnBuilder";
import EndpointColumnBuilder from "./EndpointType/EndpointColumnBuilder";
import HumanInputColumnBuilder from "./HumanInputType/HumanInputColumnBuilder";
import JSONPathBuilder from "./JSONType/JsonPathBuilder";
import AbsoluteNumericDistanceBuilder from "./MathType/AbsoluteNumericDistance";
import CosineSimilarityBuilder from "./MathType/CosineSimilarityBuilder";
import CountBuilder from "./MathType/CountBuilder";
import MathOperatorBuilder from "./MathType/OperatorBuilder";
import PromptTemplateColumnBuilder from "./PromptTemplateType/PromptTemplateColumnBuilder";
import RegexBuilder from "./RegexType";
import ContainsBuilder from "./StringManipulationType/ContainsBuilder";
import ParseValueBuilder from "./StringManipulationType/ParseValueBuilder";
import VariableBuilder from "./VariableType";
import XMLPathBuilder from "./XMLPathBuilder";

export const ModalStep = ({
  children,
  navigateNext,
  navigatePrevious,
  nextButtonText = "Next",
  previousButtonText = "Previous",
}: {
  children: ReactNode;
  navigateNext?: () => void;
  navigatePrevious?: () => void;
  nextButtonText?: string;
  previousButtonText?: string;
}) => {
  return (
    <div>
      {children}
      <div className="mt-4 flex justify-end gap-x-2">
        {navigatePrevious && (
          <Button variant="outline" onClick={navigatePrevious}>
            {previousButtonText}
          </Button>
        )}
        {navigateNext && (
          <Button onClick={navigateNext}>{nextButtonText}</Button>
        )}
      </div>
    </div>
  );
};

const ModalRouter = ({
  children,
  availableColumns,
  reportId,
  oldColumn,
  editable = true,
  onClose,
}: {
  children: ReactNode;
  availableColumns: ReportColumn[];
  reportId: number;
  oldColumn?: ReportColumn;
  editable?: boolean;
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (onClose && prevOpen.current && !open) {
      prevOpen.current = false;
      onClose();
    } else if (prevOpen.current !== open) {
      prevOpen.current = open;
    }
  }, [onClose, open]);

  // This is done so that the modal is re-rendered on every open change
  return (
    <ModalRouterHelper
      key={String(open)}
      reportId={reportId}
      open={open}
      setOpen={setOpen}
      availableColumns={availableColumns}
      oldColumn={oldColumn}
      editable={editable}
    >
      {children}
    </ModalRouterHelper>
  );
};

const ModalRouterHelper = ({
  children,
  availableColumns,
  reportId,
  oldColumn,
  open,
  setOpen,
  editable,
}: {
  children: ReactNode;
  availableColumns: ReportColumn[];
  reportId: number;
  oldColumn?: ReportColumn;
  open: boolean;
  setOpen: (open: boolean) => void;
  editable: boolean;
}) => {
  const FIRST_MODAL_STEP = oldColumn ? "setup-column" : "choose-type";
  const [currentModalNavigation, setCurrentModalNavigation] =
    useState(FIRST_MODAL_STEP);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const userToken = useAuth()?.userToken;
  const {
    mutate: createReportColumn,
    data: createReportColumnData,
    isLoading: createReportColumnIsLoading,
    error: createReportColumnError,
  } = useCreateReportColumn(userToken || "", reportId);
  const {
    mutate: patchReportColumn,
    data: patchData,
    isLoading: patchIsLoading,
    error: patchError,
  } = usePatchReportColumn(userToken || "", reportId);
  const data = oldColumn ? patchData : createReportColumnData;
  const isLoading = createReportColumnIsLoading || patchIsLoading;
  const errorData: { message?: ValidationError[]; success?: boolean } | null =
    createReportColumnError || patchError || null;

  const [newColumnData, setNewColumnData] = useState<Partial<ReportColumn>>(
    oldColumn || {},
  );
  const patchColumnData = (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => {
    setNewColumnData((prevState) => {
      if (typeof data === "function") {
        return {
          ...prevState,
          ...data(prevState),
        };
      } else {
        return {
          ...prevState,
          ...data,
        };
      }
    });
  };

  const saveColumnAction = (newColumnDataArg: ReportColumn) => {
    const newColumnDataBuilder: ReportColumn = { ...newColumnDataArg };
    if (!oldColumn) {
      // Creating column from scratch, we need to add a few things
      const maxPosition =
        availableColumns.length > 0
          ? Math.max(...availableColumns.map((c) => c.position || 0))
          : 0;
      newColumnDataBuilder.position = maxPosition + 1;
      newColumnDataBuilder.report_id = reportId;
      if (newColumnDataBuilder as ReportColumn) {
        createReportColumn(newColumnDataBuilder as ReportColumn);
      } else {
        console.error("Error: Invalid step data type");
      }
    } else if (newColumnDataBuilder) {
      patchReportColumn(newColumnDataBuilder);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      if (data.success) {
        setOpen(false);
      } else {
        if (data.message && data.message[0]) {
          if (data.message[0].loc && data.message[0].loc[0]) {
            setErrorMessage(
              `Error: ${data.message[0].loc[0]} (${data.message[0].msg})`,
            );
          } else {
            setErrorMessage(`Error: ${data.message[0].msg}`);
          }
        } else {
          setErrorMessage("Error: Unknown error occurred");
        }
      }
    }
    if (!isLoading && errorData && errorData.message) {
      if (errorData.message[0] && errorData.message[0].loc) {
        if (errorData.message[0].loc[0]) {
          setErrorMessage(
            `Error: ${errorData.message[0].loc[0]} (${errorData.message[0].msg})`,
          );
        } else {
          setErrorMessage(`Error: ${errorData.message[0].msg}`);
        }
      } else {
        setErrorMessage("Error: Unknown error occurred");
      }
    }
  }, [isLoading, data, setOpen, errorData]);

  const modalNavigation: { [key: string]: () => ReactNode } = {
    "choose-type": () => (
      <ModalStep
        navigateNext={
          newColumnData.column_type
            ? () => setCurrentModalNavigation("setup-column")
            : undefined
        }
      >
        <ChooseColumnType
          patchColumnData={patchColumnData}
          columnData={newColumnData}
          editable={editable}
        />
      </ModalStep>
    ),
    "setup-column": () => {
      const { column_type } = newColumnData;
      let modalStepElement: any;
      if (column_type === "PROMPT_TEMPLATE") {
        modalStepElement = (
          <PromptTemplateColumnBuilder
            columnData={newColumnData}
            patchColumnData={patchColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "ENDPOINT") {
        modalStepElement = (
          <EndpointColumnBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "HUMAN") {
        modalStepElement = (
          <HumanInputColumnBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "COMPARE") {
        modalStepElement = (
          <CompareColumnBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "COALESCE") {
        modalStepElement = (
          <CoalesceColumnBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "ABSOLUTE_NUMERIC_DISTANCE") {
        modalStepElement = (
          <AbsoluteNumericDistanceBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "REGEX") {
        modalStepElement = (
          <RegexBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "JSON_PATH") {
        modalStepElement = (
          <JSONPathBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "PARSE_VALUE") {
        modalStepElement = (
          <ParseValueBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "CONTAINS") {
        modalStepElement = (
          <ContainsBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "LLM_ASSERTION") {
        modalStepElement = (
          <LLMAssertionBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "VARIABLE") {
        modalStepElement = (
          <VariableBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "ASSERT_VALID") {
        modalStepElement = (
          <AssertValidBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "COSINE_SIMILARITY") {
        modalStepElement = (
          <CosineSimilarityBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "COUNT") {
        modalStepElement = (
          <CountBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "MATH_OPERATOR") {
        modalStepElement = (
          <MathOperatorBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      } else if (column_type === "XML_PATH") {
        modalStepElement = (
          <XMLPathBuilder
            columnData={newColumnData}
            navigatePrevious={() => setCurrentModalNavigation("choose-type")}
            availableColumns={availableColumns}
            saveColumnAction={
              editable ? saveColumnAction : () => setOpen(false)
            }
            editable={editable}
          />
        );
      }

      return modalStepElement;
    },
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setCurrentModalNavigation(FIRST_MODAL_STEP);
      setNewColumnData({});
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {/* This button triggers the modal */}
        {children}
      </DialogTrigger>
      <DialogContent>
        <div className="p-2">
          {!editable && (
            <div className="w-34 mx-auto my-4 rounded-md bg-gray-100 p-2 text-xs text-gray-600">
              <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
              [View-Only] Batch run has already been started and is not
              editable.
            </div>
          )}
          {modalNavigation[currentModalNavigation]()}
          {errorMessage && (
            <div className="w-34 mx-auto mt-4 rounded-md bg-red-100 p-2 text-xs text-red-600">
              <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
              {errorMessage}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRouter;
