import ActiveFilters from "@/components/ui/table/Filter/ActiveFilters";
import ExpandableSearchbar from "@/components/ui/table/Search/ExpandableSearchbar";
import { memo } from "react";

const TableControls = ({ data }: any) => {
  const {
    globalSearchTerm,
    setGlobalSearchTerm,
    filters,
    removeFilter,
    currentFilterInput,
  } = data;
  return (
    <div className="mb-4 flex flex-wrap items-center gap-2">
      <ExpandableSearchbar
        globalSearchTerm={globalSearchTerm}
        setGlobalSearchTerm={setGlobalSearchTerm}
      />
      <ActiveFilters
        filters={filters}
        removeFilter={removeFilter}
        currentFilterInput={currentFilterInput}
      />
    </div>
  );
};

export default memo(TableControls);
