const ParameterHeader = () => {
  return (
    <div className="grid grid-cols-8 items-center gap-4 rounded-md border border-gray-200 bg-gray-50 p-1 text-xs text-gray-700">
      <div className="col-span-1"></div>
      <div className="col-span-1 text-center">Required</div>
      <div className="col-span-2">Name</div>
      <div className="col-span-2">Type</div>
      <div className="col-span-2">Title</div>
    </div>
  );
};

export default ParameterHeader;
