import { FunctionDeclarationsFormatter } from "@/components/FunctionDeclarationsFormatter";
import { CopyButton } from "@/components/ui/copy-button";
import { Function_ } from "@/types";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, TerminalIcon } from "@heroicons/react/outline";

export const FunctionDefinitionBox = ({
  functionName,
  functionDeclaration,
  startOpen = false,
}: {
  functionName: string;
  functionDeclaration: Function_;
  startOpen?: boolean;
}) => {
  const isFunctionDeclaration = functionDeclaration !== undefined;

  return (
    <Disclosure defaultOpen={startOpen}>
      {({ open }) => (
        <div className={`group border border-gray-300`}>
          <Disclosure.Button
            className={`w-full justify-between rounded-md p-2 text-gray-500 group-hover:bg-gray-50 ${
              open && "bg-gray-50"
            }`}
          >
            <div className="flex">
              <div className="flex-1">
                <div
                  className={`flex items-center space-x-1 hover:border-gray-500 group-hover:text-gray-900`}
                >
                  <div
                    className={`group-hover:inherit text-sm ${
                      open ? "text-gray-900" : ""
                    }`}
                  >
                    <TerminalIcon className="mr-2 inline h-4 w-4" />
                    {functionName}
                  </div>
                </div>
              </div>
              <div className="group-hover:text-gray-800">
                <ChevronUpIcon
                  className={`${!open ? "rotate-180 transform" : ""} h-4 w-4`}
                />
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            {isFunctionDeclaration && (
              <div className="flex items-start justify-between">
                <div className="w-full p-4">
                  <FunctionDeclarationsFormatter
                    functionDeclarations={[functionDeclaration]}
                    functionCall={""}
                  />
                </div>
                <div className="p-4">
                  <CopyButton text={JSON.stringify(functionDeclaration)} />
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
