import { ApiKeyComponent } from "@/components/ApiKeyModal";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { APIKeysSection } from "@/components/ConfigureAPIKeysModal";
import ProviderBaseURLs from "@/components/ProviderBaseURLs";
import SubscriptionStatusBanner from "@/components/SubscriptionStatusBanner";
import { Button } from "@/components/ui/button";
import WebhookForm from "@/components/WebhookForm";
import Workspace from "@/components/Workspace";
import { useUser } from "@/context/user-context";
import {
  ChartSquareBarIcon,
  FolderOpenIcon,
  LightBulbIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PromptLayerApiKeyTable } from "./components/PromptLayerApiKeyTable";
import { UsageMetersAll } from "./components/UsageMeters";
import HuggingFaceSettingsBlock from "./HuggingFaceSettingsBlock";

const Settings: FC = () => {
  const [showPricingModal, setShowPricingModal] = useState(false);
  const navigate = useNavigate();
  const userContext = useUser();
  const subscriptionStatus = userContext.subscriptionStatus;
  const planName = userContext.stripePlanName;
  const workspaceName = userContext.activeWorkspace?.name || "Personal";

  const renderTopSection = () => {
    return (
      <div className="flex pt-4">
        <div className="flex-1 pt-4">
          <h1 className="text-2xl text-gray-900">Settings</h1>
          <h2 className="pt-1 text-lg text-gray-500">
            Your PromptLayer settings.
          </h2>
        </div>
        <div className="pt-4">
          <Button variant={"outline"} onClick={() => navigate("/logout")}>
            <XIcon className="mr-1 h-5 w-auto text-gray-700" />
            Sign Out
          </Button>
        </div>
      </div>
    );
  };

  const sections = [
    {
      title: "Billing",
      description: "Manage your billing information",
      renderElement: () => (
        <div className="flex flex-col space-y-2 py-4">
          <UsageMetersAll />
          {!userContext.userIsFreePlan ? (
            <>
              <span className="text-sm text-gray-500">
                You are currently on the{" "}
                <span className="text-sm font-semibold capitalize">
                  {planName}
                </span>
                {" plan. "}
                {subscriptionStatus?.data?.billing_portal_url && (
                  <span>
                    Use the{" "}
                    <a
                      href={subscriptionStatus?.data?.billing_portal_url}
                      className="text-sm text-blue-500 hover:underline"
                    >
                      Stripe portal
                    </a>{" "}
                    to edit your billing details.
                  </span>
                )}
              </span>
              <div className="py-2">
                <Button
                  variant={"outline"}
                  onClick={() =>
                    (window.location.href =
                      subscriptionStatus?.data?.billing_portal_url)
                  }
                >
                  Manage your account
                </Button>
              </div>
            </>
          ) : (
            <>
              <span className="text-sm text-gray-500">
                You are currently on the{" "}
                <span className="text-sm font-semibold capitalize">
                  {planName}
                </span>{" "}
                plan. Use the{" "}
                <a
                  href={subscriptionStatus?.data?.billing_portal_url}
                  className="text-sm text-blue-500 hover:underline"
                >
                  Stripe portal
                </a>{" "}
                to edit your billing details.
              </span>
              <div className="py-2">
                <Button
                  variant={"outline"}
                  onClick={() => setShowPricingModal(true)}
                >
                  Click to Change Plans
                </Button>
              </div>
            </>
          )}
          <span className="text-sm text-gray-500">
            Learn more about pricing in our{" "}
            <a
              href="https://docs.promptlayer.com/why-promptlayer/how-it-works#pricing"
              className="text-sm text-blue-500 hover:underline"
            >
              docs
            </a>{" "}
            or contact us at{" "}
            <a
              href="mailto:hello@promptlayer.com"
              className="text-sm text-blue-500 hover:underline"
            >
              hello@promptlayer.com
            </a>
          </span>
        </div>
      ),
    },
    {
      title: `Workspace Members`,
      description: (
        <div className="">
          Work together as a team using workspaces. Use the workspace selector
          in the navbar to switch. Learn more
          <a
            href="https://docs.promptlayer.com/why-promptlayer/shared-workspaces"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-400"
          >
            {" here"}
          </a>
          .
          <h1 className="mt-6 text-sm">
            You are currently in the{" "}
            <span className="font-semibold">"{workspaceName}"</span> workspace.
          </h1>
        </div>
      ),
      renderElement: () => <Workspace />,
    },
    {
      title: "PromptLayer API Keys",
      workspaceSpecific: true,
      description: "Generate a new API key for PromptLayer.",
      renderElement: () => (
        <>
          <ApiKeyComponent />
          <PromptLayerApiKeyTable />
        </>
      ),
    },
    {
      title: "Saved API Keys",
      workspaceSpecific: true,
      description: "Manage your API keys for OpenAI, Anthropic, etc.",
      renderElement: () => (
        <div className="my-6 rounded-lg border border-gray-200 p-4 shadow-sm">
          <APIKeysSection />
        </div>
      ),
    },
    {
      title: "Hugging Face",
      description: "Configure Hugging Face access for your workspace.",
      renderElement: () => <HuggingFaceSettingsBlock />,
    },
    {
      title: `Provider Base URLs`,
      description: (
        <div>Add URLs to communicate with custom models and services.</div>
      ),
      renderElement: () => <ProviderBaseURLs />,
    },
    {
      title: "Webhook",
      workspaceSpecific: true,
      description:
        "Prompt template state change events will be sent to your webhook. This is useful if you maintain a cache.",
      renderElement: () => <WebhookForm />,
    },
  ];

  const quickLinks = [
    {
      title: "Docs",
      link: "https://docs.promptlayer.com",
      icon: <FolderOpenIcon className="h-5 w-auto text-gray-700" />,
    },
    {
      title: "Latency Graph",
      link: "https://dashboard.promptlayer.com/latency",
      icon: <ChartSquareBarIcon className="h-5 w-auto text-gray-700" />,
    },
    {
      title: "Feature Requests",
      link: "https://promptlayer.canny.io/feature-requests",
      icon: <LightBulbIcon className="h-5 w-auto text-gray-700" />,
    },
    {
      title: "Discord",
      link: "https://discord.gg/DBAhQbW39S",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          className="h-5 w-auto text-gray-700"
        >
          <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
        </svg>
      ),
    },
  ];

  const scrollToSection = (sectionTitle: string) => {
    const element = document.getElementById(sectionTitle.replace(" ", "_"));
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col pb-20">
      <div className="bg-white p-1">
        <Breadcrumbs
          items={["Settings"]}
          navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/home`}
        />
        {renderTopSection()}
      </div>
      <div className="mx-3 my-10 rounded-lg border border-gray-100 bg-gray-50 p-5 shadow-md">
        <h2 className="pb-3 text-lg font-semibold text-gray-700">
          Quick Links
        </h2>
        <div className="grid grid-cols-4 gap-x-4 gap-y-8 py-3">
          {quickLinks.map((link, index) => (
            <a
              key={index}
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              className="group flex flex-col items-center text-center text-blue-500 transition-colors duration-200"
            >
              <div className="rounded-full p-2 transition-colors duration-200 group-hover:bg-blue-200">
                {link.icon}
              </div>
              <span className="mt-2 text-gray-700 group-hover:text-gray-500">
                {link.title}
              </span>
            </a>
          ))}
        </div>
      </div>
      <div className="flex flex-row gap-x-10 p-1">
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          <div className="space-y-6">
            {sections.map((section, index) => (
              <div
                className="border-b border-gray-200 py-6"
                id={section.title.replace(" ", "_")}
                key={index}
              >
                <h2 className="text-xl font-semibold">
                  {section.title}
                  {section.workspaceSpecific && (
                    <span className="py-.5 ml-2 rounded-md bg-gray-200 px-2 text-xs font-light text-gray-800">
                      For Current Workspace
                    </span>
                  )}
                </h2>
                <div className="mt-1 text-gray-500">{section.description}</div>
                {section.renderElement && section.renderElement()}
              </div>
            ))}
          </div>
        </div>
        <div className="sticky top-0 w-[300px] pl-12">
          <div className="sticky top-10 flex flex-col space-y-4">
            {sections.map((section, index) => (
              <div key={index}>
                <button
                  className="cursor-pointer rounded-lg p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
                  onClick={() => scrollToSection(section.title)}
                >
                  {section.title}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SubscriptionStatusBanner
        subscriptionStatus={subscriptionStatus.data}
        subscriptionStatusisLoading={subscriptionStatus.isLoading}
        showPricingModal={showPricingModal}
        setShowPricingModal={setShowPricingModal}
      />
    </div>
  );
};

export default Settings;
