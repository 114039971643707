import moment from "moment-timezone";
import { FC, useCallback } from "react";

import pythonLogo from "@/assets/python-logo.png";
import HistoryPageNavbar from "@/components/ContentNavbar/HistoryPageNavbar";
import LoadingSpinner from "@/components/LoadingSpinner";
import RequestDisplay from "@/components/RequestDisplay";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useRequest } from "@/queries";
import { SpanNode } from "@/types/spans";
import { FilterIcon } from "@heroicons/react/outline";
import { FilterIcon as FilledFilterIcon } from "@heroicons/react/solid";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../ui/button";
import { CalloutWithTitle } from "../ui/callout";

interface SpanDetailsProps {
  selectedSpan: SpanNode | null;
}

const formatDate = (dateString: string) => {
  return moment.utc(dateString).local().format("MMM D, YYYY, hh:mm:ss.SSS A");
};

const SpanDetails: FC<SpanDetailsProps> = ({ selectedSpan }) => {
  const auth = useAuth();
  const userContext = useUser();
  const requestId = selectedSpan?.request_log_id?.toString() || "";
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: requestData, isLoading: isRequestLoading } = useRequest(
    requestId,
    auth?.userToken || "",
    !!selectedSpan?.request_log_id,
    userContext?.activeWorkspaceId!,
  );

  const toggleFilter = useCallback(
    (key: string, value: any) => {
      setSearchParams((prev) => {
        const metadata = JSON.parse(prev.get("metadata") || "[]");
        const existingIndex = metadata.findIndex(
          (item: { [key: string]: any }) =>
            item.key === key && item.value === value,
        );
        if (existingIndex !== -1) {
          metadata.splice(existingIndex, 1);
        } else {
          metadata.push({ key, value });
        }
        prev.set("metadata", JSON.stringify(metadata));
        return prev;
      });
      return true;
    },
    [setSearchParams],
  );

  const isFiltered = useCallback(
    (key: string, value: any) =>
      searchParams.get("metadata")?.includes(`"key":"${key}"`) &&
      searchParams
        .get("metadata")
        ?.includes(`"value":${JSON.stringify(value)}`),
    [searchParams],
  );

  const parseJsonString = useCallback((jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  }, []);

  if (!selectedSpan) {
    return (
      <div className="flex h-full items-center justify-center text-gray-500">
        <p>Select a span to view its details</p>
      </div>
    );
  }

  const renderAttributes = (attributes: Record<string, any>) => {
    const filteredAttributes = Object.entries(attributes).filter(
      ([key]) => key !== "function_input" && key !== "function_output",
    );
    const hasAttributes = filteredAttributes.length > 0;

    if (!hasAttributes) return null;

    return (
      <CalloutWithTitle title="Attributes">
        {filteredAttributes.map(([key, value]) => {
          const usingFilter = isFiltered(key, value);

          const buttonClass = "ml-2 flex items-center";

          const buttonType = usingFilter ? "default" : "ghostGray";
          const iconComponent = usingFilter ? FilledFilterIcon : FilterIcon;

          return (
            <div key={key} className="flex items-center">
              <span className="mr-2 font-medium text-gray-600">{key}:</span>
              <span className="text-gray-800">{JSON.stringify(value)}</span>
              <Button
                variant={buttonType}
                className={buttonClass}
                size={"sm"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleFilter(key, value);
                }}
              >
                {React.createElement(iconComponent, {
                  className: "w-4 h-4 mr-1",
                })}
              </Button>
            </div>
          );
        })}
      </CalloutWithTitle>
    );
  };

  const renderFunctionInput = (attributes: Record<string, any>) => {
    if (!attributes.function_input) return null;

    return (
      <CalloutWithTitle title="Input">
        {renderJsonOrString(attributes.function_input)}
      </CalloutWithTitle>
    );
  };

  const renderFunctionOutput = (attributes: Record<string, any>) => {
    if (!attributes.function_output) return null;

    return (
      <CalloutWithTitle title="Output">
        {renderJsonOrString(attributes.function_output)}
      </CalloutWithTitle>
    );
  };

  const renderJsonOrString = (content: string) => {
    const parsedJson = parseJsonString(content);

    if (parsedJson) {
      return (
        <pre className="whitespace-pre-wrap text-gray-800">
          {JSON.stringify(parsedJson, null, 2)}
        </pre>
      );
    } else {
      return <pre className="whitespace-pre-wrap text-gray-800">{content}</pre>;
    }
  };

  const renderRequestLog = () => {
    if (!selectedSpan.request_log_id) return null;

    const requestId = selectedSpan.request_log_id.toString();
    const requestInfo = requestData?.items?.[0];

    return (
      <div className="flex flex-col">
        {isRequestLoading ? (
          <LoadingSpinner size={5} />
        ) : requestInfo ? (
          <>
            <div className="mb-8">
              <HistoryPageNavbar
                requestId={requestId}
                requestInfo={requestInfo}
              />
            </div>
            <RequestDisplay requestId={requestId} requestInfo={requestInfo} />
          </>
        ) : (
          <p className="text-gray-500">No request log data available</p>
        )}
      </div>
    );
  };

  const renderResource = (resource: Record<string, any>) => {
    const hasResource = Object.keys(resource).length > 0;
    if (!hasResource) return null;

    return (
      <CalloutWithTitle title="Resource">
        {Object.entries(resource).map(([key, value]) => (
          <div key={key} className=" flex items-center">
            <span className="mr-2 font-medium text-gray-600">{key}:</span>
            {key === "service.name" && value === "prompt-layer-library" && (
              <img
                alt="python-logo"
                src={pythonLogo}
                className="mr-2 h-[16px] w-auto"
              />
            )}
            <span className="text-gray-800">{value}</span>
          </div>
        ))}
      </CalloutWithTitle>
    );
  };

  const renderSpanDetailsTable = () => {
    return (
      <div className="mb-6 grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm font-semibold text-gray-600">Span ID</p>
          <p className="text-gray-800">{selectedSpan.span_id}</p>
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-600">Parent ID</p>
          <p className="text-gray-800">{selectedSpan.parent_id || "None"}</p>
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-600">Start Time</p>
          <p className="text-gray-800">{formatDate(selectedSpan.start)}</p>
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-600">End Time</p>
          <p className="text-gray-800">{formatDate(selectedSpan.end)}</p>
        </div>
        {selectedSpan.request_log_id && (
          <div>
            <p className="text-sm font-semibold text-gray-600">
              Request Log ID
            </p>
            <p className="text-gray-800">{selectedSpan.request_log_id}</p>
          </div>
        )}
      </div>
    );
  };

  const renderSpanInformation = () => {
    if (selectedSpan.request_log_id) return null;

    return (
      <>
        <h3 className="mb-4 text-xl font-bold text-gray-800">
          {selectedSpan.name}
        </h3>
        {renderSpanDetailsTable()}
        {renderResource(selectedSpan.resource)}
        {renderAttributes(selectedSpan.attributes)}
        {renderFunctionInput(selectedSpan.attributes)}
        {renderFunctionOutput(selectedSpan.attributes)}
      </>
    );
  };

  return (
    <div className="h-full overflow-auto">
      {renderSpanInformation()}
      {renderRequestLog()}
    </div>
  );
};

export default SpanDetails;
