import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useAuth } from "../context/auth-context";
import { useUser } from "../context/user-context";
import {
  useGetProviderApiKeyStatus,
  useSetOrRemoveAzureKeys,
  useSetOrRemoveBedrockKeys,
  useSetOrRemoveProviderApiKey,
} from "../queries";
import LoadingSpinner from "./LoadingSpinner";

type BedrockApiKeys = {
  bedrock_aws_access_key: string;
  bedrock_aws_secret_key: string;
  bedrock_aws_region: string;
};

type AzureApiKeys = {
  azure_api_key: string;
  azure_endpoint: string;
  azure_api_version: string;
};

type ConfigureAPIKeysModalProps = {
  setOpen: (isOpen: boolean) => void;
};

const ConfigureAPIKeysModal: React.FC<ConfigureAPIKeysModalProps> = ({
  setOpen,
}) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [setOpen]);

  const handleClose = () => {
    // Update the parent state to close the modal
    setOpen(false);
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center"
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-gray-800/30 backdrop-blur-sm"></div>
      <div
        className="relative z-10 mx-4 bg-white p-6 shadow-lg md:mx-auto md:w-full md:max-w-md md:rounded-lg lg:max-w-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="absolute right-0 top-0 p-2">
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={handleClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <APIKeysSection />
      </div>
    </div>
  );
};

export function APIKeysSection() {
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken!;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAiKeySet, setIsOpenAiKeySet] = useState(true);
  const [isAnthropicKeySet, setIsAnthropicKeySet] = useState(true);
  const [isAzure, setIsAzure] = useState(true);
  const [isCohere, setIsCohere] = useState(true);
  const [isMistral, setIsMistral] = useState(true);
  const [isBedrock, setIsBedrock] = useState(true);
  const [isGoogle, setIsGoogle] = useState(true);
  const [openaiApiKey, setOpenaiApiKey] = useState("");
  const [anthropicApiKey, setAnthropicApiKey] = useState("");
  const [cohereApiKey, setCohereApiKey] = useState("");
  const [googleApiKey, setGoogleApiKey] = useState("");
  const [mistralApiKey, setMistralApiKey] = useState("");
  const [bedrockApiKey, setBedrockApiKey] = useState<BedrockApiKeys>({
    bedrock_aws_access_key: "",
    bedrock_aws_secret_key: "",
    bedrock_aws_region: "",
  });
  const [azureApiKey, setAzureApiKey] = useState<AzureApiKeys>({
    azure_api_key: "",
    azure_endpoint: "",
    azure_api_version: "",
  });

  const { data: apiKeyStatuses, isLoading: statusIsLoading } =
    useGetProviderApiKeyStatus(userToken, userContext?.activeWorkspaceId!);

  useEffect(() => {
    if (apiKeyStatuses && !statusIsLoading) {
      setIsOpenAiKeySet(apiKeyStatuses.openai);
      setIsAzure(apiKeyStatuses["openai.azure"]);
      setIsAnthropicKeySet(apiKeyStatuses.anthropic);
      setIsCohere(apiKeyStatuses.cohere);
      setIsBedrock(apiKeyStatuses.bedrock);
      setIsGoogle(apiKeyStatuses.google);
      setIsMistral(apiKeyStatuses.mistral);
    }
  }, [apiKeyStatuses, statusIsLoading]);

  const mutation = useSetOrRemoveProviderApiKey(userToken);
  const bedrockMutation = useSetOrRemoveBedrockKeys(userToken);
  const azureMutation = useSetOrRemoveAzureKeys(userToken);

  const removeApiKey = async (provider: string) => {
    setIsLoading(true);
    if (provider === "bedrock") {
      saveBedrockKeys(null);
    } else if (provider === "openai.azure") {
      saveAzureKeys(null);
    } else {
      saveAPIKeys(null, provider);
    }
    setIsLoading(false);
  };

  const saveBedrockKeys = async (bedrockKeys: BedrockApiKeys | null) => {
    setIsLoading(true);
    try {
      const response = await bedrockMutation.mutateAsync({
        ...bedrockKeys,
        workspace_id: userContext?.activeWorkspaceId!,
      });
      if (response.success) {
        setIsBedrock(bedrockKeys !== null);
        setBedrockApiKey({
          bedrock_aws_access_key: "",
          bedrock_aws_secret_key: "",
          bedrock_aws_region: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const saveAzureKeys = async (azureKeys: AzureApiKeys | null) => {
    setIsLoading(true);
    try {
      const response = await azureMutation.mutateAsync({
        ...azureKeys,
        workspace_id: userContext?.activeWorkspaceId!,
      });
      if (response.success) {
        setIsAzure(azureKeys !== null);
        setAzureApiKey({
          azure_api_key: "",
          azure_endpoint: "",
          azure_api_version: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const saveAPIKeys = async (apiKey: string | null, provider: string) => {
    setIsLoading(true);
    try {
      const response = await mutation.mutateAsync({
        api_key: apiKey,
        provider: provider,
        workspace_id: userContext?.activeWorkspaceId!,
      });
      if (response.success) {
        if (provider === "openai") {
          setIsOpenAiKeySet(apiKey !== null);
          setOpenaiApiKey("");
        } else if (provider === "anthropic") {
          setIsAnthropicKeySet(apiKey !== null);
          setAnthropicApiKey("");
        } else if (provider === "cohere") {
          setIsCohere(apiKey !== null);
          setCohereApiKey("");
        } else if (provider === "google") {
          setIsGoogle(apiKey !== null);
          setGoogleApiKey("");
        } else if (provider === "mistral") {
          setIsMistral(apiKey !== null);
          setMistralApiKey("");
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  if (statusIsLoading) {
    return (
      <div className="py-8 text-center">
        <LoadingSpinner size={10} />
      </div>
    );
  }

  const already_set_message =
    "You have already set your organization's API key.";

  return (
    <>
      <div className="mb-4 border-b pb-2 text-2xl font-semibold">API Keys</div>
      <div className="mb-4 max-w-lg text-sm text-gray-600">
        Configure custom API keys for your organization. These will be stored
        securely and used only when you trigger a request.
      </div>

      <div className="flex flex-col gap-2 py-3">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            OpenAI API Key
          </h3>
          {isOpenAiKeySet ? (
            <div className="flex items-center pt-1">
              <span className="text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("openai")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="openai_api_key"
                id="openai_api_key"
                placeholder="sk-xxxxxxxxxxx"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={openaiApiKey}
                onChange={(e) => setOpenaiApiKey(e.target.value)}
              />
              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    openaiApiKey
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAPIKeys(openaiApiKey, "openai")}
                  disabled={!openaiApiKey || isLoading}
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{isOpenAiKeySet ? "Update" : "Save"} OpenAI Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Anthropic API Key
          </h3>
          {isAnthropicKeySet ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("anthropic")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="anthropic_api_key"
                id="anthropic_api_key"
                placeholder="sk-xxxxxxxxxxx"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={anthropicApiKey}
                onChange={(e) => setAnthropicApiKey(e.target.value)}
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    anthropicApiKey
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAPIKeys(anthropicApiKey, "anthropic")}
                  disabled={!anthropicApiKey || isLoading}
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{anthropicApiKey ? "Update" : "Save"} Anthropic Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Cohere API Key
          </h3>
          {isCohere ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("cohere")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="cohere_api_key"
                id="cohere_api_key"
                placeholder="sk-xxxxxxxxxxx"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={cohereApiKey}
                onChange={(e) => setCohereApiKey(e.target.value)}
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    cohereApiKey
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAPIKeys(cohereApiKey, "cohere")}
                  disabled={!cohereApiKey || isLoading}
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{cohereApiKey ? "Update" : "Save"} Cohere Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Mistral API Key
          </h3>
          {isMistral ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("mistral")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="mistral_api_key"
                id="mistral_api_key"
                placeholder="xxxxxxxxxxx"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={mistralApiKey}
                onChange={(e) => setMistralApiKey(e.target.value)}
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    mistralApiKey
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAPIKeys(mistralApiKey, "mistral")}
                  disabled={!mistralApiKey || isLoading}
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{mistralApiKey ? "Update" : "Save"} Mistral Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Google API Key
          </h3>
          {isGoogle ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("google")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="google_api_key"
                id="google_api_key"
                placeholder="sk-xxxxxxxxxxx"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={googleApiKey}
                onChange={(e) => setGoogleApiKey(e.target.value)}
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    googleApiKey
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAPIKeys(googleApiKey, "google")}
                  disabled={!googleApiKey || isLoading}
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{googleApiKey ? "Update" : "Save"} Google Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Bedrock API Key
          </h3>
          {isBedrock ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("bedrock")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="bedrock_aws_access_key"
                id="bedrock_aws_access_key"
                placeholder="Enter Bedrock AWS Access Key..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={bedrockApiKey.bedrock_aws_access_key}
                onChange={(e) =>
                  setBedrockApiKey({
                    ...bedrockApiKey,
                    bedrock_aws_access_key: e.target.value,
                  })
                }
              />
              <input
                type="text"
                name="bedrock_aws_secret_key"
                id="bedrock_aws_secret_key"
                placeholder="Enter Bedrock AWS Secret Key..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={bedrockApiKey.bedrock_aws_secret_key}
                onChange={(e) =>
                  setBedrockApiKey({
                    ...bedrockApiKey,
                    bedrock_aws_secret_key: e.target.value,
                  })
                }
              />
              <input
                type="text"
                name="bedrock_aws_region"
                id="bedrock_aws_region"
                placeholder="Enter Bedrock AWS Region..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={bedrockApiKey.bedrock_aws_region}
                onChange={(e) =>
                  setBedrockApiKey({
                    ...bedrockApiKey,
                    bedrock_aws_region: e.target.value,
                  })
                }
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    bedrockApiKey.bedrock_aws_access_key &&
                    bedrockApiKey.bedrock_aws_secret_key &&
                    bedrockApiKey.bedrock_aws_region
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveBedrockKeys(bedrockApiKey)}
                  disabled={
                    !bedrockApiKey.bedrock_aws_access_key ||
                    !bedrockApiKey.bedrock_aws_secret_key ||
                    !bedrockApiKey.bedrock_aws_region ||
                    isLoading
                  }
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{isBedrock ? "Update" : "Save"} Bedrock Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Azure OpenAI Configuration
          </h3>
          {isAzure ? (
            <div className="flex items-center pt-1">
              <span className="text-light text-sm italic text-gray-600">
                {already_set_message}
              </span>
              <span
                className="cursor-pointer rounded-md py-1 pl-2 text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none"
                onClick={() => removeApiKey("openai.azure")}
              >
                Click here to remove.
              </span>
            </div>
          ) : (
            <div className="mt-1">
              <input
                type="text"
                name="azure_api_key"
                id="azure_api_key"
                placeholder="Enter Azure API Key..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={azureApiKey.azure_api_key}
                onChange={(e) =>
                  setAzureApiKey({
                    ...azureApiKey,
                    azure_api_key: e.target.value,
                  })
                }
              />
              <input
                type="text"
                name="azure_endpoint"
                id="azure_endpoint"
                placeholder="Enter Azure Endpoint..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={azureApiKey.azure_endpoint}
                onChange={(e) =>
                  setAzureApiKey({
                    ...azureApiKey,
                    azure_endpoint: e.target.value,
                  })
                }
              />
              <input
                type="text"
                name="azure_api_version"
                id="azure_api_version"
                placeholder="Enter Azure API Version..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={azureApiKey.azure_api_version}
                onChange={(e) =>
                  setAzureApiKey({
                    ...azureApiKey,
                    azure_api_version: e.target.value,
                  })
                }
              />

              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${
                    azureApiKey.azure_api_key &&
                    azureApiKey.azure_endpoint &&
                    azureApiKey.azure_api_version
                      ? "bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      : "cursor-not-allowed bg-gray-400 opacity-80"
                  }`}
                  onClick={() => saveAzureKeys(azureApiKey)}
                  disabled={
                    !azureApiKey.azure_api_key ||
                    !azureApiKey.azure_endpoint ||
                    !azureApiKey.azure_api_version ||
                    isLoading
                  }
                >
                  {isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <>{isAzure ? "Update" : "Save"} Azure Key</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ConfigureAPIKeysModal;
