import { Dialog, Transition } from "@headlessui/react";
import {
  AdjustmentsIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CogIcon,
  DocumentReportIcon,
  RefreshIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  Fragment,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import logoCakeColor from "@/assets/logo/promptlayer-cake-color.svg";
import logoCake from "@/assets/logo/promptlayer-cake.svg";
import logoLong from "@/assets/logo/promptlayer-long.png";
import { ContentNavbar } from "@/components/ContentNavbar";
import LoadingSpinner from "@/components/LoadingSpinner";
import NewFeatureModal from "@/components/NewFeatureModal";
import SearchBar from "@/components/SearchBar";
import { SidebarRequestLogCard } from "@/components/SidebarRequestLogCard";
import SubscriptionStatusBanner from "@/components/SubscriptionStatusBanner";
import { WorkspaceSelector } from "@/components/WorkspaceSelector";
import { NAVIGATION } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useIntersectionObserver } from "@/hooks";
import { Request } from "@/types/requests";
import { SpanNode } from "@/types/spans";
import { responseIsFunctionOrTool } from "@/utils/logUtils";
import { classNames } from "@/utils/strings";
import { keepRelevantSearchParams } from "@/utils/utils";
import FineTuneModal from "../FineTuneModal/FineTuneModal";
import { useSwitch } from "../ui/switch";
import Content from "./Content";
import NoResultsFooter from "./NoResultsFooter";
import SpanCard from "./SpanCard";

export default function SidebarSearchLayout({
  children,
}: {
  children: ReactNode;
}) {
  const userContext = useUser();
  const { traceId } = useParams();
  const authContext = useAuth();
  const subscriptionStatus = userContext.subscriptionStatus;
  const { SwitchComponent: Switch, isSwitchOn: requestsOn } = useSwitch(
    ["Requests", "Traces"],
    !!traceId,
  );

  const requests = useMemo(
    () => (requestsOn ? userContext.requests : userContext.spans),
    [requestsOn, userContext.requests, userContext.spans],
  );

  const noRequestsFound = !(requests.length > 0);
  const fetchNextPage = requestsOn
    ? userContext.fetchNextRequestsPage
    : userContext.fetchNextTracesPage;
  const hasNextPage = requestsOn
    ? userContext.hasNextRequestsPage
    : userContext.hasNextTracesPage;
  const isFetchingNewRequests = requestsOn
    ? userContext.isFetchingRequests
    : userContext.isFetchingTraces;
  const isFetching = requestsOn
    ? userContext.isFetchingRequests && !hasNextPage
    : userContext.isFetchingTraces && !hasNextPage;
  const manuallyRefetch = requestsOn
    ? userContext.manuallyRefetchRequests
    : userContext.manuallyRefetchTraces;
  const [searchParams] = useSearchParams();
  const ref = useRef<HTMLDivElement | null>(null);
  const sidebarLogContainerRef = useRef<HTMLDivElement | null>(null);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const onRequestPage = window.location.pathname.includes("/request");
  const onGroupPage = window.location.pathname.includes("/group");
  const onHistoryPage =
    (onRequestPage || onGroupPage) &&
    !window.location.pathname.includes("playground");
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  const location = useLocation();

  const isCurrentPageName = (navigationItem: {
    name: string;
    href: string;
    altHrefs?: string[];
  }) => {
    // Get the current page from the URL
    const path = window.location.pathname;
    return (
      path.includes(`/${navigationItem.href}`) ||
      (navigationItem.altHrefs &&
        navigationItem.altHrefs.some((alt) => path.includes(`/${alt}`)))
    );
  };

  useEffect(() => {
    if (isVisible) {
      fetchNextPage();
    }
  }, [isVisible, fetchNextPage]);

  // Get ID from URL requestId, if exists
  const { requestId, groupId } = useParams();
  const currentType = onGroupPage ? "group" : "request";

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const getNextId = useCallback(
    (id?: string, type?: string) => {
      const index = requests.findIndex(
        (item: { id: string; type?: string; trace_id?: string }) =>
          (type === "trace" ? item.trace_id : String(item.id)) === id &&
          (type === "trace" || item.type === type),
      );
      if (index < requests.length - 1) {
        const nextItem = requests[index + 1];
        return {
          id: type === "trace" ? nextItem.trace_id : nextItem.id,
          type: type === "trace" ? "trace" : nextItem.type,
        };
      }
      return null;
    },
    [requests],
  );

  const getPreviousId = useCallback(
    (id?: string, type?: string) => {
      const index = requests.findIndex(
        (item: { id: string; type?: string; trace_id?: string }) =>
          (type === "trace" ? item.trace_id : String(item.id)) === id &&
          (type === "trace" || item.type === type),
      );
      if (index > 0) {
        const prevItem = requests[index - 1];
        return {
          id: type === "trace" ? prevItem.trace_id : prevItem.id,
          type: type === "trace" ? "trace" : prevItem.type,
        };
      }
      return null;
    },
    [requests],
  );
  const scrollToLogBox = useCallback(
    (id: string) => {
      if (sidebarLogContainerRef.current) {
        const container = sidebarLogContainerRef.current;
        const element = container.querySelector(
          `[data-request-id="${id}"]`,
        ) as HTMLElement;

        if (element) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top;

          // Calculate the desired scroll position
          let scrollTo = container.scrollTop + offsetTop;

          // If the element is at the top, scroll to show the full element
          if (offsetTop <= 0) {
            scrollTo = container.scrollTop + offsetTop - 16; // Add some padding
          }

          // If we're near the bottom, adjust scroll to show as much as possible
          const containerHeight = container.clientHeight;
          const elementHeight = element.clientHeight;
          if (offsetTop + elementHeight > containerHeight) {
            scrollTo =
              container.scrollTop +
              offsetTop -
              containerHeight +
              elementHeight +
              16; // Add some padding
          }

          // Perform the scroll
          container.scrollTo({
            top: scrollTo,
            behavior: "smooth",
          });
        }
      }
    },
    [sidebarLogContainerRef],
  );

  const keepRelevantParams = useCallback(() => {
    return keepRelevantSearchParams(searchParams);
  }, [searchParams]);

  // cmd+k, j, k, arrows
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (location.pathname.includes("playground")) return;
      // Ignore keypresses in input fields
      if (
        window.location.pathname.includes("/request") ||
        window.location.pathname.includes("/home") ||
        window.location.pathname.includes("/group") ||
        window.location.pathname.includes("/traces")
      ) {
        if ((event.metaKey || event.ctrlKey) && event.key === "k") {
          inputRef.current?.focus();
        }
        if (event.key === "j" || event.key === "ArrowDown") {
          const currentId = requestsOn
            ? groupId || requestId || ""
            : traceId || "";
          const currentType = requestsOn
            ? onGroupPage
              ? "group"
              : "request"
            : "trace";
          const index = getNextId(currentId, currentType);
          if (index) {
            const path = requestsOn
              ? `/workspace/${userContext?.activeWorkspaceId}/${index.type}/${index.id}`
              : `/workspace/${userContext?.activeWorkspaceId}/traces/${index.id}`;
            navigate({
              pathname: path,
              search: keepRelevantParams(),
            });
            scrollToLogBox(index.id);
          }
        }
        if (event.key === "k" || event.key === "ArrowUp") {
          const currentId = requestsOn
            ? groupId || requestId || ""
            : traceId || "";
          const currentType = requestsOn
            ? onGroupPage
              ? "group"
              : "request"
            : "trace";
          const index = getPreviousId(currentId, currentType);
          if (index) {
            const path = requestsOn
              ? `/workspace/${userContext?.activeWorkspaceId}/${index.type}/${index.id}`
              : `/workspace/${userContext?.activeWorkspaceId}/traces/${index.id}`;
            navigate({
              pathname: path,
              search: keepRelevantParams(),
            });
            scrollToLogBox(index.id);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [
    requestId,
    groupId,
    traceId,
    navigate,
    requests,
    getNextId,
    getPreviousId,
    searchParams,
    scrollToLogBox,
    keepRelevantParams,
    onGroupPage,
    currentType,
    authContext,
    userContext,
    requestsOn,
    location,
  ]);

  const user = useUser();
  const SIDEBAR_MAX = 500;

  const settings = (
    <Link
      className={`flex ${
        (isCurrentPageName({
          name: "settings",
          href: "settings",
        }) &&
          "bg-gray-200") ||
        "hover:bg-gray-50"
      } box-border h-6 items-center justify-center rounded-full p-4`}
      to={`/workspace/${userContext?.activeWorkspaceId}/settings`}
    >
      <CogIcon className="h-6 w-auto cursor-pointer rounded-full hover:text-gray-700" />
    </Link>
  );

  const renderDesktopHeader = () => {
    return (
      <div className="bg-blue fixed hidden w-full sm:block">
        <div className="flex items-center">
          <div
            className={`flex flex-shrink-0 items-center px-3 text-gray-700 transition-all duration-100 ease-in-out ${
              sidebarCollapsed ? "w-20" : "w-80"
            }`}
          >
            {sidebarCollapsed ? (
              <img
                src={logoCake}
                alt="PromptLayer Logo"
                className="h-10 w-10 cursor-pointer transition duration-300 ease-in-out hover:animate-spin hover:text-gray-600"
                onClick={() => navigate("/")}
                onMouseOver={(e) => (e.currentTarget.src = logoCakeColor)}
                onMouseOut={(e) => (e.currentTarget.src = logoCake)}
              />
            ) : (
              <div className="flex-1 text-xl font-bold tracking-wide">
                <span
                  className="cursor-pointer hover:text-gray-600"
                  onClick={() => navigate("/")}
                >
                  <img
                    src={logoLong}
                    alt="PromptLayer Logo"
                    width={180}
                    className="transition duration-100 ease-in-out"
                  />
                </span>
              </div>
            )}
            <button
              className="mr-1 rounded-md p-2 hover:bg-gray-200 focus:outline-none"
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
            >
              {sidebarCollapsed ? (
                <ChevronRightIcon className="h-5 w-5" />
              ) : (
                <ChevronLeftIcon className="h-5 w-5" />
              )}
            </button>
            {!sidebarCollapsed && (
              <RefreshIcon
                className={`meticulous-ignore h-4 w-4 ${
                  isFetchingNewRequests
                    ? "animate-spin text-blue-400"
                    : "cursor-pointer text-gray-400"
                }`}
                onClick={() =>
                  isFetchingNewRequests ? null : manuallyRefetch()
                }
              />
            )}
          </div>
          <nav
            className="flex w-full items-center px-8 lg:py-4"
            aria-label="Global"
          >
            <div className="flex flex-1 space-x-4 2xl:space-x-8">
              <WorkspaceSelector />
              {NAVIGATION.map((item) => {
                const index = getNextId();
                let link = `/workspace/${userContext!.activeWorkspace?.id}/${
                  item.href
                }/`;
                if (item.href === "request") {
                  if (index) {
                    link = `/workspace/${userContext!.activeWorkspace
                      ?.id}/${index?.type}/${String(index?.id)}/`;
                  } else {
                    link = window.location.pathname;
                  }
                }
                return (
                  <Link key={item.name} to={`${link}?${keepRelevantParams()}`}>
                    <button
                      className={classNames(
                        isCurrentPageName(item)
                          ? "bg-gray-200 text-black hover:text-gray-900"
                          : "font-normal text-gray-800 hover:bg-gray-50 hover:text-gray-900",
                        "inline-flex cursor-pointer items-center rounded-md px-3 py-2 text-sm",
                      )}
                    >
                      <item.icon
                        className="mr-2 h-5 w-auto text-gray-700"
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  </Link>
                );
              })}
            </div>
            {/* Settings dropdown */}
            {settings}
          </nav>
        </div>
      </div>
    );
  };

  const renderFineTuneDatasets = (mobile = false) => {
    return (
      <div
        className={`${
          mobile && "flex-col"
        }  my-2 flex justify-center gap-x-6  text-sm text-gray-600`}
      >
        <FineTuneModal>
          <div className="flex cursor-pointer items-center rounded-md px-4 py-2 hover:bg-gray-200 hover:text-gray-800">
            <AdjustmentsIcon className="mr-2 h-4 w-4" />
            Fine-Tune
          </div>
        </FineTuneModal>
        <div
          onClick={() => {
            navigate({
              pathname: `/workspace/${user?.activeWorkspaceId}/datasets`,
              search: `${keepRelevantSearchParams(searchParams)}`,
            });
          }}
          className={`flex cursor-pointer items-center rounded-md px-4 py-2 hover:bg-gray-200 hover:text-gray-800 ${
            window.location.pathname.includes(
              `/workspace/${user?.activeWorkspaceId}/dataset`,
            )
              ? "bg-gray-200 text-gray-800"
              : ""
          }`}
        >
          <DocumentReportIcon className="mr-2 h-4 w-4" />
          Datasets
        </div>
      </div>
    );
  };

  const renderDesktopSidebar = () => {
    return (
      <div
        className={`hidden transition-all duration-100 ease-in-out md:fixed md:inset-y-0 md:flex md:flex-col ${
          sidebarCollapsed ? "w-20" : "w-80"
        }`}
      >
        <div className={` flex flex-grow flex-col overflow-y-auto pt-10`}>
          {!sidebarCollapsed
            ? renderSidebarExpanded()
            : renderSidebarCollapsed()}
        </div>
      </div>
    );
  };

  const renderCards = () =>
    (requests || [])
      .slice(0, SIDEBAR_MAX)
      .map((item: Request & SpanNode, idx: number) =>
        requestsOn ? (
          <SidebarRequestLogCard
            key={idx}
            active={
              !!(requestId && String(item.id) === requestId) ||
              !!(groupId && String(item.id) === groupId)
            }
            logId={item.id}
            descriptor={item.engine || item.function_name}
            startTime={item.request_start_time}
            tags={item.tags}
            starred={item.is_starred}
            prompt={item.prompt_string}
            responseIsFunction={responseIsFunctionOrTool(item)}
            response={item.response_string}
            type={item.type}
            cardinality={item.cardinality}
            promptId={item.prompt_id ?? undefined}
          />
        ) : (
          <SpanCard key={idx} span={item as unknown as SpanNode} />
        ),
      );

  const renderMobileSidebar = () => {
    return (
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <div className="flex-1 text-xl font-bold tracking-wide">
                    <span
                      className="cursor-pointer hover:text-gray-600"
                      onClick={() => navigate("/")}
                    >
                      <img src={logoLong} alt="PromptLayer Logo" width={180} />
                    </span>
                  </div>
                  {/* Settings dropdown */}
                  {settings}
                </div>
                <div className="bg-blue flex w-full py-3">
                  <nav
                    className="mx-auto w-full items-center px-1 text-center"
                    aria-label="Global"
                  >
                    <div className="flex flex-col space-x-1 ">
                      {/* Mobile version */}
                      {NAVIGATION.map((item) => (
                        <Link
                          key={item.name}
                          to={
                            (item.href !== "request"
                              ? `/workspace/${userContext!.activeWorkspace
                                  ?.id}/${item.href}`
                              : `/workspace/${userContext!.activeWorkspace
                                  ?.id}/request/${getNextId()}`) +
                            `/?${keepRelevantParams()}`
                          }
                        >
                          <button
                            className={classNames(
                              isCurrentPageName(item)
                                ? "bg-gray-200 font-medium text-gray-900 hover:text-gray-900"
                                : "font-normal text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                              "inline-flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-xs",
                            )}
                          >
                            {item.name}
                          </button>
                        </Link>
                      ))}
                      <hr className="mt-2" />
                      {renderFineTuneDatasets(true)}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderSidebarCollapsed = () => {
    return (
      <div
        className={`mt-5 h-full flex-col overflow-y-hidden ${
          sidebarCollapsed ? "w-20" : "hidden"
        }`}
      >
        <SearchBar
          inputRef={inputRef}
          collapsed={true}
          requestsOn={requestsOn}
        />
      </div>
    );
  };

  const renderSidebarExpanded = () => {
    return (
      <div className="mt-3 flex flex-col overflow-y-hidden ">
        {renderFineTuneDatasets()}
        <div
          className={`flex flex-grow flex-col overflow-y-hidden  ${
            sidebarCollapsed ? "hidden" : ""
          }`}
        >
          <SearchBar inputRef={inputRef} requestsOn={requestsOn} />
          {Switch}
          <nav
            className="flex flex-1 flex-col space-y-1 overflow-y-auto px-1 pb-4"
            ref={sidebarLogContainerRef}
          >
            {renderCards()}
            {hasNextPage && (
              <div ref={ref} className="flex justify-center py-2">
                <LoadingSpinner size={5} />
              </div>
            )}
            {isFetchingNewRequests && isFetching && (
              <div className="flex justify-center py-2">
                <LoadingSpinner size={5} />
              </div>
            )}
            {noRequestsFound && <NoResultsFooter requestsOn={requestsOn} />}
          </nav>
        </div>
      </div>
    );
  };

  return (
    <div className={`h-full bg-gray-100`}>
      <NewFeatureModal />
      <SubscriptionStatusBanner
        subscriptionStatus={subscriptionStatus.data}
        subscriptionStatusisLoading={subscriptionStatus.isLoading}
        showPricingModal={showPricingModal}
        setShowPricingModal={setShowPricingModal}
      />
      <div className="h-full">
        {renderMobileSidebar()}
        {renderDesktopSidebar()}
        {renderDesktopHeader()}
        <div
          className={`h-full transition-all duration-100 ease-in-out ${
            sidebarCollapsed ? "md:pl-20" : "md:pl-80"
          } md:pt-16`}
        >
          <div className="flex h-full w-full flex-grow flex-col rounded-tl-lg border-l border-t border-gray-200 bg-white md:px-8">
            <ContentNavbar
              setSidebarOpen={setSidebarOpen}
              onHistoryPage={onHistoryPage}
              isGroup={!!groupId}
            />

            <main className="w-full flex-1 overflow-y-hidden">
              <div className="h-full overflow-y-auto md:mx-4">
                <Content>
                  <Suspense
                    fallback={
                      <div className="flex h-full items-center justify-center">
                        <LoadingSpinner />
                      </div>
                    }
                  >
                    {children}
                  </Suspense>
                </Content>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
