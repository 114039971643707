import {
  ItemDescription,
  ItemProperties,
  ParameterType,
  Properties,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";

// Helper function to determine if a parameter is an array
const isArrayType = (param: Properties): boolean => param?.type === "array";

// Helper function to determine if a parameter is an object
const isObjectType = (param: Properties): boolean => param?.type === "object";

// Helper function to determine if a parameter has enums
const hasEnum = (param: Properties): boolean => Array.isArray(param?.enum);

// Helper function to extract common fields from a parameter
const extractCommonFields = (
  param: Properties,
  key: string,
  isRequired: boolean,
) => ({
  name: key,
  type: param?.type,
  description: param?.description,
  title: param?.title,
  required: param?.required,
  isRequired: Array.isArray(param?.required)
    ? param?.required?.includes(key)
    : param?.isRequired || isRequired,
  isEnum: hasEnum(param),
  enum: hasEnum(param) ? param?.enum : undefined,
});

// Helper function to parse properties for top-level objects or arrays
const parseTopLevelProperties = (
  properties: any,
  requiredParams: string[] = [],
) => {
  return Object.keys(properties).map((key) => {
    const param = properties[key];
    const required = requiredParams.includes(key);
    if (isObjectType(param)) {
      return {
        ...extractCommonFields(param, key, required),
        properties: param?.properties || undefined,
      };
    } else if (isArrayType(param)) {
      return {
        ...extractCommonFields(param, key, required),
        items: param?.items || undefined,
      };
    } else {
      return extractCommonFields(param, key, required);
    }
  });
};

// Helper function to parse nested object or array items
const parseNestedItems = (params: any, isObject: boolean) => {
  return Object.keys(params).map((key) => {
    const param = params[key];
    const required = Array.isArray(params.required)
      ? params.required.includes(key)
      : false;
    if (isObject) {
      return {
        ...extractCommonFields(param, key, required),
        properties: param?.properties || undefined,
      };
    } else {
      return {
        ...extractCommonFields(param, key, required),
        items: isArrayType(param) ? param?.items : undefined,
        properties: isArrayType(param) ? undefined : param?.properties,
      };
    }
  });
};

// Main function to parse parameters
export const parseParams = (
  unparsedParams: any,
  isItemDescription: boolean | undefined,
  type?: string,
) => {
  if (!unparsedParams || typeof unparsedParams !== "object") {
    return [];
  }

  const hasProperties = !!unparsedParams?.properties;
  const hasValidKeys =
    Object.keys(unparsedParams.properties || {}).length > 0 ||
    Object.keys(unparsedParams).length > 0;

  if (!hasValidKeys) {
    return [];
  }

  // Handle top-level parsing
  if (hasProperties) {
    return parseTopLevelProperties(
      unparsedParams.properties,
      unparsedParams.required,
    );
  }

  // Handle nested parsing based on item description or composition
  if (!isItemDescription) {
    const isObject = type === "object";
    return parseNestedItems(unparsedParams, isObject);
  }

  // Handle item descriptions for arrays
  if (isItemDescription) {
    return [extractCommonFields(unparsedParams, "", false)];
  }

  return Object.keys(unparsedParams).map((key) => {
    const param = unparsedParams[key];
    const required = Array.isArray(unparsedParams.required)
      ? unparsedParams.required.includes(key)
      : false;

    return extractCommonFields(param, key, required);
  });
};

// Helper function to create a parameter structure
const createParameterStructure = (parameter: any) => {
  const baseStructure = {
    ...parameter,
    type: parameter.type,
    title: parameter.title,
    description: parameter.description,
    ...(parameter?.enum && { enum: parameter?.enum }),
    ...(parameter?.isRequired && { isRequired: parameter?.isRequired }),
    ...(parameter?.required && { required: parameter?.required }),
  };

  if (isArrayType(parameter)) {
    return {
      ...baseStructure,
      items: parameter.items || {},
    };
  }

  if (isObjectType(parameter)) {
    return {
      ...baseStructure,
      properties: parameter.properties || {},
    };
  }

  return baseStructure;
};

export const handleJsonToFormParamChange = async (
  parameters: Properties[],
  onChange: (params: any) => void,
  isItemDescription?: boolean,
  outerType?: string,
) => {
  const transformParameters = async (
    params: Properties[],
  ): Promise<{ properties: any; required: string[] }> => {
    const properties: any = {};
    const requiredFields: string[] = [];

    await Promise.all(
      params.map(async (parameter) => {
        if (isItemDescription) {
          Object.assign(properties, createParameterStructure(parameter));
        } else if (parameter?.type) {
          properties[parameter.name] = createParameterStructure(parameter);
        }

        if (parameter.isRequired) {
          requiredFields.push(parameter.name);
        }

        if (parameter.type === ParameterType.Object && parameter.properties) {
          const nestedParams = Object.entries(parameter.properties).map(
            ([key, value]) => {
              if (typeof value === "object" && value !== null) {
                return {
                  ...value,
                  name: key,
                } as Properties;
              }
              // Handle the case where value is not an object
              return {
                name: key,
                type: ParameterType.String, // Default to string, adjust as needed
                description: "",
                title: key,
              } as Properties;
            },
          );

          const nestedResult = await transformParameters(nestedParams);

          properties[parameter.name].properties = nestedResult.properties;
          if (nestedResult.required.length > 0) {
            properties[parameter.name].required = nestedResult.required;
          }
        }
      }),
    );

    return { properties, required: requiredFields };
  };

  const result = await transformParameters(parameters);
  if (isItemDescription) {
    onChange(result.properties);
  } else if (outerType === ParameterType.Array) {
    onChange({
      ...result,
      type: ParameterType.Object,
      additionalProperties: false,
    });
  } else if (outerType !== ParameterType.Object) {
    onChange({
      ...result,
      type: ParameterType.Object,
      additionalProperties: false,
    });
  } else {
    onChange(result.properties);
  }
};

export const getItemDescriptionState = (
  initialValue: Properties,
  depth: number,
) => {
  // This function allows us to calculate if a parameter is an item description or an item composition

  // A description state is needed to decide if a parameter is an item description or an item composition
  // It might be none of those, in which case the parameter is a regular parameter
  // If the depth is 0, then the parameter is a regular parameter
  // if the depth is 1, then the parameter can be a regular, a description, or a composition

  if (depth === 0) {
    return undefined;
  }

  if (
    typeof initialValue === "object" &&
    "type" in initialValue &&
    initialValue.type === "object"
  ) {
    // its an item composition
    return false;
  }
  if (
    typeof initialValue === "object" &&
    "type" in initialValue &&
    initialValue.type !== "object"
  ) {
    // its an item description
    return true;
  }
};

export const handleParameterChange = <K extends keyof Properties>(
  index: number,
  key: K,
  val: Properties[K] | ItemProperties[K],
  parameters: any[],
  setParameters: (params: any[]) => void,
  isItemDescription?: boolean,
) => {
  const newParameters: any = [...parameters];

  if (key === "type" && val === ParameterType.Array) {
    newParameters[index][key] = val;
    newParameters[index]["items"] = {
      properties: {
        "": {
          type: ParameterType.String,
          description: "",
          title: "",
        },
      },
      type: ParameterType.Object,
    };
  } else if (key === "type" && val === ParameterType.Object) {
    newParameters[index][key] = val;
    newParameters[index]["properties"] = {
      "": {
        type: ParameterType.String,
        description: "",
        title: "",
      },
    };
  } else if (isItemDescription) {
    newParameters[0][key] = val;
    if (newParameters.length > 1) {
      newParameters[1][key] = val;
    }
  } else {
    newParameters[index][key] = val;
  }
  setParameters(newParameters);
};

export const handleItemSwitch = (
  description: boolean,
  setParameters: (params: any[]) => void,
  onChange: (params: any) => void,
) => {
  if (description) {
    setParameters([
      {
        type: "string",
        description: "",
      } as ItemDescription,
    ]);
  } else {
    onChange({
      properties: {
        "": {
          name: "",
          type: "string",
          description: "",
          title: "",
        },
      },
      type: "object",
    });
  }
};

export const handleRemoveRow = (
  index: number,
  parameters: any[],
  setParameters: (params: any[]) => void,
) => {
  const newParameters = [...parameters];
  newParameters.splice(index, 1);
  setParameters(newParameters);
};
