import { PromptParameters } from "@/components";
import ChatTemplate from "@/components/ChatTemplate";
import { FunctionDialog } from "@/components/FunctionsModal";
import { MetadataDialog } from "@/components/metadata-dialog";
import ThreadsOverlayManager from "@/components/ThreadsOverlayManager";
import {
  FunctionsType,
  ChatTemplate as TChatTemplate,
  Template,
  TemplateFormat,
} from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { HighlightSnippets } from "@/utils/HighlightSnippets";
import {
  getFunctionCall,
  getFunctions,
  getStringContent,
  getSystemMessage,
} from "@/utils/utils";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

type PromptTemplateVersionContentProps = {
  initialProviderBaseURLName: string | null;
  chatPromptTemplate?: TChatTemplate | null;
  input_variables?: string[];
  isChat?: boolean;
  model?: any;
  templateFormat?: TemplateFormat;
  text: string | null;
  version_metadata?: { [key: string]: any };
  invalid_snippets?: string[];
  sourcedSnippets?: Array<PromptVersionSnippet>;
  renderedTemplate?: Template;
  isXrayMode: boolean;
  promptRegistryId: number;
  promptVersionNumber: number;
};

export function PromptTemplateVersionContent({
  initialProviderBaseURLName,
  chatPromptTemplate,
  input_variables = [],
  isChat,
  model,
  templateFormat = "f-string",
  text,
  version_metadata = [],
  invalid_snippets = [],
  sourcedSnippets = [],
  renderedTemplate,
  isXrayMode,
  promptRegistryId,
  promptVersionNumber,
}: PromptTemplateVersionContentProps) {
  const [isCopied, setIsCopied] = useState(false);

  const functionsType: FunctionsType = chatPromptTemplate
    ? chatPromptTemplate.tools
      ? "tools"
      : "functions"
    : "functions";
  const functions = chatPromptTemplate ? getFunctions(chatPromptTemplate) : [];
  const functionCall = chatPromptTemplate
    ? getFunctionCall(chatPromptTemplate)
    : undefined;

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const copyButton = !isChat && text && (
    <span className="absolute right-0 top-0 text-gray-600 hover:text-gray-500">
      <CopyToClipboard text={text} onCopy={onCopyText}>
        <span className="cursor-pointer">
          {isCopied ? (
            <CheckIcon className="h-5 w-auto" />
          ) : (
            <DocumentDuplicateIcon className="h-5 w-auto" />
          )}
        </span>
      </CopyToClipboard>
    </span>
  );

  const systemMessage = chatPromptTemplate
    ? getSystemMessage(chatPromptTemplate)
    : null;
  const template = systemMessage ? getStringContent(systemMessage) : "";

  const renderedCompletionTemplate =
    renderedTemplate?.type === "completion"
      ? getStringContent(renderedTemplate)
      : "";

  const codeBlock = chatPromptTemplate ? (
    <ChatTemplate
      messageTemplates={chatPromptTemplate.messages}
      template={template}
      templateFormat={templateFormat}
      invalidSnippets={invalid_snippets}
      sourcedSnippets={sourcedSnippets}
      renderedTemplate={renderedTemplate}
      isXrayMode={isXrayMode}
    />
  ) : (
    <pre className="w-full whitespace-pre-wrap font-sans text-gray-900">
      <div className="relative w-full">
        {copyButton}
        <span>
          <HighlightSnippets
            template={text!}
            mode={"view"}
            isXrayMode={isXrayMode}
            renderedTemplate={renderedCompletionTemplate}
            invalidSnippets={invalid_snippets}
            sourcedSnippets={sourcedSnippets}
          />
        </span>
      </div>
    </pre>
  );

  return (
    <div className="flex h-full flex-col justify-between divide-y rounded-md border border-gray-300 bg-white drop-shadow-md">
      <div className="overflow-y-scroll p-3">{codeBlock}</div>
      <div className="w-full">
        <div className="flex flex-row-reverse items-center justify-between gap-x-2 p-3">
          <div className="flex items-center space-x-2">
            <MetadataDialog metadata={version_metadata} />
            <PromptParameters
              initialProviderBaseURLName={initialProviderBaseURLName}
              isChat={isChat}
              model={model}
            />
            {functions.length > 0 ? (
              <FunctionDialog
                functions={functions}
                functionCall={functionCall}
                functionsType={functionsType}
              />
            ) : null}
            <ThreadsOverlayManager
              promptRegistryId={promptRegistryId}
              promptVersionNumber={promptVersionNumber}
            />
          </div>
          {input_variables.length ? (
            <div className="flex flex-1 items-center space-x-2 overflow-hidden">
              <div className="whitespace-nowrap">Input Variables: </div>
              <div className="flex space-x-2 overflow-x-scroll whitespace-nowrap text-gray-500">
                {input_variables.map((variable, index) => (
                  <div
                    className="rounded-full border border-gray-300 px-2 py-1 text-center text-sm"
                    key={index}
                    aria-label={variable}
                    title={variable}
                  >
                    {variable.length > 15
                      ? `${variable.substring(0, 15)}...`
                      : variable}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
