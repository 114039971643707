import { SearchIcon, XIcon } from "@heroicons/react/solid";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { DivButton } from "../../button";
import { Input } from "../../input";

function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

interface ExpandableSearchbarProps {
  globalSearchTerm: string | null;
  setGlobalSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
}

const ExpandableSearchbar = ({
  globalSearchTerm,
  setGlobalSearchTerm,
}: ExpandableSearchbarProps) => {
  const [localSearchTerm, setLocalSearchTerm] = useState<string>(
    globalSearchTerm || "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetGlobalSearchTerm = useCallback(
    debounce((value: string) => {
      setGlobalSearchTerm(value);
      setIsLoading(false);
    }, 300),
    [setGlobalSearchTerm],
  );

  const toggleExpand = useCallback(() => {
    if (isExpanded) {
      setLocalSearchTerm("");
    }
    setIsExpanded((prev) => !prev);
  }, [isExpanded]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearchTerm(value);
    setIsLoading(true);
  }, []);

  useEffect(() => {
    debouncedSetGlobalSearchTerm(localSearchTerm);
  }, [debouncedSetGlobalSearchTerm, localSearchTerm]);

  const clearSearch = useCallback(() => {
    setLocalSearchTerm("");
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [globalSearchTerm, isExpanded]);

  const handleBlur = useCallback(() => {
    if (localSearchTerm === "") {
      setIsExpanded(false);
    }
  }, [localSearchTerm]);

  return (
    <div className="flex">
      <div
        className={`flex min-h-[42px] min-w-[42px] flex-row-reverse items-center justify-center rounded-md border`}
      >
        <div
          className={`overflow-hidden transition-all duration-300 ${
            isExpanded ? "w-64" : "w-0"
          }`}
        >
          <div className="relative">
            <Input
              ref={inputRef}
              onBlur={handleBlur}
              type="text"
              placeholder="Search all columns..."
              onChange={handleSearch}
              value={localSearchTerm || ""}
              className="border-none outline-none focus-visible:ring-0"
            />
            {isLoading ? (
              <div className="absolute right-2 top-1/2 -translate-y-1/2">
                <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600"></div>
              </div>
            ) : (
              localSearchTerm && (
                <DivButton
                  variant="ghost"
                  size="tinyIcon"
                  onClick={clearSearch}
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                >
                  <XIcon className="h-4 w-4" />
                </DivButton>
              )
            )}
          </div>
        </div>
        <div
          onClick={toggleExpand}
          className="flex min-h-[42px] min-w-[42px] cursor-pointer items-center justify-center"
        >
          <div className="relative">
            <SearchIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(ExpandableSearchbar);
