import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { EditorModeToggle } from "@/components/FunctionsModal/FunctionForm/EditorModeToggle";
import { JsonEditor } from "@/components/FunctionsModal/FunctionForm/JsonEditor";
import { useFunctionForm } from "@/components/FunctionsModal/hooks/useFunctionForm";
import InteractiveFunctionParametersEditor from "@/components/FunctionsModal/InteractiveFunctionEditor";
import {
  FunctionFormValues,
  ParametersEditorModeEnum,
} from "@/components/FunctionsModal/Types";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Function_ } from "@/types";
import {
  convertInteractiveToJson,
  parseJsonToInteractive,
} from "@/utils/jsonSchemaEditor";
import { displayErrorToast } from "@/utils/toast";
import { Link } from "lucide-react";
import { useForm, useFormContext } from "react-hook-form";

const defaultFormValues: Function_ = {
  name: "",
  description: "",
  strict: false,
  parameters: {
    type: "object",
    properties: {},
  },
};

export const FunctionForm = () => {
  const { editingFunction: initialValues, setParameterEditorMode } =
    useFunctionDialogContext();
  const initialJson = initialValues ?? defaultFormValues;
  const form = useForm<FunctionFormValues>({
    defaultValues: {
      ...initialJson,
      json: initialJson,
    },
  });

  const { handleSubmit, closeForm, parameterEditorMode } = useFunctionForm();

  const handleFormSubmit = (values: FunctionFormValues) => {
    handleSubmit(values);
    form.reset(defaultFormValues);
  };

  const updateJsonValues = (newMode: ParametersEditorModeEnum) => {
    const formValues = form.getValues();

    if (newMode === ParametersEditorModeEnum.JSON) {
      // Switching from Interactive to JSON Mode
      form.setValue("json", convertInteractiveToJson(formValues) as Function_);
    } else if (newMode === ParametersEditorModeEnum.INTERACTIVE) {
      // Switching from JSON to Interactive Mode
      try {
        const parsedJson: Partial<FunctionFormValues> = parseJsonToInteractive(
          formValues.json,
        );

        (Object.keys(parsedJson) as Array<keyof typeof parsedJson>).forEach(
          (key) => {
            form.setValue(key, parsedJson[key]);
          },
        );
      } catch (error) {
        displayErrorToast("JSON schema invalid!");
        return;
      }
    }
  };

  const isStrictEnabled = form.watch("strict");
  const isAdditionalProperties = form.watch("parameters.additionalProperties");

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleFormSubmit)}
        className="space-y-8"
      >
        {/* JSON/Interactive Switch */}
        <EditorModeToggle
          parameterEditorMode={parameterEditorMode}
          setParameterEditorMode={setParameterEditorMode}
          updateJsonValues={updateJsonValues}
        />
        {parameterEditorMode === ParametersEditorModeEnum.JSON ? (
          // raw JSON Editor
          <JsonEditor form={form} />
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Function Name</FormLabel>
                    <FormControl>
                      <Input autoFocus placeholder="my_function" {...field} />
                    </FormControl>
                    <FormDescription>
                      Name of function, max length 64.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Description"
                        rows={3}
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormDescription>
                      A description of what the function does, used by the model
                      to choose when and how to call the function. (Optional)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-5 flex gap-5">
                <StrictCheckBox />
                <AdditionalPropertiesCheckBox />
              </div>
              <p
                className={`text-sm text-gray-500 ${
                  isStrictEnabled && isAdditionalProperties
                    ? "visible"
                    : "invisible"
                }`}
              >
                Structured Outputs only supports `additionalProperties: false`.
                <a
                  href="https://platform.openai.com/docs/guides/structured-outputs/additionalproperties-false-must-always-be-set-in-objects"
                  target="_blank"
                  rel="noreferrer"
                  className="pl-1 text-sm text-gray-500 hover:text-gray-400"
                >
                  Learn more <Link className="inline h-4 w-4 pl-1" />
                </a>
              </p>
            </div>
            <FormField
              control={form.control}
              name="parameters"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Parameters</FormLabel>
                  <FormControl>
                    <div>
                      <InteractiveFunctionParametersEditor
                        onChange={field.onChange}
                        initialValue={field.value}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <div className="flex space-x-4">
          <Button type="submit">Add function</Button>
          <Button variant="outline" onClick={closeForm}>
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

const StrictCheckBox = () => {
  const form = useFormContext<FunctionFormValues>();
  return (
    <FormField
      control={form.control}
      name="strict"
      render={({ field }) => (
        <FormItem className="flex items-center gap-3 space-y-0">
          <FormControl>
            <input
              type="checkbox"
              className="rounded text-blue-600 focus:ring-blue-500"
              name={field.name}
              checked={!!field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          </FormControl>
          <FormLabel>Enable Strict Structured Output</FormLabel>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const AdditionalPropertiesCheckBox = () => {
  const form = useFormContext<FunctionFormValues>();
  return (
    <FormField
      control={form.control}
      name="parameters.additionalProperties"
      render={({ field }) => (
        <FormItem className="flex items-center gap-3 space-y-0">
          <FormControl>
            <input
              type="checkbox"
              className="rounded text-blue-600 focus:ring-blue-500"
              name={field.name}
              checked={!!field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          </FormControl>
          <FormLabel>Allow Additional Properties</FormLabel>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
