import { Message, Template, TemplateFormat } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import MessagePromptTemplate from "./MessagePromptTemplate";

type ChatTemplateProps = {
  template: string;
  template_id?: string;
  setTemplate?: (template: string) => void;
  messageTemplates: Message[];
  setMessageTemplates?: (messageTemplates: Message[]) => void;
  templateFormat: TemplateFormat;
  invalidSnippets?: string[];
  invalidPromptTemplate?: boolean;
  invalidPromptIndex?: number | null;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  renderedTemplate?: Template;
  isXrayMode?: boolean;
};

const ChatTemplate = (props: ChatTemplateProps) => {
  const isXrayMode = props.isXrayMode ?? false;
  const renderedTemplate = props.renderedTemplate;

  const renderedMessageTemplates =
    renderedTemplate?.type === "chat"
      ? renderedTemplate?.messages?.slice(1)
      : [];

  const renderedFirstMessage =
    renderedTemplate?.type === "chat" ? renderedTemplate?.messages[0] : null;

  if (!renderedFirstMessage) return null;
  return (
    <div className="grid h-full grid-cols-1 overflow-hidden sm:text-sm md:grid-cols-2">
      <div className="h-full p-2">
        <div
          className={`flex h-full flex-col gap-2 border p-2 ${
            props.invalidPromptTemplate && props.invalidPromptIndex === 0
              ? "border-red-400"
              : ""
          }`}
        >
          <div className="grid h-[370px] flex-shrink-0 grid-rows-[auto] overflow-auto whitespace-pre-wrap text-sm">
            <div className="max-h-full overflow-y-auto">
              <MessagePromptTemplate
                key={0}
                isFirstMessage={true}
                messageTemplate={props.messageTemplates[0]}
                templateFormat={props.templateFormat}
                invalidSnippets={props.invalidSnippets}
                highlightInvalid={
                  props.invalidPromptIndex === 1 && props.invalidPromptTemplate
                }
                sourcedSnippets={props.sourcedSnippets}
                renderedMessageTemplate={renderedFirstMessage}
                isXrayMode={isXrayMode}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full flex-col overflow-auto p-2">
        <div className="flex h-full flex-col overflow-auto">
          {props.messageTemplates
            ?.slice(1)
            ?.map((messageTemplate, i) => (
              <MessagePromptTemplate
                key={i}
                messageTemplate={messageTemplate}
                templateFormat={props.templateFormat}
                invalidSnippets={props.invalidSnippets}
                highlightInvalid={
                  props.invalidPromptIndex === i + 1 &&
                  props.invalidPromptTemplate
                }
                sourcedSnippets={props.sourcedSnippets}
                renderedMessageTemplate={renderedMessageTemplates[i]}
                isXrayMode={isXrayMode}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChatTemplate;
