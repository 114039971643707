import { FunctionFormValues } from "@/components/FunctionsModal/Types";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { UseFormReturn } from "react-hook-form";

const placeholderJson = `{
  "name": "get_weather",
  "description": "Determine weather in my location",
  "parameters": {
    "type": "object",
    "properties": {}
  }
}`;

export const JsonEditor = ({
  form,
}: {
  form: UseFormReturn<FunctionFormValues>;
}) => (
  <FormField
    control={form.control}
    name="json"
    render={({ field }) => (
      <FormItem>
        <FormLabel>
          Schema
          <a
            href="https://json-schema.org/understanding-json-schema/"
            target="_blank"
            rel="noreferrer"
            className="ml-1 underline"
          >
            (JSONSchema)
          </a>
        </FormLabel>
        <FormControl>
          <Textarea
            className="font-mono"
            placeholder={placeholderJson}
            {...field}
            rows={10}
            onChange={(e) => field.onChange(e.target.value)}
            value={
              typeof field.value === "string"
                ? field.value
                : JSON.stringify(field.value, null, 2)
            }
          />
        </FormControl>
        <FormDescription>
          JSON Schema object with the function name, description, and
          parameters.
        </FormDescription>
        <FormMessage />
      </FormItem>
    )}
  />
);
