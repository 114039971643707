import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { Folder } from "@/types/folders";
import { TooltipArrow, TooltipTrigger } from "@radix-ui/react-tooltip";

const FolderPath = ({
  folder,
  limit = 2,
}: {
  folder: Folder;
  limit?: number;
}) => {
  const getFolderPath = (folder: Folder) => {
    let folderPath: string = folder.path
      ?.map((path) => path?.name ?? "")
      ?.join("/");
    folderPath = folder.path ? `${folderPath}/${folder.name}` : folder.name;
    return folderPath;
  };

  const fullPath = getFolderPath(folder);
  const isExceedLimit = fullPath.split("/").length > limit;
  const truncatedFolderPath = `.../${fullPath
    .split("/")
    .slice(-limit)
    .join("/")}`;

  return (
    <>
      {isExceedLimit ? (
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>{truncatedFolderPath}</TooltipTrigger>
            <TooltipContent
              className="rounded bg-gray-800 p-2 text-sm text-white"
              side="top"
              sideOffset={10}
            >
              {fullPath}
              <TooltipArrow className="fill-current text-gray-800" />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <span>{fullPath}</span>
      )}
    </>
  );
};

export default FolderPath;
