import FunctionEditorDialog from "@/components/FunctionsModal/FunctionEditorDialog";
import FunctionOverviewDialog from "@/components/FunctionsModal/FunctionOverviewDialog";
import {
  FunctionDialogContextType,
  FunctionDialogProps,
  ParametersEditorModeEnum,
} from "@/components/FunctionsModal/Types";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Function_ } from "@/types";
import { TerminalIcon } from "@heroicons/react/outline";
import { createContext, useContext, useMemo, useState } from "react";

const FunctionDialogContext = createContext<FunctionDialogContextType>({
  functions: [],
  setFunctions: () => {},
  functionCall: undefined,
  setFunctionCall: () => {},
  functionCallValue: "",
  functionsType: "functions",
  setFunctionsType: () => {},
  isAddingFunction: false,
  setIsAddingFunction: () => {},
  editingFunction: null,
  setEditingFunction: () => {},
  isEditing: false,
  parameterEditorMode: ParametersEditorModeEnum.INTERACTIVE,
  setParameterEditorMode: () => {},
  onSubmit: () => {},
});

export const FunctionDialog = (props: FunctionDialogProps) => {
  const [parameterEditorMode, setParameterEditorMode] = useState(
    ParametersEditorModeEnum.INTERACTIVE,
  );
  const [isAddingFunction, setIsAddingFunction] = useState(false);
  const [editingFunction, setEditingFunction] = useState<Function_ | null>(
    null,
  );

  const functionCallValue =
    typeof props.functionCall === "string"
      ? props.functionCall
      : props.functionCall?.name || "";

  const isEditing = useMemo(
    () => !!props.setFunctions && !!props.onSubmit,
    [props.setFunctions, props.onSubmit],
  );

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(
    () => ({
      functions: props.functions,
      setFunctions: props.setFunctions || (() => {}),
      functionCall: props.functionCall,
      setFunctionCall: props.setFunctionCall || (() => {}),
      functionCallValue,
      functionsType: props.functionsType,
      setFunctionsType: props.setFunctionsType || (() => {}),
      isAddingFunction,
      setIsAddingFunction,
      editingFunction,
      setEditingFunction,
      isEditing,
      parameterEditorMode,
      setParameterEditorMode,
      onSubmit: props.onSubmit || (() => {}),
    }),
    [
      props.functions,
      props.setFunctions,
      props.functionCall,
      props.setFunctionCall,
      props.functionsType,
      props.setFunctionsType,
      props.onSubmit,
      functionCallValue,
      isAddingFunction,
      editingFunction,
      isEditing,
      parameterEditorMode,
    ],
  );

  return (
    <Dialog>
      <FunctionDialogContext.Provider value={value}>
        <DialogTrigger asChild>
          <Button variant="outline">
            <TerminalIcon className="mr-1 inline h-4 w-4" />
            {"Functions"}
            {value.functions.length ? `(${value.functions.length})` : ""}
          </Button>
        </DialogTrigger>
        <DialogContent className="flex max-h-[800px] min-h-[600px] max-w-6xl flex-col overflow-auto">
          {isAddingFunction || editingFunction ? (
            <FunctionEditorDialog />
          ) : (
            <FunctionOverviewDialog />
          )}
        </DialogContent>
      </FunctionDialogContext.Provider>
    </Dialog>
  );
};

export const useFunctionDialogContext = () => {
  const context = useContext(FunctionDialogContext);
  if (!context) {
    throw new Error(
      "useFunctionDialogContext must be used within a FunctionDialogProvider",
    );
  }
  return context;
};
