import { Function_ } from "@/types";

const getOptionalParameters = (declaration: Function_): string[] => {
  const requiredParameters = declaration?.parameters?.required || [];
  const allParameters = Object.keys(declaration?.parameters?.properties || {});
  const optionalParameters = allParameters.filter(
    (param) => !requiredParameters.includes(param),
  );
  return optionalParameters;
};

export const FunctionDeclarationsFormatter = ({
  functionDeclarations,
  functionCall,
}: {
  functionDeclarations: Function_[];
  functionCall?: string | { [key: string]: string };
}) => {
  return (
    <div className="space-y-2">
      {functionDeclarations.map((declaration: Function_, index) => (
        <div key={index}>
          <div className="font-medium text-gray-900">{declaration?.name}</div>
          <div className="pl-2 pt-1 font-sans text-sm italic text-gray-500">
            {declaration?.description}
          </div>
          <div className="pl-2 pt-1 text-gray-600">
            {declaration?.parameters?.type === "object" ? (
              <>
                {/* Required Parameters: */}
                <ParametersList
                  parameters={declaration?.parameters?.required || []}
                  properties={declaration?.parameters?.properties || {}}
                  title="Required Parameters:"
                />
                {/* Optional Parameters */}

                <ParametersList
                  parameters={getOptionalParameters(declaration)}
                  properties={declaration?.parameters?.properties || {}}
                  title="Optional Parameters:"
                />
              </>
            ) : (
              <div>{JSON.stringify(declaration, undefined, 2)}</div>
            )}
          </div>
        </div>
      ))}
      {functionCall !== "" ? (
        <div className="font-medium text-gray-700">
          {"function_call: "}
          {typeof functionCall === "string" ? (
            <span className="italic">{functionCall}</span>
          ) : (
            <span className="font-mono">{functionCall?.name}</span>
          )}
        </div>
      ) : null}
    </div>
  );
};

// Component to render parameters list
const ParametersList = ({
  parameters,
  properties,
  title,
}: {
  parameters: string[];
  properties: Record<string, any>;
  title: string;
}) =>
  parameters.length > 0 && (
    <>
      <div className="font-sans text-sm text-gray-900">{title}</div>
      <ul className="py-1 pl-4">
        {parameters.map((param, index) => (
          <ParameterRenderer
            key={index}
            keyName={param}
            value={properties[param]}
          />
        ))}
      </ul>
    </>
  );

// Component to render individual parameters
const ParameterRenderer = ({
  keyName,
  value,
}: {
  keyName: string;
  value: any;
}) => (
  <li key={keyName} className="pb-1 text-sm">
    <span className="font-semibold text-gray-500">{`${keyName} (${value?.type})`}</span>
    {value?.enum && (
      <span className="font-mono text-sm font-semibold text-gray-500">
        [{value.enum.join(", ")}]
      </span>
    )}
    {value?.description && (
      <span className="font-sans text-sm italic text-gray-500">
        : {value?.description?.trim()}
      </span>
    )}
    {value?.type === "object" && value?.properties && (
      <ObjectParameterRenderer properties={value.properties} />
    )}
    {value?.type === "array" && value?.items && (
      <ArrayParameterRenderer items={value.items} />
    )}
  </li>
);

// Component to render object-type parameters
const ObjectParameterRenderer = ({ properties }: { properties: any }) => (
  <ul className="py-1 pl-4">
    {Object.keys(properties).map((nestedKey) => (
      <ParameterRenderer keyName={nestedKey} value={properties[nestedKey]} />
    ))}
  </ul>
);

// Component to render array-type parameters
const ArrayParameterRenderer = ({ items }: { items: any }) => (
  <ul className="py-1 pl-4">
    {typeof items === "string" ? (
      <li>{items}</li>
    ) : (
      <>
        {items.description && (
          <span className="font-sans text-sm italic text-gray-500">
            {items.description.trim()}
          </span>
        )}
        {items.properties && (
          <ObjectParameterRenderer properties={items.properties} />
        )}
      </>
    )}
  </ul>
);
