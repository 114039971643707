import InteractiveFunctionParametersEditor from "@/components/FunctionsModal/InteractiveFunctionEditor";
import RegularParamForm from "@/components/FunctionsModal/InteractiveFunctionEditor/RegularParamForm";
import {
  ItemDescription,
  ItemProperties,
  Properties,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";
import {
  handleParameterChange,
  handleRemoveRow,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/utils/InteractiveParameterUtils";
import { Dispatch, FC, SetStateAction } from "react";

interface ArrayParameterEditorProps {
  parameters: Properties[];
  parameter: Properties;
  setParameters: Dispatch<SetStateAction<Properties[]>>;
  depth: number;
  index: number;
  isItemDescription?: boolean;
}

const ArrayParameterEditor: FC<ArrayParameterEditorProps> = ({
  parameters,
  parameter,
  setParameters,
  depth,
  index,
  isItemDescription,
}) => {
  return (
    <div className={`${depth === 0 ? "max-h-[150px] overflow-y-auto" : ""}`}>
      <RegularParamForm
        parameter={parameter}
        parameters={parameters}
        setParameters={setParameters}
        handleParameterChange={handleParameterChange}
        depth={depth}
        index={index}
        handleRemoveRow={handleRemoveRow}
        isItemDescription={isItemDescription}
      >
        <div className="col-span-6 col-start-3">
          <InteractiveFunctionParametersEditor
            onChange={(value) =>
              handleParameterChange(
                index,
                "items",
                value,
                parameters,
                setParameters,
                isItemDescription,
              )
            }
            initialValue={(() => {
              if (parameter?.items?.hasOwnProperty("properties")) {
                const itemsProperties = (parameter.items as ItemProperties)
                  .properties;
                const itemsRequiredArray = (parameter.items as ItemProperties)
                  .required;
                if (typeof itemsProperties === "object") {
                  return { ...itemsProperties, required: itemsRequiredArray };
                }
                return itemsProperties;
              } else {
                return parameter.items as ItemDescription;
              }
            })()}
            depth={depth + 1}
            outerType={parameter.type}
          />
        </div>
      </RegularParamForm>
    </div>
  );
};

export default ArrayParameterEditor;
