import { Eye, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useParams } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./components/ui/accordion";
import { Button } from "./components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./components/ui/dialog";
import { Input } from "./components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./components/ui/tooltip";
import { useAuth } from "./context/auth-context";
import {
  useDeleteInferenceClient,
  useInferenceClients,
  useInferenceClientsList,
} from "./queries";

interface HeaderOrCookie {
  id: string;
  key: string;
  value: string;
}

interface Model {
  id: number;
  apiName: string;
  modelName: string;
  apiKey: string;
  timeout: string | number;
  headers: HeaderOrCookie[];
  cookies: HeaderOrCookie[];
}

const HuggingFaceSettingsBlock = () => {
  const [models, setModels] = useState<Model[]>([]);

  const { workspaceId } = useParams();
  const [editingModel, setEditingModel] = useState<Model | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isInspectMode, setIsInspectMode] = useState(false);
  const userToken = useAuth()?.userToken || "";

  const { data: inferenceClientsData, isLoading: isLoadingInferenceClients } =
    useInferenceClientsList(userToken, workspaceId || "");

  const {
    mutate: deleteInferenceClient,
    isLoading: isLoadingDeleteInferenceClient,
  } = useDeleteInferenceClient(userToken);

  const inferenceClients = inferenceClientsData?.inference_clients;

  useEffect(() => {
    if (inferenceClients) {
      const formattedModels = inferenceClients.map((client: any) => ({
        id: client.id,
        apiName: client.name,
        modelName: client.model,
        apiKey: client.api_key,
        timeout: client.timeout,
        headers: Object.entries(client.headers || {}).map(([key, value]) => ({
          id: uuidv4(),
          key,
          value: value as string,
        })),
        cookies: Object.entries(client.cookies || {}).map(([key, value]) => ({
          id: uuidv4(),
          key,
          value: value as string,
        })),
      }));
      setModels(formattedModels);
    }
  }, [inferenceClients]);

  const {
    mutate: saveInferenceClients,
    isLoading: isLoadingSaveInferenceClients,
  } = useInferenceClients(userToken);

  const isLoading =
    isLoadingInferenceClients ||
    isLoadingSaveInferenceClients ||
    isLoadingDeleteInferenceClient;

  const handleModelChange = (
    field: keyof Model,
    value: string | HeaderOrCookie[],
  ) => {
    if (editingModel && !isInspectMode) {
      setEditingModel({ ...editingModel, [field]: value });
    }
  };

  const handleHeaderChange = (id: string, newKey: string, newValue: string) => {
    if (editingModel && !isInspectMode) {
      const newHeaders = editingModel.headers.map((header) =>
        header.id === id ? { ...header, key: newKey, value: newValue } : header,
      );
      setEditingModel({ ...editingModel, headers: newHeaders });
    }
  };

  const handleCookieChange = (id: string, newKey: string, newValue: string) => {
    if (editingModel && !isInspectMode) {
      const newCookies = editingModel.cookies.map((cookie) =>
        cookie.id === id ? { ...cookie, key: newKey, value: newValue } : cookie,
      );
      setEditingModel({ ...editingModel, cookies: newCookies });
    }
  };

  const addHeader = () => {
    if (editingModel && !isInspectMode) {
      const newHeader = { id: uuidv4(), key: "", value: "" };
      setEditingModel({
        ...editingModel,
        headers: [...editingModel.headers, newHeader],
      });
    }
  };

  const addCookie = () => {
    if (editingModel && !isInspectMode) {
      const newCookie = { id: uuidv4(), key: "", value: "" };
      setEditingModel({
        ...editingModel,
        cookies: [...editingModel.cookies, newCookie],
      });
    }
  };

  const removeHeader = (id: string) => {
    if (editingModel && !isInspectMode) {
      const newHeaders = editingModel.headers.filter(
        (header) => header.id !== id,
      );
      setEditingModel({ ...editingModel, headers: newHeaders });
    }
  };

  const removeCookie = (id: string) => {
    if (editingModel && !isInspectMode) {
      const newCookies = editingModel.cookies.filter(
        (cookie) => cookie.id !== id,
      );
      setEditingModel({ ...editingModel, cookies: newCookies });
    }
  };

  const addModel = () => {
    setEditingModel({
      id: -1,
      apiName: "",
      modelName: "",
      apiKey: "",
      timeout: "",
      headers: [],
      cookies: [],
    });
    setIsInspectMode(false);
    setIsDialogOpen(true);
  };

  const inspectModel = (model: Model) => {
    setEditingModel({ ...model });
    setIsInspectMode(true);
    setIsDialogOpen(true);
  };

  const removeModel = (model: Model) => {
    deleteInferenceClient(
      {
        id: model.id,
      },
      {
        onSuccess: () => {
          const newModels = models.filter((m) => m.apiName !== model.apiName);
          setModels(newModels);
        },
      },
    );
  };

  const saveModel = () => {
    if (
      editingModel &&
      editingModel.apiName &&
      editingModel.modelName &&
      editingModel.apiKey
    ) {
      const modelIndex = models.findIndex(
        (m) => m.apiName === editingModel.apiName,
      );
      if (modelIndex !== -1) {
        const newModels = [...models];
        newModels[modelIndex] = editingModel;
        setModels(newModels);
      } else {
        setModels([...models, editingModel]);
      }
      setIsDialogOpen(false);
      saveModelData(editingModel);
      setEditingModel(null);
    }
  };

  const saveModelData = async (model: Model) => {
    saveInferenceClients({
      workspace_id: Number(workspaceId),
      name: model.apiName,
      model: model.modelName,
      api_key: model.apiKey,
      timeout: model.timeout ? Number(model.timeout) : null,
      headers: Object.fromEntries(
        model.headers
          .filter((h) => (h.key && h.value) || (h.key && !h.value))
          .map((h) => [h.key, h.value]),
      ),
      cookies: Object.fromEntries(
        model.cookies
          .filter((c) => (c.key && c.value) || (c.key && !c.value))
          .map((c) => [c.key, c.value]),
      ),
    });
  };

  return (
    <>
      <div className="my-6 rounded-lg border border-gray-200 p-4 shadow-sm">
        <div className="huggingface-settings flex flex-col gap-4 py-3">
          <div className="flex items-center justify-between border-b pb-2">
            <h1 className="text-2xl font-semibold">Inference Clients</h1>
            {isLoading && <LoadingSpinner size={5} />}
          </div>
          {models.length === 0 ? (
            <div className="mt-3 text-center text-gray-500">
              No models specified. Click "Create new" to get started.
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Display Name</TableHead>
                  <TableHead>Model</TableHead>
                  <TableHead>API Key</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {models.map((model, index) => (
                  <TableRow key={index}>
                    <TableCell>{model.apiName}</TableCell>
                    <TableCell>{model.modelName}</TableCell>
                    <TableCell>••••••••</TableCell>
                    <TableCell>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => inspectModel(model)}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Inspect model</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => removeModel(model)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Remove model</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {isInspectMode ? "Inspect Model" : "Add Model"}
                </DialogTitle>
              </DialogHeader>
              {editingModel && (
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="apiName"
                      className="mb-1 block text-sm font-medium"
                    >
                      Display Name <span className="text-red-500">*</span>
                    </label>
                    <Input
                      id="apiName"
                      placeholder="Display Name"
                      value={editingModel.apiName}
                      onChange={(e) =>
                        handleModelChange("apiName", e.target.value)
                      }
                      required
                      readOnly={isInspectMode}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="modelName"
                      className="mb-1 block text-sm font-medium"
                    >
                      Model Name or URL <span className="text-red-500">*</span>
                    </label>
                    <Input
                      id="modelName"
                      placeholder="Model Name"
                      value={editingModel.modelName}
                      onChange={(e) =>
                        handleModelChange("modelName", e.target.value)
                      }
                      required
                      readOnly={isInspectMode}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="apiKey"
                      className="mb-1 block text-sm font-medium"
                    >
                      API Key <span className="text-red-500">*</span>
                    </label>
                    <Input
                      id="apiKey"
                      type="password"
                      placeholder="API Key"
                      value={
                        isInspectMode ? "12345678910" : editingModel.apiKey
                      }
                      onChange={(e) =>
                        handleModelChange("apiKey", e.target.value)
                      }
                      required
                      readOnly={isInspectMode}
                    />
                  </div>

                  <Accordion type="single" collapsible>
                    <AccordionItem
                      value="optional"
                      className="rounded-md border px-4 py-2"
                    >
                      <AccordionTrigger>
                        <div className="flex items-center">
                          Optional Settings
                          {(editingModel.headers.length > 0 ||
                            editingModel.cookies.length > 0 ||
                            (editingModel.timeout as number) > 0) && (
                            <span className="ml-2 h-2 w-2 rounded-full bg-blue-500"></span>
                          )}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="space-y-4">
                          <div>
                            <label
                              htmlFor="timeout"
                              className="mb-1 block text-sm font-medium"
                            >
                              Timeout (seconds)
                            </label>
                            <Input
                              id="timeout"
                              type="number"
                              placeholder={
                                isInspectMode ? "0" : "Enter timeout in seconds"
                              }
                              value={editingModel.timeout || ""}
                              onChange={(e) =>
                                handleModelChange("timeout", e.target.value)
                              }
                              readOnly={isInspectMode}
                            />
                          </div>
                          <Accordion type="single" collapsible>
                            <AccordionItem value="headers">
                              <AccordionTrigger>
                                <div className="flex items-center">
                                  Headers
                                  {editingModel.headers.length > 0 && (
                                    <span className="ml-2 h-2 w-2 rounded-full bg-blue-500"></span>
                                  )}
                                </div>
                              </AccordionTrigger>
                              <AccordionContent>
                                <div className="space-y-2">
                                  {editingModel.headers.map((header) => (
                                    <div
                                      key={header.id}
                                      className="flex items-center space-x-2"
                                    >
                                      <Input
                                        placeholder="Key"
                                        value={header.key}
                                        onChange={(e) =>
                                          handleHeaderChange(
                                            header.id,
                                            e.target.value,
                                            header.value,
                                          )
                                        }
                                        readOnly={isInspectMode}
                                      />
                                      <Input
                                        placeholder="Value"
                                        value={header.value}
                                        onChange={(e) =>
                                          handleHeaderChange(
                                            header.id,
                                            header.key,
                                            e.target.value,
                                          )
                                        }
                                        readOnly={isInspectMode}
                                      />
                                      {!isInspectMode && (
                                        <Button
                                          size="icon"
                                          variant="destructiveLink"
                                          onClick={() =>
                                            removeHeader(header.id)
                                          }
                                        >
                                          <Trash2 className="h-4 w-4" />
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {!isInspectMode && (
                                    <Button
                                      onClick={addHeader}
                                      variant="outline"
                                      size="sm"
                                    >
                                      Add Header
                                    </Button>
                                  )}
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>

                          <Accordion type="single" collapsible>
                            <AccordionItem value="cookies">
                              <AccordionTrigger>
                                <div className="flex items-center">
                                  Cookies
                                  {editingModel.cookies.length > 0 && (
                                    <span className="ml-2 h-2 w-2 rounded-full bg-blue-500"></span>
                                  )}
                                </div>
                              </AccordionTrigger>
                              <AccordionContent>
                                <div className="space-y-2">
                                  {editingModel.cookies.map((cookie) => (
                                    <div
                                      key={cookie.id}
                                      className="flex items-center space-x-2"
                                    >
                                      <Input
                                        placeholder="Key"
                                        value={cookie.key}
                                        onChange={(e) =>
                                          handleCookieChange(
                                            cookie.id,
                                            e.target.value,
                                            cookie.value,
                                          )
                                        }
                                        readOnly={isInspectMode}
                                      />
                                      <Input
                                        placeholder="Value"
                                        value={cookie.value}
                                        onChange={(e) =>
                                          handleCookieChange(
                                            cookie.id,
                                            cookie.key,
                                            e.target.value,
                                          )
                                        }
                                        readOnly={isInspectMode}
                                      />
                                      {!isInspectMode && (
                                        <Button
                                          size="icon"
                                          variant="destructiveLink"
                                          onClick={() =>
                                            removeCookie(cookie.id)
                                          }
                                        >
                                          <Trash2 className="h-4 w-4" />
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {!isInspectMode && (
                                    <Button
                                      onClick={addCookie}
                                      variant="outline"
                                      size="sm"
                                    >
                                      Add Cookie
                                    </Button>
                                  )}
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>

                  {!isInspectMode && (
                    <Button
                      onClick={saveModel}
                      disabled={
                        !editingModel.apiName ||
                        !editingModel.modelName ||
                        !editingModel.apiKey
                      }
                    >
                      Submit
                    </Button>
                  )}
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <Button onClick={addModel}>Create new</Button>
    </>
  );
};

export default HuggingFaceSettingsBlock;
