import { ColumnType, ReportColumn } from "@/types/evaluate";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const ColumnStateContext = createContext<any>({
  isColumnCollapsed: (columnName: string) => false,
  toggleColumn: () => {},
});

const useInitialColumnState = (columns: ReportColumn[]) => {
  const storageKey = `collapsedColumns_${columns?.[0]?.report_id}`;

  const initialColumnStates = useMemo(() => {
    const storedStates = localStorage.getItem(storageKey);
    if (storedStates) {
      return JSON.parse(storedStates);
    }
    return columns.reduce(
      (acc: { [key: string]: boolean }, column: ReportColumn) => {
        if (column.id) {
          acc[column.id] =
            column.column_type === ColumnType.DATASET &&
            columns.length > 5 &&
            columns.indexOf(column) < columns.length - 5;
        }
        return acc;
      },
      {},
    );
  }, [columns, storageKey]);

  const [columnStates, setColumnStates] = useState<{ [key: string]: boolean }>(
    initialColumnStates,
  );

  const prevInitialColumnStates = useRef(columnStates);

  useEffect(() => {
    if (
      Object.keys(initialColumnStates).length > 0 &&
      prevInitialColumnStates.current !== initialColumnStates
    )
      setColumnStates(initialColumnStates);
  }, [initialColumnStates]);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(columnStates));
  }, [columnStates, storageKey]);

  return [columnStates, setColumnStates] as const;
};

export const useCollapsibleColumnContext = () => {
  return useContext(ColumnStateContext);
};

const useCollapsibleColumns = (columns: ReportColumn[]) => {
  const [columnStates, setColumnStates] = useInitialColumnState(columns);

  const isColumnCollapsed = useCallback(
    (column: string) => columnStates[column] === true,
    [columnStates],
  );

  const toggleColumn = useCallback(
    (column: string) => {
      setColumnStates((prev) => ({
        ...prev,
        [column]: !prev[column],
      }));
    },
    [setColumnStates],
  );

  const ColumnStateProvider = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      return (
        <ColumnStateContext.Provider
          value={{
            isColumnCollapsed,
            toggleColumn,
            columnStates,
          }}
        >
          {children}
        </ColumnStateContext.Provider>
      );
    },
    [columnStates, isColumnCollapsed, toggleColumn],
  );

  const totalHiddenColumns = useMemo(() => {
    return columns.reduce((acc: number, column: ReportColumn, i) => {
      if (column?.id && columnStates[column.id] === true) {
        acc++;
      }
      return acc;
    }, 0);
  }, [columns, columnStates]);

  const showAllColumns = useCallback(() => {
    setColumnStates({});
  }, [setColumnStates]);

  return {
    ColumnStateProvider,
    isColumnCollapsed,
    toggleColumn,
    totalHiddenColumns,
    showAllColumns,
  };
};

export default useCollapsibleColumns;
