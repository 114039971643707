import { useUser } from "@/context/user-context";
import { useAccessibleModels } from "@/modelConfig";
import { PromptTemplateConfiguration, ReportColumn } from "@/types/evaluate";
import { ReactNode, useMemo, useState } from "react";
import ChooseEngineStep from "./ChooseEngineStep";
import ChooseTemplateStep from "./ChooseTemplateStep";
import HomeStep from "./HomeStep";

const PromptTemplateColumnBuilder = ({
  columnData,
  patchColumnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  patchColumnData: (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => void;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [currentNavigation, setCurrentNavigation] = useState("home");

  const configuration: Partial<PromptTemplateConfiguration> = useMemo(() => {
    return columnData.configuration || {};
  }, [columnData]);
  const setConfiguration = (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => {
    if (editable) {
      if (typeof data === "function") {
        patchColumnData((prevState) => {
          return {
            ...prevState,
            configuration: data(prevState?.configuration || {}),
          };
        });
      } else {
        patchColumnData({ configuration: data });
      }
    }
  };

  const userContext = useUser();
  const userId = userContext.user?.id;
  const modelConfigs = useAccessibleModels(userId);

  const saveTemplateAction = () => {
    const newColumnData = columnData;

    // Complete the configuration
    if (!configuration.prompt_template_variable_mappings) {
      newColumnData.configuration = {
        ...configuration,
        prompt_template_variable_mappings: {},
      };
    }

    saveColumnAction(newColumnData as ReportColumn);
  };

  const builderNavigation: { [key: string]: () => ReactNode } = {
    home: () => (
      <HomeStep
        navigateAway={navigatePrevious}
        navigateNext={saveTemplateAction}
        setCurrentNavigation={setCurrentNavigation}
        availableColumns={availableColumns}
        configuration={configuration}
        setConfiguration={setConfiguration}
        columnName={columnData.name || ""}
        setColumnName={(name: string) => patchColumnData({ name })}
        editable={editable}
        columnId={columnData.id}
      />
    ),
    "select-prompt-template": () => (
      <ChooseTemplateStep
        navigateAway={() => {
          const templateName = configuration.template?.name;
          if (templateName && !columnData.name) {
            patchColumnData({ name: `step-${templateName}` });
          }
          setCurrentNavigation("home");
        }}
        configuration={configuration}
        setConfiguration={setConfiguration}
        editable={editable}
      />
    ),
    "select-engine-and-params": () => (
      <ChooseEngineStep
        navigateAway={() => setCurrentNavigation("home")}
        modelConfigs={modelConfigs}
        configuration={configuration}
        setConfiguration={setConfiguration}
        editable={editable}
        // setConfigurationFromPrevious={setConfigurationFromPrevious}
      />
    ),
  };

  return <>{builderNavigation[currentNavigation]()}</>;
};

export default PromptTemplateColumnBuilder;
