import InteractiveFunctionParametersEditor from "@/components/FunctionsModal/InteractiveFunctionEditor";
import RegularParamForm from "@/components/FunctionsModal/InteractiveFunctionEditor/RegularParamForm";
import { Properties } from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";
import {
  handleParameterChange,
  handleRemoveRow,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/utils/InteractiveParameterUtils";
import { Dispatch, FC, SetStateAction } from "react";

interface ObjectParameterEditorProps {
  parameters: Properties[];
  parameter: Properties;
  setParameters: Dispatch<SetStateAction<Properties[]>>;
  depth: number;
  index: number;
}

const ObjectParameterEditor: FC<ObjectParameterEditorProps> = ({
  parameters,
  parameter,
  setParameters,
  depth,
  index,
}) => {
  return (
    <div className={`${depth === 0 ? "max-h-[150px] overflow-y-auto" : ""}`}>
      <RegularParamForm
        parameter={parameter}
        parameters={parameters}
        setParameters={setParameters}
        handleParameterChange={handleParameterChange}
        depth={depth}
        index={index}
        handleRemoveRow={handleRemoveRow}
      >
        <div className="col-span-6 col-start-3">
          <InteractiveFunctionParametersEditor
            onChange={(val) =>
              handleParameterChange(
                index,
                "properties",
                val,
                parameters,
                setParameters,
              )
            }
            initialValue={parameter.properties}
            depth={depth + 1}
            outerType={parameter.type}
          />
        </div>
      </RegularParamForm>
    </div>
  );
};

export default ObjectParameterEditor;
