import {
  ParameterType,
  Properties,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";
import { PlusIcon } from "@heroicons/react/outline";

const AddParameterButton = ({
  setParameters,
}: {
  setParameters: React.Dispatch<React.SetStateAction<Properties[]>>;
}) => {
  const handleAddRow = () => {
    const newProperty: Properties = {
      name: "",
      type: ParameterType.String,
      description: "",
      title: "",
      isRequired: false,
    };
    setParameters((prev: Properties[]) => [...prev, newProperty]);
  };

  return (
    <div className="flex justify-end">
      <div
        className="mt-4 inline-flex cursor-pointer items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        onClick={handleAddRow}
      >
        <PlusIcon className="mr-2 h-5 w-5" />
        Add Parameter
      </div>
    </div>
  );
};

export default AddParameterButton;
