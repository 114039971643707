import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  useMetadataFields,
  usePromptRegistryObjects,
  useScoreNames,
} from "@/queries";
import { handleAddMetadataToSearchParams } from "@/utils/metadata";
import {
  URL_PARAM_KEYS,
  getLastMetadataFieldFilter,
  setLastMetadataFieldFilter,
} from "@/utils/utils";
import { Popover } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchBarInput from "./SearchBarInput";

const SearchBarExpanded = ({
  inputRef,
  requestsOn,
}: {
  inputRef: RefObject<HTMLInputElement>;
  requestsOn?: boolean;
}) => {
  const auth = useAuth();
  const user = useUser();
  const metadataFieldsNames = useMetadataFields(
    auth?.userToken!,
    user?.activeWorkspaceId,
  );
  const scoreNames = useScoreNames(auth?.userToken!, user?.activeWorkspaceId);

  const promptRegistryObjectsQuery = usePromptRegistryObjects(
    auth?.userToken!,
    {
      workspaceId: user?.activeWorkspaceId!,
      perPage: Number.MAX_SAFE_INTEGER,
    },
  );

  const promptTemplates =
    promptRegistryObjectsQuery.data?.pages.flatMap((page) => page.items) ?? [];
  const promptTemplatesIsLoading = promptRegistryObjectsQuery.isLoading;

  const {
    setMetadataFields,
    setScores,
    setStartTimeParam,
    setEndTimeParam,
    setDefaultDateRangeLimitIsOn,
    setQ,
    setSelectedTags,
    setShowFavorites,
    setPromptTemplate,
  } = user;
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get(URL_PARAM_KEYS.Q) ?? "";

  const metadataParam = searchParams.get(URL_PARAM_KEYS.METADATA) ?? "[]";
  const metadataFields = JSON.parse(metadataParam) as Array<{
    key: string;
    value: string;
  }>;
  const scoresParam = searchParams.get(URL_PARAM_KEYS.SCORES) ?? "[]";
  const scores = JSON.parse(scoresParam) as Array<{
    name: string;
    operator: string;
    value: number;
  }>;
  const startTimeSearchParam = searchParams.get(URL_PARAM_KEYS.START_TIME);
  const endTimeSearchParam = searchParams.get(URL_PARAM_KEYS.END_TIME);
  const startTime = startTimeSearchParam
    ? new Date(startTimeSearchParam)
    : undefined;
  const endTime = endTimeSearchParam ? new Date(endTimeSearchParam) : undefined;

  // defaults to true if not specified
  const defaultDateRangeLimitIsOnParam = searchParams.get(
    URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON,
  );

  const [defaultDateRangeLimitIsOnState, setDefaultDateRangeLimitIsOnState] =
    useState(defaultDateRangeLimitIsOnParam !== "false");

  const lastMetadataFieldFilter = useMemo(
    () => getLastMetadataFieldFilter(),
    [],
  );

  // Add these functions inside the SearchBar component
  const [startTimeParamState, setStartTimeParamState] = useState(
    startTimeSearchParam ||
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
  );
  const [endTimeParamState, setEndTimeParamState] =
    useState(endTimeSearchParam);

  const handleDateRangeDefaultLimitToggle = (checked: boolean) => {
    // If it's turned off, set the url param
    if (!checked) {
      setSearchParams((prev) => {
        prev.set(URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON, "false");
        return prev;
      });
      setDefaultDateRangeLimitIsOn(false);
      return;
    } else {
      setSearchParams((prev) => {
        prev.delete(URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON);
        return prev;
      });
      setDefaultDateRangeLimitIsOn(true);
    }
  };

  const handleAddDateRange = () => {
    if (startTimeParamState || endTimeParamState) {
      if (startTimeParamState) {
        const parsedStartTime = new Date(startTimeParamState + "T00:00");
        setSearchParams((prev) => {
          prev.set(URL_PARAM_KEYS.START_TIME, parsedStartTime.toISOString());
          return prev;
        });
        setStartTimeParam(parsedStartTime);
      } else {
        setSearchParams((prev) => {
          prev.delete(URL_PARAM_KEYS.START_TIME);
          return prev;
        });
        setStartTimeParam(null);
      }

      if (endTimeParamState) {
        const parsedEndTime = new Date(endTimeParamState + "T00:00");
        setSearchParams((prev) => {
          prev.set(URL_PARAM_KEYS.END_TIME, parsedEndTime.toISOString());
          return prev;
        });
        setEndTimeParam(parsedEndTime);
      } else {
        setSearchParams((prev) => {
          prev.delete(URL_PARAM_KEYS.END_TIME);
          return prev;
        });
        setEndTimeParam(null);
      }
    }
  };

  const handleRemoveDateRange = () => {
    setSearchParams((prev) => {
      prev.delete(URL_PARAM_KEYS.START_TIME);
      prev.delete(URL_PARAM_KEYS.END_TIME);
      return prev;
    });
    setStartTimeParam(null);
    setEndTimeParam(null);
  };

  const handleAddPromptTemplate = () => {
    const selectedPromptTemplate = promptTemplateNameRef.current?.value;
    if (!selectedPromptTemplate) return;
    setSearchParams((prev) => {
      prev.set(
        URL_PARAM_KEYS.PROMPT_TEMPLATE,
        JSON.stringify({ name: selectedPromptTemplate }),
      );
      return prev;
    });
  };

  const handleRemovePromptTemplate = () => {
    setSearchParams((prev) => {
      prev.delete(URL_PARAM_KEYS.PROMPT_TEMPLATE);
      return prev;
    });
  };

  const promptTemplateFromParams: { name: string } = JSON.parse(
    searchParams.get(URL_PARAM_KEYS.PROMPT_TEMPLATE) ?? "{}",
  );
  const selectedTagsParam = searchParams.get(URL_PARAM_KEYS.TAGS) ?? "[]";
  const showFavorites = searchParams.get(URL_PARAM_KEYS.FAVORITES) === "true";
  const keyRef = useRef<HTMLSelectElement | HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);
  const scoreOperatorRef = useRef<HTMLSelectElement>(null);
  const scoreFieldRef = useRef<HTMLSelectElement>(null);
  const scoreValueRef = useRef<HTMLInputElement>(null);
  const promptTemplateNameRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    // Remove focus from input on enter
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && inputRef.current) {
        inputRef.current.blur();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [inputRef]);

  const handleAddMetadata = () => {
    const key = keyRef.current?.value;
    const value = valueRef.current?.value;
    if (key && value) {
      handleAddMetadataToSearchParams(
        searchParams,
        setSearchParams,
        key,
        value,
      );
    }
  };

  const handleAddScore = () => {
    const scoreField = scoreFieldRef.current?.value;
    const scoreOperator = scoreOperatorRef.current?.value;
    const scoreValue = scoreValueRef.current?.value;
    if (scoreField && scoreOperator && scoreValue) {
      setSearchParams((previous) => {
        previous.set(
          URL_PARAM_KEYS.SCORES,
          JSON.stringify([
            ...scores,
            {
              name: scoreField,
              operator: scoreOperator,
              value: parseInt(scoreValue),
            },
          ]),
        );
        return previous;
      });
    }
  };

  useEffect(() => {
    const metadataFields = JSON.parse(metadataParam);
    setMetadataFields(metadataFields);
  }, [metadataParam, setMetadataFields]);

  useEffect(() => {
    const defaultDateRangeLimitIsOnParam = searchParams.get(
      URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON,
    );
    const newVal = defaultDateRangeLimitIsOnParam !== "false";
    setDefaultDateRangeLimitIsOn(newVal);
    if (newVal !== defaultDateRangeLimitIsOnState) {
      setDefaultDateRangeLimitIsOnState(newVal);
    }
  }, [
    searchParams,
    setDefaultDateRangeLimitIsOn,
    setDefaultDateRangeLimitIsOnState,
    defaultDateRangeLimitIsOnState,
  ]);

  useEffect(() => {
    const promptTemplateParam = searchParams.get(
      URL_PARAM_KEYS.PROMPT_TEMPLATE,
    );
    const promptTemplateFromParams = promptTemplateParam
      ? JSON.parse(promptTemplateParam)
      : null;
    setPromptTemplate(promptTemplateFromParams);
  }, [searchParams, setPromptTemplate]);

  useEffect(() => {
    const scoresParsed = JSON.parse(scoresParam);
    setScores(scoresParsed);
  }, [scoresParam, setScores]);

  useEffect(() => {
    if (startTimeSearchParam) {
      setStartTimeParam(new Date(startTimeSearchParam));
    } else {
      setStartTimeParam(null);
    }
  }, [startTimeSearchParam, setStartTimeParam]);

  useEffect(() => {
    if (endTimeSearchParam) {
      setEndTimeParam(new Date(endTimeSearchParam));
    } else {
      setEndTimeParam(null);
    }
  }, [endTimeSearchParam, setEndTimeParam]);

  useEffect(() => {
    setQ(q);
  }, [q, setQ]);

  useEffect(() => {
    const selectedTags = JSON.parse(selectedTagsParam);
    setSelectedTags(selectedTags);
  }, [selectedTagsParam, setSelectedTags]);

  useEffect(() => {
    setShowFavorites(showFavorites);
  }, [showFavorites, setShowFavorites]);

  const metadataFieldsExist =
    metadataFieldsNames?.data?.metadataFields &&
    metadataFieldsNames?.data?.metadataFields.length > 0;
  const scoreNamesExist =
    scoreNames?.data?.scoreNames && scoreNames?.data?.scoreNames.length > 0;

  const [showDateRange, setShowDateRange] = useState(false);
  const [showMetadata, setShowMetadata] = useState(true);
  const [showPromptTemplate, setShowPromptTemplate] = useState(false);
  const [showScore, setShowScore] = useState(false);

  const renderMetadataFilter =
    metadataFieldsExist || !requestsOn ? (
      <>
        <form
          className={`group flex flex-col gap-y-1 rounded-md border border-gray-200 bg-gray-50 px-3 py-3 shadow-sm ${
            showMetadata ? `` : `hover:border-gray-300 hover:bg-gray-200`
          }`}
          onSubmit={(event) => {
            event.preventDefault();
            handleAddMetadata();
          }}
        >
          {showMetadata ? (
            <div
              className={`mb-2 flex items-center justify-between text-center transition duration-150 ease-in-out`}
            >
              <span className="text-left text-gray-500">Metadata</span>
              <span
                className="cursor-pointer text-gray-500 hover:text-gray-400"
                onClick={() => setShowMetadata(!showMetadata)}
              >
                Hide
              </span>
            </div>
          ) : (
            <div
              className={`flex cursor-pointer items-center justify-between px-1 transition duration-150 ease-in-out`}
              onClick={() => setShowMetadata(!showMetadata)}
            >
              <span className="text-left text-gray-500">Metadata</span>
              <span className="text-gray-500">
                {showMetadata ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronLeftIcon className="h-4 w-4" />
                )}
              </span>
            </div>
          )}
          {showMetadata && (
            <>
              <div className="grid grid-cols-6 items-center">
                <label
                  htmlFor="key"
                  className="col-span-1 font-medium text-gray-500"
                >
                  Key
                </label>
                {requestsOn ? (
                  <select
                    ref={keyRef as RefObject<HTMLSelectElement>}
                    name="search"
                    id="search"
                    placeholder="Search..."
                    className="col-span-5 block h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    onChange={(e) => {
                      const fieldName = e.target.value;
                      setLastMetadataFieldFilter(fieldName);
                    }}
                    defaultValue={lastMetadataFieldFilter || "Select a key..."}
                  >
                    {metadataFieldsNames.data?.metadataFields?.map(
                      (field: any) => (
                        <option key={field.name} value={field.name}>
                          {field.name}
                        </option>
                      ),
                    )}
                  </select>
                ) : (
                  <input
                    ref={keyRef as RefObject<HTMLInputElement>}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Enter a key..."
                    className="col-span-5 block h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                )}
              </div>
              {/* Value Input */}
              <div className="grid grid-cols-6 items-center gap-1">
                <label
                  htmlFor="key"
                  className="col-span-1 font-medium text-gray-500"
                >
                  Value
                </label>
                <input
                  ref={valueRef}
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Enter a value..."
                  className="col-span-5 block h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              {/* Add Button */}
              <div className="flex justify-center pt-1">
                <button
                  type="submit"
                  className="flex items-center gap-2 rounded-md border border-gray-300 px-3 py-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800"
                >
                  <PlusIcon className="h-3" />
                  Add metadata filter
                </button>
              </div>
            </>
          )}
        </form>
      </>
    ) : (
      <i>
        {" "}
        Start logging metadata with the API to use metadata search.{" "}
        <a
          href="https://docs.promptlayer.com/why-promptlayer/advanced-search#metadata-search"
          rel="noreferrer"
          target="_blank"
          className="text-blue-500"
        >
          Learn more in the docs.
        </a>
      </i>
    );

  const renderScoreFilter = requestsOn && scoreNamesExist && (
    <>
      <form
        className={`group flex flex-col gap-y-1 rounded-md border border-gray-200 bg-gray-50 px-3 py-3 shadow-sm ${
          showScore ? `` : `hover:border-gray-300 hover:bg-gray-200`
        }`}
        onSubmit={(event) => {
          event.preventDefault();
          handleAddScore();
        }}
      >
        {showScore ? (
          <div
            className={`mb-2 flex items-center justify-between text-center transition duration-150 ease-in-out`}
          >
            <span className="text-left text-gray-500">Score</span>
            <span
              className="cursor-pointer text-gray-500 hover:text-gray-400"
              onClick={() => setShowScore(!showScore)}
            >
              Hide
            </span>
          </div>
        ) : (
          <div
            className={`flex cursor-pointer items-center justify-between px-1 transition duration-150 ease-in-out`}
            onClick={() => setShowScore(!showScore)}
          >
            <span className="text-left text-gray-500">Score</span>
            <span className="text-gray-500">
              {showScore ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronLeftIcon className="h-4 w-4" />
              )}
            </span>
          </div>
        )}
        {showScore && (
          <>
            <div className="flex flex-row gap-x-2">
              <div className="flex-1">
                <select
                  ref={scoreFieldRef}
                  name="scoreField"
                  id="scoreField"
                  className="block h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  {scoreNames.data?.scoreNames?.map((name: any) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
                <label htmlFor="scoreField" className="text-xs text-gray-500">
                  Score name
                </label>
              </div>
              <div className="">
                <select
                  ref={scoreOperatorRef}
                  name="scoreOperator"
                  id="scoreOperator"
                  className="block h-8 w-20 rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  defaultValue=">"
                >
                  <option value="=">{"="}</option>
                  <option value=">">{">"}</option>
                  <option value="<">{"<"}</option>
                  <option value="<=">{"<="}</option>
                  <option value=">=">{">="}</option>
                </select>
              </div>
              <div className="">
                <input
                  ref={scoreValueRef}
                  type="number"
                  name="scoreValue"
                  id="scoreValue"
                  className="block h-8 w-20 rounded-md border-gray-300 font-mono text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                <label htmlFor="scoreValue" className="text-xs text-gray-500">
                  Value
                </label>
              </div>
            </div>
            <div className="flex justify-center pt-1">
              <button
                type="submit"
                className="flex items-center gap-2 rounded-md border border-gray-300 px-3 py-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800"
              >
                <PlusIcon className="h-3" />
                Add score filter
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );

  const renderPromptTemplateFilter = requestsOn &&
    !promptTemplatesIsLoading &&
    promptTemplates?.length > 0 && (
      <>
        <form
          className={`group flex flex-col gap-y-1 rounded-md border border-gray-200 bg-gray-50 px-3 py-3 shadow-sm ${
            showPromptTemplate ? `` : `hover:border-gray-300 hover:bg-gray-200`
          }`}
          onSubmit={(event) => {
            event.preventDefault();
            handleAddPromptTemplate();
          }}
        >
          {showPromptTemplate ? (
            <div
              className={`mb-2 flex items-center justify-between text-center transition duration-150 ease-in-out`}
            >
              <span className="text-left text-gray-500">Prompt Template</span>
              <span
                className="cursor-pointer text-gray-500 hover:text-gray-400"
                onClick={() => setShowPromptTemplate(!showPromptTemplate)}
              >
                Hide
              </span>
            </div>
          ) : (
            <div
              className={`flex cursor-pointer items-center justify-between px-1 transition duration-150 ease-in-out`}
              onClick={() => setShowPromptTemplate(!showPromptTemplate)}
            >
              <span className="text-left text-gray-500">Prompt Template</span>
              <span className="text-gray-500">
                {showPromptTemplate ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronLeftIcon className="h-4 w-4" />
                )}
              </span>
            </div>
          )}
          {showPromptTemplate && (
            <>
              <div className="flex items-center gap-x-2">
                <select
                  id="prompt-template"
                  name="prompt-template"
                  ref={promptTemplateNameRef}
                  className="block h-9 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  disabled={
                    promptTemplatesIsLoading || promptTemplates?.length === 0
                  }
                >
                  {promptTemplatesIsLoading ? (
                    <option>Loading...</option>
                  ) : promptTemplates?.length > 0 ? (
                    <>
                      {promptTemplates.map((template, index) => (
                        <option key={index} value={template.prompt_name}>
                          {template.prompt_name.length > 30
                            ? template.prompt_name.substring(0, 30) + "..."
                            : template.prompt_name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option>No templates found</option>
                  )}
                </select>
              </div>
              {/* Add Button */}
              <div className="flex justify-center pt-1">
                <button
                  type="submit"
                  className={`flex items-center gap-2 rounded-md border border-gray-300 px-3 py-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800`}
                >
                  <PlusIcon className="h-3" />
                  Add prompt filter
                </button>
              </div>
            </>
          )}
        </form>
      </>
    );

  const renderDateRangeFilter = requestsOn && (
    <>
      <form
        className={`group flex flex-col gap-y-1 rounded-md border border-gray-200 bg-gray-50 px-3 py-3 shadow-sm ${
          showDateRange ? `` : `hover:border-gray-300 hover:bg-gray-200`
        }`}
        onSubmit={(event) => {
          event.preventDefault();
          handleAddDateRange();
        }}
      >
        {showDateRange ? (
          <div
            className={`mb-2 flex items-center justify-between text-center transition duration-150 ease-in-out`}
          >
            <span className="text-left text-gray-500">Date Range</span>
            <span
              className="cursor-pointer text-gray-500 hover:text-gray-400"
              onClick={() => setShowDateRange(!showDateRange)}
            >
              Hide
            </span>
          </div>
        ) : (
          <div
            className={`flex cursor-pointer items-center justify-between px-1 transition duration-150 ease-in-out`}
            onClick={() => setShowDateRange(!showDateRange)}
          >
            <span className="text-left text-gray-500">Date Range</span>
            <span className="text-gray-500">
              {showDateRange ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronLeftIcon className="h-4 w-4" />
              )}
            </span>
          </div>
        )}
        {showDateRange && (
          <>
            <div
              className={`flex gap-x-1 py-1 text-xs text-gray-500 ${
                startTime || endTime ? "disabled opacity-50" : ""
              }`}
            >
              <input
                type="checkbox"
                id="date-range-default"
                name="date-range-default"
                className="rounded-xs h-4 w-4 border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                onChange={(e) => {
                  handleDateRangeDefaultLimitToggle(e.target.checked);
                  setDefaultDateRangeLimitIsOnState(e.target.checked);
                }}
                checked={
                  startTime || endTime ? false : defaultDateRangeLimitIsOnState
                }
              />
              <label
                htmlFor="date-range-default"
                className={`text-gray-500 ${
                  startTime || endTime ? "" : "cursor-pointer"
                }`}
              >
                Default to 14 days
              </label>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex min-w-0 flex-1 flex-col">
                <input
                  type="date"
                  name="start-date"
                  id="start-date"
                  className="h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={startTimeParamState}
                  onChange={(e) => {
                    setStartTimeParamState(e.target.value);
                  }}
                  pattern="\d{2}/\d{2}/\d{2}"
                  placeholder="MM/DD/YY"
                />
                <label htmlFor="start-date" className="text-xs text-gray-500">
                  Start
                </label>
              </div>
              <div className="flex min-w-0 flex-1 flex-col">
                <input
                  type="date"
                  name="end-date"
                  id="end-date"
                  className="h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={endTimeParamState || ""}
                  onChange={(e) => {
                    setEndTimeParamState(e.target.value);
                  }}
                  pattern="\d{2}/\d{2}/\d{2}"
                  placeholder="MM/DD/YY"
                />
                <label htmlFor="end-date" className="text-xs text-gray-500">
                  End
                </label>
              </div>
            </div>
            <div className="flex justify-center pt-1">
              <button
                type="submit"
                className="flex items-center gap-2 rounded-md border border-gray-300 px-3 py-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800"
              >
                <PlusIcon className="h-3" />
                Add date filter
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );

  return (
    <Popover>
      <div className="px-1">
        <SearchBarInput inputRef={inputRef} requestsOn={requestsOn} />
      </div>
      {
        <>
          <div className={`mx-2 flex flex-wrap gap-1 `}>
            {metadataFields.map(({ key, value }: any, index: number) => (
              <div
                key={index}
                className="my-1 flex items-center justify-between"
              >
                <div
                  title={`${key}: ${value}`}
                  className="inline-flex cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                >
                  <button
                    className="h-3 w-3"
                    onClick={() => {
                      const newMetadata = metadataFields.filter(
                        (_x: any, i: number) => i !== index,
                      );
                      setSearchParams((previous) => {
                        if (newMetadata.length === 0) {
                          previous.delete(URL_PARAM_KEYS.METADATA);
                        } else {
                          previous.set(
                            URL_PARAM_KEYS.METADATA,
                            JSON.stringify(newMetadata),
                          );
                        }
                        return previous;
                      });
                    }}
                  >
                    <XIcon className="h-3 w-3" />
                  </button>
                  <div className="flex">
                    <div className="max-w-[100px] truncate font-medium">
                      {key}
                    </div>
                    <div className="pr-1">:</div>
                    <div className="max-w-[50px] truncate">
                      {typeof value !== "string"
                        ? JSON.stringify(value)
                        : value}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {requestsOn &&
              scores.map(({ name, operator, value }: any, index: number) => (
                <div key={index} className="flex items-center justify-between">
                  <div
                    title={`${name} ${operator} ${value}`}
                    className="my-1 inline-flex  cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                  >
                    <button
                      className="h-3 w-3"
                      onClick={() => {
                        const newScores = scores.filter(
                          (_x: any, i: number) => i !== index,
                        );
                        setSearchParams((previous) => {
                          if (newScores.length === 0) {
                            previous.delete(URL_PARAM_KEYS.SCORES);
                          } else {
                            previous.set(
                              URL_PARAM_KEYS.SCORES,
                              JSON.stringify(newScores),
                            );
                          }
                          return previous;
                        });
                      }}
                    >
                      <XIcon className="h-3 w-3" />
                    </button>
                    <div className="flex">
                      <div className="max-w-[100px] truncate font-medium">
                        {name}
                      </div>
                      <div className="px-1">{operator}</div>
                      <div className="max-w-[50px] truncate">{value}</div>
                    </div>
                  </div>
                </div>
              ))}
            {requestsOn && promptTemplateFromParams?.name && (
              <div className="flex items-center justify-between">
                <div
                  title={promptTemplateFromParams.name}
                  className="my-1 inline-flex  cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                >
                  <button
                    className="h-3 w-3"
                    onClick={handleRemovePromptTemplate}
                  >
                    <XIcon className="h-3 w-3" />
                  </button>
                  <div className="flex">
                    <div className="max-w-[100px] truncate font-medium">
                      {promptTemplateFromParams.name}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {requestsOn && startTime && endTime && (
              <div className="flex items-center justify-between">
                <div
                  title={`${startTime.toLocaleString()} - ${endTime.toLocaleString()}`}
                  className="my-1 inline-flex cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                >
                  <button className="h-3 w-3" onClick={handleRemoveDateRange}>
                    <XIcon className="h-3 w-3" />
                  </button>
                  <div className="flex">
                    <div className="max-w-[100px] truncate">
                      {startTime.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </div>
                    <div className="px-1">-</div>
                    <div className="max-w-[100px] truncate">
                      {endTime.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {requestsOn && startTime && !endTime && (
              <div className="flex items-center justify-between">
                <div
                  title={`${startTime.toLocaleString()} - Now`}
                  className="my-1 inline-flex cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                >
                  <button className="h-3 w-3" onClick={handleRemoveDateRange}>
                    <XIcon className="h-3 w-3" />
                  </button>
                  <div className="flex">
                    <div className="max-w-[100px] truncate">
                      {startTime.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </div>
                    <div className="px-1">-</div>
                    <div className="max-w-[100px] truncate">Now</div>
                  </div>
                </div>
              </div>
            )}
            {requestsOn && !startTime && endTime && (
              <div className="flex items-center justify-between">
                <div
                  title={`Up to ${endTime.toLocaleString()}`}
                  className="my-1 inline-flex  cursor-pointer items-center gap-1 truncate rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
                >
                  <button className="h-3 w-3" onClick={handleRemoveDateRange}>
                    <XIcon className="h-3 w-3" />
                  </button>
                  <div className="flex">
                    <div className="max-w-[100px] truncate pr-1">Up to</div>
                    <div className="max-w-[100px] truncate">
                      {endTime.toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Popover.Panel className="mx-1 mb-2 mt-1 space-y-1 rounded p-2 text-xs">
            <div className="space-y-1">
              {metadataFieldsExist || scoreNamesExist || !requestsOn ? (
                <>
                  <div className="mb-2 flex justify-between border-b border-gray-300 pb-1">
                    <div className="font-medium capitalize text-gray-500">
                      Advanced Search
                    </div>
                    <button
                      className="font-medium text-blue-500"
                      type="button"
                      onClick={() => {
                        setSearchParams((previous) => {
                          previous.delete(URL_PARAM_KEYS.METADATA);
                          previous.delete(URL_PARAM_KEYS.SCORES);
                          return previous;
                        });
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                  <div className="space-y-2">
                    {renderMetadataFilter}
                    {renderScoreFilter}
                    {renderPromptTemplateFilter}
                    {renderDateRangeFilter}
                  </div>
                </>
              ) : (
                <>
                  {renderDateRangeFilter}
                  <div className="pt-2 text-center text-xs text-gray-500">
                    Learn more about PromptLayer search on
                    <a
                      href="https://docs.promptlayer.com/why-promptlayer/advanced-search"
                      rel="noreferrer"
                      target="_blank"
                      className="text-blue-500"
                    >
                      {" the docs."}
                    </a>
                  </div>
                </>
              )}
            </div>
          </Popover.Panel>
          <hr className="mx-auto my-3 w-1/5 border-gray-300" />
        </>
      }
    </Popover>
  );
};

export default SearchBarExpanded;
