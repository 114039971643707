export const roles = [
  "system",
  "user",
  "assistant",
  "function",
  "tool",
  "placeholder",
] as const;

export type Role = (typeof roles)[number];

export type TextContent = {
  type: "text";
  text: string;
};

export type ImageURL = {
  url: string;
  detail?: string;
};

export type ImageContent = {
  type: "image_url";
  image_url: ImageURL;
  image_variable?: string;
};

export type Content = TextContent | ImageContent;

export const templateFormats = ["f-string", "jinja2"] as const;

export type TemplateFormat = (typeof templateFormats)[number];

export type FunctionCall = {
  name: string;
  arguments: string;
};

const chat = "chat";
const completion = "completion";

export type CompletionTemplate = {
  type: typeof completion;
  content: Content[];
  input_variables: string[];
  template_format: "f-string" | "jinja2";
};

export type JSONSchema = {
  id?: string;
  $schema?: string;
  title?: string;
  description?: string;
  multipleOf?: number;
  maximum?: number;
  exclusiveMaximum?: boolean;
  minimum?: number;
  exclusiveMinimum?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  additionalItems?: boolean | JSONSchema;
  items?: JSONSchema | JSONSchema[];
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
  maxProperties?: number;
  minProperties?: number;
  required?: string[];
  additionalProperties?: boolean | JSONSchema;
  definitions?: { [key: string]: JSONSchema };
  properties?: { [key: string]: JSONSchema };
  patternProperties?: { [key: string]: JSONSchema };
  dependencies?: { [key: string]: JSONSchema | string[] };
  enum?: any[];
  type?: string | string[];
  format?: string;
  allOf?: JSONSchema[];
  anyOf?: JSONSchema[];
  oneOf?: JSONSchema[];
  not?: JSONSchema;
};

export type Function_ = {
  name: string;
  description: string;
  strict?: boolean;
  parameters?: JSONSchema;
};

export type Tool = {
  type: "function";
  function: Function_;
};

export type MessageFunctionCall = {
  name: string;
};

export type ChatFunctionCall = string | MessageFunctionCall;

export interface BaseMessage {
  content?: Content[];
  input_variables?: string[];
  template_format: TemplateFormat;
  raw_request_display_role?: string;
}

export interface SystemMessage extends BaseMessage {
  role: (typeof roles)[0];
  content: Content[];
  name?: string;
}

export interface UserMessage extends BaseMessage {
  role: (typeof roles)[1];
  content: Content[];
  name?: string;
}

export type ToolCall = {
  id: string;
  type: "function";
  function: FunctionCall;
};

export interface AssistantMessage extends BaseMessage {
  role: (typeof roles)[2];
  function_call?: FunctionCall;
  name?: string;
  tool_calls?: ToolCall[];
}

export interface FunctionMessage extends BaseMessage {
  role: (typeof roles)[3];
  name: string;
}

export interface ToolMessage extends BaseMessage {
  role: (typeof roles)[4];
  content: Content[];
  tool_call_id: string;
  name?: string;
}

export interface PlaceholderMessage extends BaseMessage {
  role: (typeof roles)[5];
  name: string;
}

export type ChatToolChoice = {
  type: "function";
  function: MessageFunctionCall;
};

export type ToolChoice = string | ChatToolChoice;

export type Tag = {
  id: number;
  workspace_id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export type Message =
  | SystemMessage
  | UserMessage
  | AssistantMessage
  | FunctionMessage
  | ToolMessage
  | PlaceholderMessage;

export type ChatTemplate = {
  type: typeof chat;
  messages: Message[];
  functions?: Function_[];
  function_call?: ChatFunctionCall;
  input_variables: string[];
  tools?: Tool[];
  tool_choice?: ToolChoice;
};

export type Model = {
  provider: string;
  name: string;
  parameters: { [key: string]: any };
};

export type Metadata = {
  model?: Model;
  [key: string]: any;
};

export type Template = ChatTemplate | CompletionTemplate;

export type BasePromptTemplate = {
  prompt_name: string;
  tags?: string[];
  folder_id?: number;
  workspace_id?: number;
};

export type PromptBlueprint = {
  prompt_template: Template;
  inference_client_name?: string;
  commit_message?: string;
  metadata?: Metadata;
  report_id?: number;
  provider_base_url_name: string | null;
};

export type CreatePromptVersion = PromptBlueprint & {
  prompt_id: number;
};

export type CreatePromptTemplate = {
  prompt_template: BasePromptTemplate;
  prompt_version: PromptBlueprint;
};

export const functionsTypes = ["functions", "tools"] as const;

export type FunctionsType = (typeof functionsTypes)[number];

export type SchemaDefinition = {
  name: string;
  description?: string;
  strict?: boolean;
  schema?: JSONSchema;
};

export type InferenceClientRequestBody =
  | {
      api_key: string;
    }
  | InferenceClient;

export type InferenceClient = {
  id: number;
  cookies: Record<string, string> | null;
  headers: Record<string, string> | null;
  name: string;
  model: string;
  timeout: number | null;
  proxies: string[] | null;
  workspace_id: number;
};
