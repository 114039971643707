import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Slider } from "@/components/ui/slider";
import useProviderBaseURLNameOptions from "@/hooks/useProviderBaseURLNameOptions";
import {
  ParamConfig,
  defaultModelsChat,
  defaultModelsCompletion,
  modelConfigs,
} from "@/modelConfig";
import { Model } from "@/types";
import { CheckIcon, DuplicateIcon } from "@heroicons/react/outline";
import { SlidersHorizontal } from "lucide-react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  initialProviderBaseURLName: string | null;
  model: Model | null;
  isChat?: boolean;
};

const hasCustomParameters = (model: Model | null) => {
  return model && model.parameters;
};
const getModelConfig = (model: Model, isChat: boolean) => {
  const provider = model.provider;
  const providerExists = modelConfigs && modelConfigs[provider];

  if (!providerExists)
    return {
      metadata: {},
      params: {},
    };

  const modelExists =
    providerExists &&
    model &&
    model.name &&
    !!modelConfigs[provider][model.name];
  let name = model.name;

  if (!modelExists) {
    name = isChat
      ? defaultModelsChat[provider]
      : defaultModelsCompletion[provider];
  }

  return modelConfigs[provider][name];
};

export function PromptParameters(props: Props) {
  const customParametersSet = hasCustomParameters(props.model);

  const getSelector = (key: string, value: any) => {
    switch (value.selectorType) {
      case "slider":
        return (
          <Slider
            id={key}
            name={key}
            min={0.0}
            max={2.0}
            step={0.01}
            className="col-span-1 h-8"
            label={props.model?.parameters?.[key] ?? 1}
            value={[props.model?.parameters?.[key]]}
          />
        );
      case "input":
        return (
          <NonEditableInput
            value={props.model?.parameters?.[key] || value.default}
          />
        );
      case "dropdown":
        return (
          <NonEditableInput
            value={
              Object.keys(value?.options || {}).find(
                (optionKey) =>
                  value?.options[optionKey]?.type ===
                  props.model?.parameters?.[key]?.type,
              ) || value.default
            }
          />
        );
      default:
        return null;
    }
  };

  const providerBaseURLNameOptions = useProviderBaseURLNameOptions(
    props.initialProviderBaseURLName,
  );

  const renderProviderBaseURLRow = () => {
    if (!providerBaseURLNameOptions.length) return null;

    let value = props.initialProviderBaseURLName === "" ? "" : undefined;

    if (value === undefined) {
      value = props.initialProviderBaseURLName || "";
    }

    return (
      <>
        <Label className="col-span-2" htmlFor="providerBaseURL">
          Provider Base URL
        </Label>
        <div className="col-span-2">
          <NonEditableInput
            value={props.initialProviderBaseURLName || "Default"}
          />
        </div>
      </>
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost">
          <div
            className={`relative flex flex-row items-center ${
              customParametersSet
                ? "after:absolute after:-right-2 after:-top-0 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
                : ""
            }`}
          >
            <SlidersHorizontal className="mr-2 h-4 w-4" />
            Parameters
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-100">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Parameters</h4>
            <p className="text-sm text-muted-foreground">
              Configure model parameters.{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                target="_blank"
                rel="noreferrer"
                href="https://docs.promptlayer.com/features/prompt-registry#metadata-2"
              >
                Learn more.
              </a>
            </p>
          </div>
          <div className="w-full">
            {props.model ? (
              <>
                <div className="grid grid-cols-4 items-center gap-4 pt-4">
                  <Label className="col-span-2" htmlFor="provider">
                    Model Provider
                  </Label>
                  <div className="col-span-2">
                    <NonEditableInput value={props.model.provider} />
                  </div>

                  <Label className="col-span-2" htmlFor="model">
                    {props.model.provider === "huggingface"
                      ? "Inference Client Name"
                      : "Model Name"}
                  </Label>
                  <div className="col-span-2">
                    <NonEditableInput value={props.model.name} />
                  </div>

                  {/* Row: Provider base URL */}
                  {props.model.provider !== "huggingface" &&
                    renderProviderBaseURLRow()}
                </div>
                {Object.entries(
                  getModelConfig(props.model, props.isChat || false).params,
                ).map(([key, value]: [string, ParamConfig]) => (
                  <div
                    key={key}
                    className="grid grid-cols-2 items-center gap-4 pt-4"
                  >
                    <Label htmlFor={key}>{value.name}</Label>
                    {getSelector(key, value)}
                  </div>
                ))}
              </>
            ) : (
              <>
                <p className="text-sm italic text-gray-400">
                  No parameters set for this prompt version
                </p>
              </>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function NonEditableInput({ value }: { value: string }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 700);
  };

  return (
    <div>
      <div className="relative">
        <Input
          type="text"
          value={value}
          className="col-span-2 h-8 py-1 pr-10 disabled:cursor-default disabled:opacity-80"
          readOnly
          disabled
        />
        <CopyToClipboard text={value} onCopy={handleCopy}>
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 hover:text-gray-600"
          >
            {copied ? (
              <CheckIcon className="h-5 w-5" />
            ) : (
              <DuplicateIcon className="h-5 w-5" />
            )}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
