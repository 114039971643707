import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ToastType } from "@/enums";
import { displayToast } from "@/utils/toast";
import { FC } from "react";

type DeleteConfirmationModalProps = {
  title: string;
  description: string;
  onDelete: () => Promise<void>;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  title,
  description,
  onDelete,
  open,
  setOpen,
}: DeleteConfirmationModalProps) => {
  const handleDelete = async () => {
    try {
      await onDelete();
      setOpen(false);
    } catch (error: unknown) {
      console.error("Error deleting item:", error);
      displayToast("Failed to delete item", ToastType.error);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="text-gray-700">{description}</div>
        <DialogFooter className="mt-5">
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="destructive">
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
