import { ChatTemplate, Message } from "@/types";
import { FunctionDeclarationsFormatter } from "../FunctionDeclarationsFormatter";
import { ToolDeclarationsFormatter } from "../ToolDeclarationsFormatter";
import { CopyButton } from "../ui/copy-button";

export const ActionDeclaration = ({
  componentIsOpen,
  chatTemplate,
  message,
}: {
  componentIsOpen: boolean;
  chatTemplate: ChatTemplate;
  message?: Message;
}) => {
  const tools = chatTemplate.tools || [];
  const functions = chatTemplate.functions || [];
  const toolDeclarationNames = tools
    .map((tool) => tool.function.name)
    .join(",");
  const functionDeclarationNames = functions.map((func) => func.name).join(",");
  const toolChoice = chatTemplate.tool_choice;
  const functionCall = chatTemplate.function_call;
  const toolCalls =
    message?.role === "assistant" ? message.tool_calls || [] : [];
  const isTool = tools.length > 0;
  return (
    <>
      {!componentIsOpen ? (
        <>
          {isTool ? (
            <div className="group-hover:inherit pt-2 text-left font-mono text-xs">
              {toolDeclarationNames && toolDeclarationNames.length > 40
                ? toolDeclarationNames.substring(0, 40) + "..."
                : toolDeclarationNames}
              <div className="font-medium text-gray-700">
                {toolChoice ? (
                  <span className="italic">
                    {"tool_choice: "}
                    {typeof toolChoice === "string"
                      ? toolChoice
                      : toolChoice.function.name}
                  </span>
                ) : (
                  <>
                    {toolCalls.map((toolCall, index) => (
                      <span key={index} className="font-mono">
                        {toolCall.function.name}
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="group-hover:inherit pt-2 text-left font-mono text-xs">
              {functionDeclarationNames && functionDeclarationNames.length > 40
                ? functionDeclarationNames.substring(0, 40) + "..."
                : functionDeclarationNames}
              <div className="font-medium text-gray-700">
                {"function_call: "}
                {typeof functionCall === "string" ? (
                  <span className="italic">{functionCall}</span>
                ) : (
                  <span className="font-mono italic">{functionCall?.name}</span>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isTool ? (
            <div className="flex items-start justify-between">
              <div className="w-full">
                <ToolDeclarationsFormatter
                  toolDeclarations={tools ?? []}
                  toolChoice={toolChoice}
                />
              </div>
              <div>
                <CopyButton text={JSON.stringify(tools)} />
              </div>
            </div>
          ) : (
            <div className="flex items-start justify-between">
              <div className="w-full">
                <FunctionDeclarationsFormatter
                  functionDeclarations={functions ?? []}
                  functionCall={functionCall}
                />
              </div>
              <div>
                <CopyButton text={JSON.stringify(functions)} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
