import { ParametersEditorModeEnum } from "@/components/FunctionsModal/Types";
import { Switch } from "@/components/switch";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

export const EditorModeToggle = ({
  updateJsonValues,
  parameterEditorMode,
  setParameterEditorMode,
}: {
  updateJsonValues: (mode: ParametersEditorModeEnum) => void;
  parameterEditorMode: ParametersEditorModeEnum;
  setParameterEditorMode: Dispatch<SetStateAction<ParametersEditorModeEnum>>;
}) => {
  const isJsonMode = useMemo(
    () => parameterEditorMode === ParametersEditorModeEnum.JSON,
    [parameterEditorMode],
  );

  const handleChange = useCallback(
    (checked: boolean) => {
      const mode = checked
        ? ParametersEditorModeEnum.JSON
        : ParametersEditorModeEnum.INTERACTIVE;
      updateJsonValues(mode);
      setParameterEditorMode(mode);
    },
    [setParameterEditorMode, updateJsonValues],
  );

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center gap-4 rounded-md border border-gray-300 bg-gray-50 p-2 text-sm shadow-sm">
        <div>Interactive</div>
        <Switch checked={isJsonMode} onChange={handleChange} />
        <div>JSON</div>
      </div>
    </div>
  );
};
