import {
  SortConfigItem,
  useReportContext,
} from "@/components/Evaluate/report-context";
import { ReportColumn } from "@/types/evaluate";
import { truncate } from "@/utils/strings";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { memo } from "react";
import { Filter } from "../Search/search-context";
interface ActiveFiltersProps {
  filters: { [columnId: string]: string[] };
  removeFilter: (columnId: string, value: string) => void;
  currentFilterInput: Filter | null;
}

const ActiveFilters = ({
  filters,
  removeFilter,
  currentFilterInput,
}: ActiveFiltersProps) => {
  const { sortedColumns, sortColumn, sortConfig } = useReportContext();

  return (
    <>
      {(currentFilterInput || Object.keys(filters).length > 0) && (
        <div className="mx-2 flex h-auto w-[1px]  self-stretch  bg-gray-300" />
      )}
      {Object.entries(filters).map(([columnId, filterValues]) => {
        const column = sortedColumns.find(
          (c: ReportColumn) => c.id?.toString() === columnId,
        );
        return filterValues.map((value, i) => (
          <div
            key={`${columnId}-${i}`}
            className="inline-flex h-full items-center rounded-full border-[1px] border-blue-500 bg-blue-50 px-3 py-1 text-sm text-blue-600"
          >
            <span className="mr-1 font-semibold">{column?.name}:</span>
            <span>{truncate(value, 15)}</span>
            <button
              onClick={() => removeFilter(columnId, value)}
              className="ml-2 rounded-full p-1 hover:bg-blue-200"
            >
              <XIcon className="h-3 w-3" />
            </button>
          </div>
        ));
      })}

      {currentFilterInput && (
        <div className="inline-flex h-full items-center rounded-full border-[1px] border-yellow-500  bg-yellow-50 px-3 py-1 text-sm text-yellow-700">
          <span className="mr-1 font-semibold">
            {
              sortedColumns.find(
                (c: ReportColumn) =>
                  c.id?.toString() === currentFilterInput.column_id,
              )?.name
            }
            :
          </span>
          <span>{truncate(currentFilterInput.value, 15)}</span>
        </div>
      )}
      {(currentFilterInput || Object.keys(filters).length > 0) &&
        sortConfig.length > 0 && (
          <div className="mx-2 flex h-auto w-[1px] self-stretch bg-gray-300" />
        )}
      {sortConfig.map((sortItem: SortConfigItem, index: number) => (
        <div
          key={sortItem.columnId}
          className="inline-flex h-full items-center rounded-full border-[1px] border-purple-500 bg-purple-50  px-3 py-1 text-sm text-purple-600"
        >
          {sortItem.direction === "asc" ? (
            <ArrowUpIcon className="ml-1 h-4 w-4" />
          ) : (
            <ArrowDownIcon className="ml-1 h-4 w-4" />
          )}
          <span className="ml-1 font-semibold">
            {truncate(
              sortedColumns.find(
                (c: ReportColumn) => c.id === sortItem.columnId,
              )?.name,
              15,
            )}
          </span>
          <button
            onClick={() => sortColumn(sortItem.columnId, null)}
            className="ml-2 rounded-full p-1 hover:bg-purple-200"
          >
            <XIcon className="h-3 w-3" />
          </button>
        </div>
      ))}
    </>
  );
};

export default memo(ActiveFilters);
