import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { FunctionForm } from "@/components/FunctionsModal/FunctionForm";

import { DialogHeader } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Link } from "lucide-react";

const FunctionEditorDialog: React.FC = () => {
  const { editingFunction: previousFunction } = useFunctionDialogContext();
  return (
    <div>
      <DialogHeader className="text-center text-xl">
        <div className="flex flex-col">
          <Label className="text-xl font-bold">
            {previousFunction ? "Edit Function" : "Add New Function"}
          </Label>
          <p className="max-w-md text-sm text-gray-500">
            Define a structured function that the model can call.
            <a
              href="https://platform.openai.com/docs/guides/gpt/function-calling"
              target="_blank"
              rel="noreferrer"
              className="pl-1 text-sm text-gray-500 hover:text-gray-400"
            >
              Learn more <Link className="inline h-4 w-4 pl-1" />
            </a>
          </p>
        </div>
      </DialogHeader>
      <div className="pt-6">
        <FunctionForm />
      </div>
    </div>
  );
};

export default FunctionEditorDialog;
