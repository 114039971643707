import { useFunctionDialogContext } from "@/components/FunctionsModal";
import {
  FunctionFormValues,
  ParametersEditorModeEnum,
} from "@/components/FunctionsModal/Types";
import { getAdditionalPropertiesValue } from "@/utils/jsonSchemaEditor";
import JSON5 from "json5";

export const useFunctionForm = () => {
  const {
    functions,
    isEditing,
    parameterEditorMode,
    setIsAddingFunction,
    setEditingFunction,
    onSubmit,
  } = useFunctionDialogContext();

  const functionNames = functions.map((f) => f.name);

  const closeForm = () => {
    setIsAddingFunction(false);
    setEditingFunction(null);
  };

  const handleSubmit = (values: FunctionFormValues) => {
    let functionSchema = values.json;
    if (parameterEditorMode === ParametersEditorModeEnum.INTERACTIVE) {
      functionSchema = {
        name: values.name,
        description: values.description,
        strict: !!values.strict,
        parameters: {
          ...values.parameters,
          additionalProperties: getAdditionalPropertiesValue(values),
        },
      };
    } else if (typeof values.json === "string") {
      try {
        functionSchema = JSON5.parse(values.json);
      } catch (error) {
        alert("JSON schema invalid!");
        return;
      }
    }

    if (functionNames.includes(functionSchema.name) && isEditing) {
      if (
        !window.confirm(
          "A function with this name already exists. Are you sure you want to overwrite it?",
        )
      ) {
        return;
      }
    }

    if (functionSchema.parameters) {
      functionSchema.parameters.type = "object";
    }
    closeForm();
    onSubmit!(functionSchema);
  };

  return {
    handleSubmit,
    closeForm,
    parameterEditorMode,
  };
};
