import {
  CheckIcon,
  CollectionIcon,
  DocumentDuplicateIcon,
  DotsHorizontalIcon,
  FolderOpenIcon,
  GlobeIcon,
  HomeIcon,
  StarIcon,
} from "@heroicons/react/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import moment from "moment";
import { ReactNode, useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";

import { toggleSharableRequest } from "@/api/application-api";
import { CodeCopyBlock } from "@/components/ApiKeyModal";
import DropdownButton from "@/components/DropdownButton";
import LoadingSpinner from "@/components/LoadingSpinner";
import { MetadataPopoverContent } from "@/components/MetadataPopoverContent";
import { ScoreDropdown } from "@/components/ScoreDropdown";
import { Toggle } from "@/components/Toggle";
import { AlertDialog } from "@/components/alert-dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  usePromptRegistry,
  useRequestGroups,
  useToggleStarredRequest,
} from "@/queries";
import { Request } from "@/types/requests";

interface HistoryPageNavbarProps {
  requestId: string;
  requestInfo: any;
}

const HistoryPageNavbar = ({
  requestId,
  requestInfo,
}: HistoryPageNavbarProps) => {
  const [groupHelpOpen, setGroupHelpOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedTimeCopied, setLoggedTimeCopied] = useState(false);
  const [loggedTimeElement, setLoggedTimeElement] = useState<
    string | ReactNode
  >();
  const [loggedTimeHover, setLoggedTimeHover] = useState(false);
  const [otherScoresList, setOtherScoresList] = useState<any[]>([]);
  const [resetScore, setResetScore] = useState(false);
  const [score, setScore] = useState<any[]>([]);
  const [sharingEnabled, setSharingEnabledState] = useState(false);
  const [templateHelpOpen, setTemplateHelpOpen] = useState(false);
  const authContext = useAuth();
  const loggedTimeRef = useRef<HTMLHeadingElement>(null);
  const navigate = useNavigate();
  const request_groups = useRequestGroups(requestId!, authContext!.userToken!);
  const userContext = useUser();
  const userToken = authContext?.userToken!;

  const promptRegistry = usePromptRegistry(userToken, requestInfo?.prompt_id);
  const templateFromRegistryInfo = promptRegistry.data;

  const { mutate: toggleStarredRequest, isLoading: isLoadingToggleStarred } =
    useToggleStarredRequest();

  const sharingUrl = `https://dashboard.promptlayer.com/share/${requestInfo?.share_hash}`;

  useEffect(() => {
    setOtherScoresList([]);
    setScore([]);
    setResetScore(true);
    if (requestInfo) {
      if (requestInfo.scores && requestInfo.scores.length > 0) {
        if (
          requestInfo?.scores.some(
            (score: any) => score && score.hasOwnProperty("default"),
          )
        ) {
          let default_score = requestInfo?.scores.find(
            (score: any) => "default" in score,
          )?.default;
          setScore([default_score]);
          setResetScore(default_score || default_score === 0 ? false : true);
        }
        setOtherScoresList(
          requestInfo.scores
            .filter((score: any) => score && !("default" in score))
            .map((score: any) => score),
        );
      }
      setSharingEnabledState(requestInfo.is_sharable);
    }
  }, [requestId, requestInfo]);

  useEffect(() => {
    const time = requestInfo?.request_start_time;
    const formattedTime = time
      ? moment(time).format("YYYY-MM-DD HH:mm:ss")
      : "";

    if (loggedTimeCopied) {
      setLoggedTimeElement(
        <span>
          Copied
          <CheckIcon className="-mt-0.5 inline h-5 w-5 pl-1 text-gray-500" />
        </span>,
      );
    } else if (loggedTimeHover) {
      setLoggedTimeElement(
        <span
          onClick={() => {
            navigator.clipboard.writeText(formattedTime);
            setLoggedTimeCopied(true);
            setTimeout(() => {
              setLoggedTimeCopied(false);
            }, 1000);
          }}
          className="cursor-pointer text-gray-700"
        >
          {formattedTime}
        </span>,
      );
    } else {
      setLoggedTimeElement(`Logged ${moment.utc(time).fromNow()}`);
    }
  }, [loggedTimeHover, loggedTimeCopied, requestInfo]);

  const getRequestScore = (reqInfo: any) => {
    return reqInfo?.scores?.find((score: any) => score && "default" in score)
      ?.default;
  };

  const handleMouseEnter = () => {
    setLoggedTimeHover(true);
  };

  const handleMouseLeave = () => {
    setLoggedTimeHover(false);
  };

  const handleToggleSharableRequest = async (newVal: boolean) => {
    setLoading(true);
    const response = await toggleSharableRequest(
      authContext!,
      Number(requestId),
      newVal,
    );
    if (response.status === 200) {
      const data = await response.json();

      if (data.success) {
        setSharingEnabledState(newVal);
      }
    } else {
      console.log("Error toggling sharable request");
    }
    setLoading(false);
  };

  const handleToggleStarredRequest = (newVal: boolean) => {
    toggleStarredRequest({
      id: requestId!,
      newVal,
      userToken: authContext!.userToken!,
    });
  };

  const onCopyUrl = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const onToggleStar = (val: boolean) => {
    handleToggleStarredRequest(val);
  };

  const renderLoggedTime = () => {
    return (
      <h1
        className="hidden py-1 text-sm text-gray-500 lg:block"
        id="logged-time"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={loggedTimeRef}
      >
        {loggedTimeElement}
      </h1>
    );
  };

  const renderMetadataPopover = () => {
    return (
      <h1>
        <Popover>
          <PopoverTrigger className="text-sm hover:text-gray-500">
            Metadata
          </PopoverTrigger>
          <MetadataPopoverContent
            requestId={requestInfo?.id}
            key={`${requestId}.metadata`}
          />
        </Popover>
      </h1>
    );
  };

  const renderNoGroupPopover = () => {
    return (
      <Popover>
        <PopoverTrigger className="py-1 text-gray-400 hover:text-gray-500 md:ml-5">
          <CollectionIcon className="mr-1 inline h-4 w-4 align-middle" />
          <span
            className="mr-1 align-middle"
            onClick={() => setGroupHelpOpen(true)}
          >
            No Group
          </span>
        </PopoverTrigger>
        <div className="text-blue-500 md:ml-5">
          <AlertDialog
            closeModal={() => setGroupHelpOpen(false)}
            show={groupHelpOpen}
            title={
              <>
                <div>
                  Associate Prompt Run with Group.{" "}
                  <a
                    href="https://docs.promptlayer.com/features/prompt-history/groups"
                    target="_blank"
                    className="pl-1 text-sm font-medium text-blue-500 hover:text-blue-400"
                    rel="noreferrer"
                  >
                    (Read the docs)
                  </a>
                </div>
              </>
            }
          >
            <div className="space-y-4">
              <div>
                Associate Request with a Group
                <a
                  href="https://docs.promptlayer.com/features/prompt-history/groups"
                  target="_blank"
                  className="pl-2 text-sm font-medium text-blue-500 hover:text-blue-400"
                  rel="noreferrer"
                >
                  (Read the docs)
                </a>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                Programmatically link completions to one or more groups. Use
                this to organize requests.
              </div>
              <CodeCopyBlock
                python={true}
                code={`# Create group
group_id = promptlayer.group.create()

# Make your request
resp, pl_id = openai.Completion.create(engine='gpt-3.5-turbo-instruct', 
    prompt=prompt, return_pl_id=True)

# Associate request to Group
promptlayer.track.group(request_id=pl_id, 
    group_id=group_id)
                `}
              />
            </div>
          </AlertDialog>
        </div>
      </Popover>
    );
  };

  const renderNoTemplatePopover = () => {
    return (
      <Popover>
        <PopoverTrigger className="py-1 text-gray-400 hover:text-gray-500 md:ml-5">
          <FolderOpenIcon className="mr-1 inline h-4 w-4 align-middle" />
          <span
            className="mr-1 align-middle"
            onClick={() => setTemplateHelpOpen(true)}
          >
            No Template
          </span>
        </PopoverTrigger>
        <div className="text-blue-500 md:ml-5">
          <AlertDialog
            closeModal={() => setTemplateHelpOpen(false)}
            show={templateHelpOpen}
            title={
              <>
                <div>
                  Associate Request with a Prompt Template
                  <a
                    href="https://docs.promptlayer.com/features/prompt-history/tracking-templates"
                    target="_blank"
                    className="pl-1 text-sm font-medium text-blue-500 hover:text-blue-400"
                    rel="noreferrer"
                  >
                    (Read the docs)
                  </a>
                </div>
              </>
            }
          >
            <div className="space-y-4">
              <div>
                Programmatically link completions to templates. Use this to
                start tracking template cost & performance.
              </div>
              <CodeCopyBlock
                python={true}
                code={`# Make your request
resp, pl_id = openai.Completion.create(engine='gpt-3.5-turbo-instruct', 
    prompt=prompt, return_pl_id=True)

# Associate request to Prompt Template
promptlayer.track.prompt(request_id=pl_id, 
    prompt_name='simple_summary', prompt_input_variables=vars)

# Evaluate the request
score = evaluate_request(resp)
promptlayer.track.score(request_id=pl_id, score=score)
                `}
              />
            </div>
          </AlertDialog>
        </div>
      </Popover>
    );
  };

  const renderPromptTemplateLink = () => {
    if (requestInfo?.prompt_id && promptRegistry.isLoading) {
      return <LoadingSpinner size={5} />;
    }

    if (!templateFromRegistryInfo) {
      return renderNoTemplatePopover();
    }

    return (
      <Link
        className="ml-1 cursor-pointer pt-1 text-blue-500 hover:text-blue-400 md:ml-5"
        to={`/workspace/${userContext?.activeWorkspaceId}/prompt/${templateFromRegistryInfo?.id}${
          requestInfo?.prompt_version_number
            ? `/version/${requestInfo?.prompt_version_number}`
            : ""
        }`}
      >
        <FolderOpenIcon className="mr-1 inline h-4 w-4 align-middle" />
        {templateFromRegistryInfo?.prompt_name}{" "}
        {requestInfo?.prompt_version_number
          ? `[#${requestInfo?.prompt_version_number}]`
          : ""}
      </Link>
    );
  };

  const renderRequestGroupLink = () => {
    if (!request_groups.data?.groups?.length) return renderNoGroupPopover();

    return (
      <Popover>
        <PopoverTrigger className="py-1 text-gray-700 hover:text-gray-500 md:ml-5">
          <CollectionIcon className="mr-1 inline h-4 w-4 align-middle" />
          <span className="align-middle">
            <span>Groups</span>
          </span>
        </PopoverTrigger>
        <PopoverContent>
          <div className="divide-y divide-gray-200 text-center">
            {request_groups.data?.groups?.flatMap(
              (item: Request, index: number) => (
                <div key={`${item.id}-${index}`} className={`py-2`}>
                  <Link
                    className="cursor-pointer text-blue-500 hover:text-blue-400"
                    to={`/workspace/${userContext?.activeWorkspaceId}/group/${item.id}`}
                  >
                    Group #{item.id}
                  </Link>
                </div>
              ),
            )}
          </div>
        </PopoverContent>
      </Popover>
    );
  };

  const renderShare = () => {
    return (
      <h1 className="py-1 text-sm">
        <DropdownButton
          dropdownItems={[]}
          width="w-80"
          dropdownContent={
            <div className="flex flex-col space-y-3 px-4 py-3">
              <div className="flex items-center space-x-2">
                <div>
                  <GlobeIcon className="inline h-6 text-gray-600" />
                </div>
                <div className="flex-1 flex-col">
                  <div className="text-sm text-gray-800">Share this page</div>
                  <div className="text-xs text-gray-600">
                    Anyone with the link can view
                  </div>
                </div>
                <div>
                  <Toggle
                    enabled={sharingEnabled}
                    setEnabled={setSharingEnabled}
                  />
                </div>
              </div>
              {loading ? (
                <div className="flex justify-center">
                  <div className="h-5 w-5 animate-spin rounded-full border-b-2 border-blue-600"></div>
                </div>
              ) : null}
              {sharingEnabled && !loading ? (
                <div>
                  <div className="flex">
                    <input
                      value={sharingUrl || "Loading..."}
                      type="text"
                      readOnly
                      className="block w-full rounded-md border-gray-300 text-gray-600 hover:text-gray-700 focus:border-blue-500 focus:text-gray-800 focus:ring-blue-500 sm:text-sm"
                    />
                    <span className="ml-2 cursor-pointer rounded-md border hover:bg-gray-50">
                      <CopyToClipboard
                        text={sharingUrl || "Loading..."}
                        onCopy={onCopyUrl}
                      >
                        <span className="cursor-pointer">
                          {isCopied ? (
                            <CheckIcon className="mt-2 h-5 w-auto flex-shrink-0 px-2 text-gray-500 hover:text-gray-700" />
                          ) : (
                            <DocumentDuplicateIcon className="mt-2 h-5 w-auto flex-shrink-0 px-2 text-gray-500 hover:text-gray-700" />
                          )}
                        </span>
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          }
        >
          <span className="hover:text-gray-500">Share</span>
        </DropdownButton>
      </h1>
    );
  };

  const renderStar = () => {
    return (
      <h1 className="py-1 text-sm">
        <span
          className="cursor-pointer"
          onClick={() => onToggleStar(!requestInfo.is_starred)}
        >
          {isLoadingToggleStarred ? (
            <LoadingSpinner size="4" />
          ) : requestInfo.is_starred ? (
            <StarIconSolid className="inline h-5 w-auto text-yellow-500" />
          ) : (
            <StarIcon className="inline h-5 w-auto text-gray-400 hover:text-yellow-500" />
          )}
        </span>
      </h1>
    );
  };

  const renderThreeDotMenu = () => {
    return (
      <h1 className="text-md cursor-pointer py-1">
        <DropdownButton
          dropdownContent={null}
          dropdownItems={[
            {
              name: `Copy pl_id`,
              icon: DocumentDuplicateIcon,
              onClick: () => {
                navigator.clipboard.writeText(requestInfo?.id);
              },
            },
            {
              name: requestInfo?.is_starred ? "Un-favorite" : "Favorite",
              icon: requestInfo?.is_starred ? StarIconSolid : StarIcon,
              onClick: () => onToggleStar(!requestInfo.is_starred),
            },
            {
              name: "Go Home",
              icon: HomeIcon,
              onClick: () => {
                navigate("/");
              },
            },
          ]}
        >
          <DotsHorizontalIcon className="inline h-6" />
        </DropdownButton>
      </h1>
    );
  };

  const setSharingEnabled = (val: boolean) => {
    requestInfo.is_sharable = val;
    handleToggleSharableRequest(val);
  };

  if (!requestInfo) {
    return <div className="flex flex-1 justify-between px-4 md:px-0"></div>;
  }

  return (
    <div className="flex flex-1 justify-between px-4 md:px-0">
      <div className="flex flex-1 items-center text-sm text-gray-800">
        {renderPromptTemplateLink()}
        {renderRequestGroupLink()}
      </div>
      <div className="flex items-center space-x-8 text-gray-800 hover:text-gray-700 md:mr-6">
        {renderLoggedTime()}
        {renderStar()}
        <ScoreDropdown
          requestId={requestId!}
          requestScore={getRequestScore(requestInfo)}
          resetScore={resetScore}
          score={score}
          scores={otherScoresList}
          setResetScore={setResetScore}
          setScore={setScore}
        />
        {renderShare()}
        {renderMetadataPopover()}
        {renderThreeDotMenu()}
      </div>
    </div>
  );
};

export default HistoryPageNavbar;
