export type ItemProperties = {
  [key: string]: Properties | string;
  type: ParameterType.Object;
};

export type ItemDescription = {
  description: string;
  type: ParameterType;
  isEnum?: boolean;
  enum?: string[];
  isItemDescription?: boolean;
};

export type Properties = {
  name: string;
  type: ParameterType;
  description: string;
  title: string;
  isRequired?: boolean;
  required?: string[];
  isEnum?: boolean;
  enum?: string[];
  items?: ItemProperties | ItemDescription;
  isItemDescription?: boolean;
  // Because of the possible recursive component calls,
  // we might need to self-reference here.
  properties?: Properties;
};

export enum ParameterType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Object = "object",
  Array = "array",
}
