import { EditorModeToggle } from "@/components/FunctionsModal/FunctionForm/EditorModeToggle";
import InteractiveFunctionParametersEditor from "@/components/FunctionsModal/InteractiveFunctionEditor";
import { ParametersEditorModeEnum } from "@/components/FunctionsModal/Types";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Function_, SchemaDefinition } from "@/types";
import {
  convertInteractiveToJson,
  parseJsonToInteractive,
  updateAdditionalProperties,
} from "@/utils/jsonSchemaEditor";
import { displayErrorToast } from "@/utils/toast";
import { Link } from "lucide-react";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

type SchemaDefinitionFormValues = SchemaDefinition & {
  json: SchemaDefinition;
};

const EditJsonSchemaModal = ({
  initialValues,
  handleSubmit,
}: {
  initialValues: SchemaDefinitionFormValues;
  handleSubmit: (schema: SchemaDefinition) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Dialog open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
      <DialogTrigger asChild>
        <Button variant="outline">Edit Json Schema</Button>
      </DialogTrigger>
      <DialogContent className="flex max-h-[800px] min-h-[600px] max-w-6xl flex-col overflow-auto">
        <SchemaEditor
          initialValues={initialValues}
          setIsOpen={setIsOpen}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

const defaultFormValues: SchemaDefinitionFormValues = {
  name: "",
  description: "",
  strict: false,
  schema: {
    type: "object",
    properties: {},
  },
  json: {
    name: "",
  },
};

const SchemaEditor = ({
  initialValues,
  setIsOpen,
  handleSubmit,
}: {
  initialValues: SchemaDefinitionFormValues;
  setIsOpen: (isOpen: boolean) => void;
  handleSubmit: (schema: SchemaDefinition) => void;
}) => {
  const form = useForm<SchemaDefinitionFormValues>({
    defaultValues: initialValues ?? defaultFormValues,
  });
  const [parameterEditorMode, setParameterEditorMode] =
    useState<ParametersEditorModeEnum>(ParametersEditorModeEnum.INTERACTIVE);

  const updateJsonValues = (newMode: ParametersEditorModeEnum) => {
    const formValues = form.getValues();

    if (newMode === ParametersEditorModeEnum.JSON) {
      // Switching from Interactive to JSON Mode
      form.setValue(
        "json",
        convertInteractiveToJson(formValues) as SchemaDefinition,
      );
    } else if (newMode === ParametersEditorModeEnum.INTERACTIVE) {
      // Switching from JSON to Interactive Mode
      try {
        const parsedJson: Partial<SchemaDefinitionFormValues> =
          parseJsonToInteractive(formValues.json as Function_);

        (Object.keys(parsedJson) as Array<keyof typeof parsedJson>).forEach(
          (key) => {
            form.setValue(key, parsedJson[key]);
          },
        );
      } catch (error) {
        displayErrorToast("JSON schema invalid!");
        return;
      }
    }
  };

  const closeForm = () => {
    setIsOpen(false);
    form.reset(defaultFormValues);
  };

  const handleFormSubmit = (values: SchemaDefinitionFormValues) => {
    const formValues: Partial<SchemaDefinitionFormValues> =
      parameterEditorMode === ParametersEditorModeEnum.JSON
        ? parseJsonToInteractive(values.json as Function_)
        : values;

    if (!formValues.schema) {
      return;
    }

    const updatedSchemaParameters = updateAdditionalProperties(
      formValues.schema,
      formValues.schema?.additionalProperties || false,
    );

    let schema: SchemaDefinition = {
      name: formValues.name || "",
      description: formValues.description,
      strict: formValues.strict,
      schema: { ...updatedSchemaParameters, type: "object" },
    };

    handleSubmit(schema);
    closeForm();
  };

  const isStrictEnabled = form.watch("strict");
  const isAdditionalProperties = form.watch("schema.additionalProperties");

  return (
    <div>
      <DialogHeader className="text-center text-xl">
        <Label className="text-xl font-bold">Schema Name</Label>
      </DialogHeader>
      <div className="pt-6">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleFormSubmit)}
            className="space-y-8"
          >
            {/* JSON/Interactive Switch */}
            <EditorModeToggle
              parameterEditorMode={parameterEditorMode}
              setParameterEditorMode={setParameterEditorMode}
              updateJsonValues={updateJsonValues}
            />
            {parameterEditorMode === ParametersEditorModeEnum.JSON ? (
              // raw JSON Editor
              <JsonEditor form={form} />
            ) : (
              <>
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Schema Name</FormLabel>
                        <FormControl>
                          <Input autoFocus placeholder="my_schema" {...field} />
                        </FormControl>
                        <FormDescription>
                          Name of schema, max length 64.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Description"
                            rows={3}
                            {...field}
                            value={field.value ?? ""}
                          />
                        </FormControl>
                        <FormDescription>
                          A description of what the response format is for, used
                          by the model to determine how to respond in the
                          format. (Optional)
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="mt-5 flex gap-5">
                    <FormField
                      control={form.control}
                      name="strict"
                      render={({ field }) => (
                        <FormItem className="flex items-center gap-3 space-y-0">
                          <FormControl>
                            <input
                              type="checkbox"
                              className="rounded text-blue-600 focus:ring-blue-500"
                              name={field.name}
                              checked={!!field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          </FormControl>
                          <FormLabel>Enable Strict Structured Output</FormLabel>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="schema.additionalProperties"
                      render={({ field }) => (
                        <FormItem className="flex items-center gap-3 space-y-0">
                          <FormControl>
                            <input
                              type="checkbox"
                              className="rounded text-blue-600 focus:ring-blue-500"
                              name={field.name}
                              checked={!!field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          </FormControl>
                          <FormLabel>Allow Additional Properties</FormLabel>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <p
                    className={`text-sm text-gray-500 ${
                      isStrictEnabled && isAdditionalProperties
                        ? "visible"
                        : "invisible"
                    }`}
                  >
                    Structured Outputs only supports `additionalProperties:
                    false`.
                    <a
                      href="https://platform.openai.com/docs/guides/structured-outputs/additionalproperties-false-must-always-be-set-in-objects"
                      target="_blank"
                      rel="noreferrer"
                      className="pl-1 text-sm text-gray-500 hover:text-gray-400"
                    >
                      Learn more <Link className="inline h-4 w-4 pl-1" />
                    </a>
                  </p>
                </div>
                <FormField
                  control={form.control}
                  name="schema"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Parameters</FormLabel>
                      <FormControl>
                        <div>
                          <InteractiveFunctionParametersEditor
                            onChange={field.onChange}
                            initialValue={field.value}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            <div className="flex space-x-4">
              <Button type="submit">Add schema</Button>
              <Button variant="outline" onClick={closeForm}>
                Cancel
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

const JsonEditor = ({
  form,
}: {
  form: UseFormReturn<SchemaDefinitionFormValues>;
}) => (
  <FormField
    control={form.control}
    name="json"
    render={({ field }) => (
      <FormItem>
        <FormLabel>
          Schema
          <a
            href="https://json-schema.org/understanding-json-schema/"
            target="_blank"
            rel="noreferrer"
            className="ml-1 underline"
          >
            (JSONSchema)
          </a>
        </FormLabel>
        <FormControl>
          <Textarea
            className="font-mono"
            {...field}
            rows={10}
            onChange={(e) => field.onChange(e.target.value)}
            value={
              typeof field.value === "string"
                ? field.value
                : JSON.stringify(field.value, null, 2)
            }
          />
        </FormControl>
        <FormDescription>
          JSON Schema object with the schema name, description, and schma.
        </FormDescription>
        <FormMessage />
      </FormItem>
    )}
  />
);

export default EditJsonSchemaModal;
