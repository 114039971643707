import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { FC } from "react";

interface AddEnumsProps {
  enums: string[];
  setEnums: (enums: string[]) => void;
}

const AddEnums: FC<AddEnumsProps> = ({ enums, setEnums }) => {
  // Handle adding a new enum input field
  const handleAddEnum = () => {
    setEnums([...enums, ""]);
  };

  // Handle updating the value of an enum
  const handleEnumChange = (index: number, value: string) => {
    const updatedEnums = enums.map((enumValue, i) =>
      i === index ? value : enumValue,
    );
    setEnums(updatedEnums);
  };

  // Handle removing an enum input field
  const handleRemoveEnum = (index: number) => {
    const updatedEnums = enums.filter((_, i) => i !== index);
    setEnums(updatedEnums);
  };

  return (
    <div className="block space-y-4">
      <div className="space-y-2">
        {enums.map((enumValue, index) => (
          <div key={index} className="flex items-center space-x-2">
            <Input
              type="text"
              value={enumValue}
              onChange={(e) => handleEnumChange(index, e.target.value)}
              placeholder={`Enum ${index + 1}`}
            />
            <Button
              variant="outline"
              className="flex items-center space-x-2"
              onClick={() => handleRemoveEnum(index)}
            >
              <XIcon className="h-5 w-5 text-red-500" />
            </Button>
          </div>
        ))}
      </div>
      <Button
        variant={"default"}
        type="button"
        className="flex items-center space-x-2"
        onClick={handleAddEnum}
      >
        <PlusIcon className="mr-2 h-5 w-5" />
        Add Enum Element
      </Button>
    </div>
  );
};

export default AddEnums;
